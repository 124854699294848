import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./data/store";
import { loadData } from "./data/accounts/actions";
import { getPersistence } from "./persistence/factory";
import { runOobe, finishOobe } from "./data/oobe/actions";
import ReactModal from "react-modal";
import { AUTH_NS } from "data/auth/types";

if (process.env.REACT_APP_DEMO_MODE) {
  getPersistence().registerNewAccountHandler(async () => {
    store.dispatch(runOobe());

    const { generate } = await import(
      "lib/sample_account/generate_sample_account"
    );

    await generate();

    store.dispatch(loadData());

    store.dispatch(finishOobe());
  });
} else {
  getPersistence().registerNewAccountHandler(() => store.dispatch(runOobe()));
}

async function authAndFetch() {
  if (
    process.env.REACT_APP_PERSIST === "cloud" ||
    localStorage.getItem("use_auth")
  ) {
    const mod = await import("auth/client");
    mod.addAuthChangeHandler(
      (loggedIn) => loggedIn && store.dispatch(loadData())
    );
    mod.initAuth();
  }
}

if (store.getState()[AUTH_NS].requireLogin) {
  authAndFetch();
} else {
  // Kickoff initial data fetch
  store.dispatch(loadData());
}

getPersistence().listenForChange();

const appNode = document.getElementById("root");
ReactModal.setAppElement(appNode!);
ReactDOM.render(<App />, appNode);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
