import cx from "classnames";
import React from "react";
import css from "./pagination.module.css";

const PagerItem: React.FC<{
  onClick?: () => void;
  className?: string;
}> = (props) => (
  <li className={props.className}>
    {props.onClick ? (
      <button onClick={props.onClick}>{props.children}</button>
    ) : (
      props.children
    )}
  </li>
);

const _Pager: React.FC<{
  page: number;
  maxPage: number;
  surround?: number;
  onChange(page: number): void;
}> = (props) => {
  if (props.page > props.maxPage) {
    props.onChange(1);
    return null;
  }

  let min = Math.max(1, props.page - props.surround!);
  let max = Math.min(props.maxPage, min + 2 * props.surround!);
  min = Math.max(1, max - 2 * props.surround!);

  const all = [];
  all.push(
    <PagerItem
      key="prev"
      onClick={
        props.page > 1 ? () => props.onChange(props.page - 1) : undefined
      }
    >
      Prev
    </PagerItem>
  );

  if (min > 1) {
    all.push(
      <PagerItem key="1" onClick={() => props.onChange(1)}>
        1
      </PagerItem>
    );
    min++;
    if (min > 2) {
      all.push(<PagerItem key="left-dot">...</PagerItem>);
      min++;
    }
  }

  if (max < props.maxPage) {
    max--;
    if (max < props.maxPage - 1) {
      max--;
    }
  }

  for (let i = min; i <= max; i++) {
    all.push(
      <PagerItem
        onClick={i === props.page ? undefined : props.onChange.bind(null, i)}
        key={i}
        className={i === props.page ? css.current : undefined}
      >
        {i}
      </PagerItem>
    );
  }
  if (max < props.maxPage) {
    if (max < props.maxPage - 1) {
      all.push(<PagerItem key="right-dot">...</PagerItem>);
    }
    all.push(
      <PagerItem key="last" onClick={() => props.onChange(props.maxPage)}>
        {props.maxPage}
      </PagerItem>
    );
  }
  all.push(
    <PagerItem
      key="next"
      onClick={
        props.page < props.maxPage
          ? () => props.onChange(props.page + 1)
          : undefined
      }
    >
      Next
    </PagerItem>
  );
  return <ul className={css.pager}>{all}</ul>;
};

_Pager.defaultProps = {
  surround: 4,
};

export const Pager = React.memo(_Pager);

const _RichPager: React.FC<{
  data: any[];
  start: number;
  size: number;
  onChange(start: number, size: number): void;
  pageSizes?: number[];
  className?: string;
  hideSinglePage?: boolean;
}> = (props) => {
  if (props.data.length === 0) {
    return null;
  }
  if (props.hideSinglePage && props.data.length <= props.size) {
    return null;
  }
  return (
    <div className={cx(css.richPager, props.className)}>
      <Pager
        page={Math.floor(props.start / props.size) + 1}
        maxPage={Math.ceil(props.data.length / props.size)}
        onChange={(page) => props.onChange(props.size * (page - 1), props.size)}
      />
      {props.pageSizes && props.pageSizes.length > 1 && (
        <label>
          Per Page:
          <select
            onChange={(e) => {
              const newSize = +e.currentTarget.value || props.size;
              props.onChange(
                Math.floor(props.start / newSize) * newSize,
                newSize
              );
            }}
            value={props.size}
          >
            {props.pageSizes!.map((newSize) => (
              <option value={newSize} key={newSize}>
                {newSize}
              </option>
            ))}
          </select>
        </label>
      )}
    </div>
  );
};

_RichPager.defaultProps = {
  pageSizes: [10, 25, 50, 100],
};

export const RichPager = React.memo(_RichPager);
