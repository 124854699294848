import { TRANSACTIONS_NS, TransactionsState } from "./types";
import { TRANSACTIONS_DEFAULT_STATE } from "./default_state";
import { reducerInNs } from "data/utils";
import { AppState } from "data/store";
import { TransactionsAction } from "./action_creators";
import produce from "immer";

export const transactionsReducer = reducerInNs(
  TRANSACTIONS_NS,
  (
    state: TransactionsState = TRANSACTIONS_DEFAULT_STATE,
    action: TransactionsAction,
    fullState: AppState
  ) => {
    switch (action.type) {
      case "TRANSACTIONS/SET_FILTER": {
        const { filter, index } = action.payload;
        return produce(state, draftState => {
          if (!draftState.filters) {
            draftState.filters = [];
          }
          while (index >= draftState.filters.length) {
            draftState.filters.push({});
          }
          Object.assign(draftState.filters[index], filter);
        });
      }
      case "TRANSACTIONS/CLEAR_FILTER": {
        return {
          ...state,
          filters: []
        };
      }
      case "TRANSACTIONS/REMOVE_FILTER": {
        const { index } = action.payload;
        return produce(state, draftState => {
          if (draftState.filters) {
            draftState.filters.splice(index, 1);
          }
        });
      }
      case "TRANSACTIONS/SET_SEARCH_TERM": {
        return {
          ...state,
          searchTerm: action.payload.term
        };
      }
    }
    return state;
  }
);
