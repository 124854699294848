import cx from "classnames";
import React from "react";
import {
  ConnectedAccountSelector,
  AccountDisplay,
} from "components/common/account";
import css from "./automation.module.css";
import pageCss from "components/styles/page.module.css";
import inputCss from "components/styles/input.module.css";
import { ConnectedMultiExternalAccountSelector } from "components/common/multi_external_account_selector";
import { connect } from "react-redux";
import { AppState } from "data/store";
import {
  automationRuleSelector,
  automationEntriesSelector,
  externalAccountByIdSelector,
} from "data/importer/selectors";
import {
  ImporterAutomationRule,
  TextMatch,
  transactionFromAction,
} from "lib/importing/automation";
import { updateAutomationRule, executeAutomation } from "data/importer/actions";
import { UUID } from "lib/core/uuid";
import { ImportedEntry, Account, ExternalAccount } from "model/bookkeeping";
import { format, formatDebitCredit } from "model/scaled_value";
import {
  accountByExternalIdSelector,
  accountsMapSelector,
} from "data/accounts/selectors";

const TextMatchInput = React.memo<{
  textMatch?: TextMatch;
  onChange(textMatch: TextMatch): void;
}>((props) => {
  const { textMatch, onChange } = props;
  const defaultTextMatch: TextMatch = {
    text: "",
    regex: false,
    caseSensitive: false,
  };
  return (
    <>
      <input
        type="text"
        className={inputCss.input}
        value={props.textMatch?.text}
        onChange={(e) =>
          onChange({
            ...defaultTextMatch,
            ...textMatch,
            text: e.currentTarget.value,
          })
        }
      />
      <label>
        <input
          type="checkbox"
          checked={props.textMatch?.regex}
          onChange={(e) =>
            onChange({
              ...defaultTextMatch,
              ...textMatch,
              regex: e.currentTarget.checked,
            })
          }
        />{" "}
        Regex
      </label>
      <label>
        <input
          type="checkbox"
          checked={props.textMatch?.caseSensitive}
          onChange={(e) =>
            onChange({
              ...defaultTextMatch,
              ...textMatch,
              caseSensitive: e.currentTarget.checked,
            })
          }
        />{" "}
        Case sensitive
      </label>
    </>
  );
});

const RuleEditor = React.memo<{
  rule: ImporterAutomationRule;
  updateAutomationRule: (rule: ImporterAutomationRule) => void;
  executeAutomation: () => Promise<void>;
}>((props) => {
  const { rule, updateAutomationRule } = props;
  const { criteria, actions } = rule;
  const updateCriteria = (
    partialCriteria: Partial<ImporterAutomationRule["criteria"]>
  ) =>
    updateAutomationRule({
      ...rule,
      criteria: {
        ...criteria,
        ...partialCriteria,
      },
    });
  const updateAction = (
    partialAction: Partial<ImporterAutomationRule["actions"]>
  ) => {
    updateAutomationRule({
      ...rule,
      actions: {
        ...actions,
        ...partialAction,
      },
    });
  };

  type Leg = {
    accountId: UUID;
    amountExpression?: string;
  };

  const updateMultiLeg = (leg: Leg, index: number) => {
    if (index <= (actions.createMultiLeg?.length || 0)) {
      const clone = actions.createMultiLeg ? [...actions.createMultiLeg] : [];
      clone[index] = leg;
      updateAction({
        createMultiLeg: clone,
        setTransferAccount: undefined,
        markAsDuplicate: undefined,
      });
    }
  };
  const deleteMultiLeg = (index: number) => {
    if (actions.createMultiLeg) {
      const clone = [...actions.createMultiLeg];
      clone.splice(index, 1);
      updateAction({
        createMultiLeg: clone,
      });
    }
  };
  const multiLegExtended: (Leg | undefined)[] = actions.createMultiLeg
    ? [...actions.createMultiLeg]
    : [];
  multiLegExtended.push(undefined);
  return (
    <div className={cx(pageCss.block, css.section)}>
      <h2 className={css.title}>For each entry</h2>
      <ul className={css.list}>
        <li className={css.listItem}>
          <span className={css.criteriaLabel}>Are in account</span>
          <ConnectedMultiExternalAccountSelector
            selectedExternalAccounts={
              Array.isArray(criteria.externalAccounts)
                ? criteria.externalAccounts
                : []
            }
            onChange={(ids) => {
              updateCriteria({
                externalAccounts: ids.length > 0 ? ids : undefined,
              });
            }}
          />
          <label>
            <input
              type="checkbox"
              checked={criteria.externalAccounts === "any"}
              onChange={(e) =>
                updateCriteria({
                  externalAccounts: e.currentTarget.checked ? "any" : undefined,
                })
              }
            />
            Any account
          </label>
        </li>
        <li className={css.listItem}>
          <span className={css.criteriaLabel}>Have memo</span>
          <TextMatchInput
            textMatch={criteria.memo}
            onChange={(memo) => updateCriteria({ memo })}
          />
        </li>
      </ul>
      <h2 className={css.title}>Do</h2>
      <ul className={css.list}>
        <li className={css.listItem}>
          <input
            type="checkbox"
            checked={!!actions.setMemo}
            onChange={(e) =>
              !e.currentTarget.checked && updateAction({ setMemo: undefined })
            }
          />{" "}
          Set memo to:{" "}
          <input
            type="text"
            className={inputCss.input}
            value={actions.setMemo || ""}
            onChange={(e) =>
              updateAction({
                setMemo: e.currentTarget.value,
                markAsDuplicate: undefined,
              })
            }
          />
        </li>
        <li className={css.listItem}>
          <input
            type="checkbox"
            checked={!!actions.setTransferAccount}
            onChange={(e) =>
              !e.currentTarget.checked &&
              updateAction({ setTransferAccount: undefined })
            }
          />{" "}
          Set transfer account to:{" "}
          <ConnectedAccountSelector
            inputProps={{ className: inputCss.input }}
            displayProps={{ className: inputCss.input }}
            value={actions.setTransferAccount}
            hidePlaceholders={true}
            onChange={(acc) =>
              updateAction({
                setTransferAccount: acc,
                createMultiLeg: undefined,
                markAsDuplicate: undefined,
              })
            }
          />
        </li>
        <li className={css.listItem}>
          <input
            type="checkbox"
            checked={!!actions.createMultiLeg}
            onChange={(e) =>
              !e.currentTarget.checked &&
              updateAction({ createMultiLeg: undefined })
            }
          />{" "}
          Create multi-leg transaction:
          <table>
            <thead>
              <tr>
                <td>Account</td>
                <td>Amount</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(Import to Account)</td>
                <td>x</td>
                <td></td>
              </tr>
              {multiLegExtended.map((leg, i) => (
                <tr key={i}>
                  <td>
                    <ConnectedAccountSelector
                      inputProps={{ className: inputCss.input }}
                      displayProps={{ className: inputCss.input }}
                      hidePlaceholders={true}
                      value={leg?.accountId}
                      onChange={(accountId) =>
                        updateMultiLeg(
                          {
                            accountId: accountId,
                            amountExpression: leg?.amountExpression,
                          },
                          i
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="Amount"
                      className={cx(inputCss.input, css.expressionBox)}
                      value={leg?.amountExpression || ""}
                      disabled={!leg}
                      onChange={(e) =>
                        leg &&
                        updateMultiLeg(
                          {
                            accountId: leg.accountId,
                            amountExpression: e.currentTarget.value,
                          },
                          i
                        )
                      }
                    />
                  </td>
                  <td>
                    {leg && (
                      <button onClick={() => deleteMultiLeg(i)}>X</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </li>
        <li className={css.listItem}>
          <input
            type="checkbox"
            checked={!!actions.importImmediately}
            onChange={(e) =>
              e.currentTarget.checked
                ? updateAction({
                    importImmediately: e.currentTarget.checked,
                    markAsDuplicate: undefined,
                  })
                : updateAction({
                    importImmediately: undefined,
                  })
            }
          />{" "}
          Import the transaction immediately
        </li>
        <li className={css.listItem}>
          <input
            type="checkbox"
            checked={!!actions.markAsDuplicate}
            onChange={(e) =>
              updateAutomationRule({
                ...rule,
                actions: {
                  markAsDuplicate: true,
                },
              })
            }
          />{" "}
          Mark as duplicate
        </li>
      </ul>
      <div>
        <button onClick={() => props.executeAutomation()}>Run</button>
      </div>
    </div>
  );
});

const ActionPreview = React.memo<{
  rule: ImporterAutomationRule;
  entry: ImportedEntry;
  accountByExternalId: (id: UUID) => Account | undefined;
  accountsMap: Map<UUID, Account>;
  externalAccountById: { [key: string]: ExternalAccount };
}>((props) => {
  const account = props.accountByExternalId(
    props.externalAccountById[props.entry.externalAccountId]?.externalId
  );
  if (account) {
    const txAndSplit = transactionFromAction(
      props.entry,
      account.id,
      props.rule.actions
    );
    if (txAndSplit) {
      const { transaction, splits } = txAndSplit;
      return (
        <div className={css.actionPreview}>
          <h3 className={css.actionPreviewTransactionMemo}>
            {transaction.memo}
          </h3>
          <table>
            <tbody>
              {splits.map((split) => {
                const [dr, cr] = formatDebitCredit(split.valueScaled);
                return (
                  <tr key={split.id}>
                    <td>
                      <AccountDisplay
                        accountName={
                          props.accountsMap.get(split.accountId)?.name || ""
                        }
                      />
                    </td>
                    <td>{dr}</td>
                    <td>{cr}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    }
  }
  return null;
});

const RulePreview = React.memo<{
  entries: ImportedEntry[];
  rule: ImporterAutomationRule;
  accountByExternalId: (id: UUID) => Account | undefined;
  accountsMap: Map<UUID, Account>;
  externalAccountById: { [key: string]: ExternalAccount };
}>((props) => {
  const [entry, setEntry] = React.useState<ImportedEntry | undefined>(
    undefined
  );
  const [offset, setOffset] = React.useState(0);
  const criteria = Object.values(props.rule.criteria);
  if (criteria.length > 0 && criteria.some((v) => v)) {
    return (
      <div
        className={cx(pageCss.block, css.section)}
        onMouseLeave={() => setEntry(undefined)}
      >
        <h2>Preview</h2>
        <div className={css.previewWrapper}>
          <table className={cx(css.previewTable, css.entriesPreviewWrapper)}>
            <tbody>
              {props.entries.slice(0, 50).map((entry) => (
                <tr
                  key={entry.id}
                  className={css.previewRow}
                  onMouseEnter={(e) => {
                    setEntry(entry);
                    setOffset(e.currentTarget.offsetTop);
                  }}
                >
                  <td>{entry.memo}</td>
                  <td className={css.entryDateCell}>
                    {entry.datetime.datetime.toFormat("D")}
                  </td>
                  <td className={css.entryAmountCell}>
                    {format(entry.valueScaled)}
                  </td>
                  <td role="presentation" className={css.entrySpacerCell}></td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className={css.actionPreviewWrapper}>
            {entry && (
              <div
                style={{
                  position: "relative",
                  top: offset,
                }}
              >
                <ActionPreview
                  rule={props.rule}
                  entry={entry}
                  accountByExternalId={props.accountByExternalId}
                  accountsMap={props.accountsMap}
                  externalAccountById={props.externalAccountById}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
});

const Automation = React.memo<{
  rule: ImporterAutomationRule;
  entries: ImportedEntry[];
  updateAutomationRule: (rule: ImporterAutomationRule) => void;
  accountByExternalId: (id: UUID) => Account | undefined;
  externalAccountById: { [key: string]: ExternalAccount };
  accountsMap: Map<UUID, Account>;
  executeAutomation: () => Promise<void>;
}>((props) => {
  return (
    <div className={css.wrapper}>
      <RuleEditor
        rule={props.rule}
        updateAutomationRule={props.updateAutomationRule}
        executeAutomation={props.executeAutomation}
      />
      <RulePreview
        entries={props.entries}
        rule={props.rule}
        accountByExternalId={props.accountByExternalId}
        accountsMap={props.accountsMap}
        externalAccountById={props.externalAccountById}
      />
    </div>
  );
});

export const ConnectedAutomation = connect(
  (state: AppState) => ({
    rule: automationRuleSelector(state),
    entries: automationEntriesSelector(state),
    accountByExternalId: accountByExternalIdSelector(state),
    accountsMap: accountsMapSelector(state),
    externalAccountById: externalAccountByIdSelector(state),
  }),
  { updateAutomationRule, executeAutomation }
)(Automation);
