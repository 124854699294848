import { BMDateTime } from "lib/datetime/types";
import { DateTime } from "luxon";

export type SerializeDateTime<T extends { datetime: BMDateTime }> = Omit<
  T,
  "datetime"
> & {
  datetime: string;
  datetimeHasTime: boolean;
};

export type UnserializeDateTime<T> = Omit<T, "datetime" | "datetimeHasTime"> & {
  datetime: BMDateTime;
};

export function serializeDateTime<T extends { datetime: BMDateTime }>(
  val: T
): SerializeDateTime<T> {
  return {
    ...val,
    datetime: val.datetime.hasTime
      ? val.datetime.datetime.toISO()
      : val.datetime.datetime.toISODate(),
    datetimeHasTime: val.datetime.hasTime,
  };
}

export function maybeSerializeDateTime(val: any): any {
  if (val.datetime) {
    return serializeDateTime(val);
  }
  return val;
}

export function maybeUnserializeDateTime(val: any): any {
  if (val.datetime === undefined) {
    return val;
  }
  return unserializeDateTime(val);
}

export function unserializeDateTime<
  T extends { datetime: string; datetimeHasTime: boolean }
>(val: T): UnserializeDateTime<T> {
  const { datetime, datetimeHasTime, ...rest } = val;
  if (datetimeHasTime) {
    return {
      ...rest,
      datetime: {
        datetime: DateTime.fromISO(datetime, { setZone: true }),
        hasTime: datetimeHasTime,
      } as BMDateTime,
    };
  } else {
    return {
      ...rest,
      datetime: {
        datetime: DateTime.fromISO(datetime.substr(0, 10)),
        hasTime: datetimeHasTime,
      } as BMDateTime,
    };
  }
}
