import React from "react";
import cx from "classnames";
import { Filter } from "model/filtering";
import { ReactComponent as CloseIcon } from "assets/font-awesome-solid/times-circle.svg";
import { ReactComponent as AddIcon } from "assets/font-awesome-solid/plus-circle.svg";
import { DateRangePicker } from "components/common/date_range";
import { ConnectedMultiAccountSelector } from "components/common/multi_account_selector";
import { defaultDateRange } from "lib/datetime/date_range";
import css from "./filter_list.module.css";
import buttonCss from "components/styles/button.module.css";

export const FilterList = React.memo<{
  filters: Filter[];
  setFilter(index: number, data: Partial<Filter>): void;
  removeFilter(index: number): void;
}>(props => {
  const { filters, setFilter, removeFilter } = props;

  const addClause = () => {
    const { filters, setFilter } = props;
    setFilter(filters ? filters.length : 0, {});
  };

  return (
    <ul className={css.filterList}>
      {filters.map((filter, i) => (
        <li key={i}>
          <div>
            <button
              className={cx(css.deleteClauseButton, buttonCss.styleless)}
              onClick={() => removeFilter(i)}
            >
              <CloseIcon width={16} height={16} />
            </button>
          </div>
          <ul>
            <li>
              Date Range:{" "}
              <label>
                <input
                  type="radio"
                  name={`filter_date_range_${i}`}
                  checked={!filter.dateRange}
                  onChange={() =>
                    setFilter(i, {
                      dateRange: undefined
                    })
                  }
                />{" "}
                Any
              </label>
              <label>
                <input
                  type="radio"
                  name={`filter_date_range_${i}`}
                  checked={!!filter.dateRange}
                  onChange={() =>
                    setFilter(i, {
                      dateRange: defaultDateRange()
                    })
                  }
                />{" "}
                Selected range
              </label>
              {filter.dateRange && (
                <DateRangePicker
                  range={filter.dateRange}
                  onChange={v => {
                    console.log(v);
                    setFilter(i, { dateRange: v });
                  }}
                />
              )}
            </li>
            <li>
              <label>
                Memo{" "}
                <input
                  type="text"
                  value={filter.memo || ""}
                  onChange={e =>
                    setFilter(i, {
                      memo: e.currentTarget.value
                    })
                  }
                />
              </label>
            </li>
            <li>
              Accounts involved{" "}
              <ConnectedMultiAccountSelector
                selectedAccounts={filter.accountsInvolved || []}
                onChange={accountsInvolved =>
                  setFilter(i, { accountsInvolved })
                }
              />
            </li>
          </ul>
          <div className={css.addClauseCell}>
            {i === filters.length - 1 ? (
              <button
                className={cx(buttonCss.styleless, css.addClauseButton)}
                onClick={addClause}
              >
                <AddIcon width={16} height={16} />
              </button>
            ) : (
              <span>-OR-</span>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
});
