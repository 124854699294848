export const EXTERNAL_ACCOUNT_IDS = "_com.ducount.externalAccountIds";
export const OPENING_BALANCE = "_com.ducount.openingBalance";
export const TYPE = "_com.ducount.accountType";
export enum TYPE_VALUES {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT"
}
export const EXTERNAL_ACCOUNT_TYPE = "_com.ducount.externalAccountType";
export const ENTRY_CATEGORY = "_com.ducount.entryCategory";
export const BASIC_ACCOUNT = "_com.ducount.basicAccount";
export enum BASIC_ACCOUNT_VALUES {
  ASSETS = "ASSETS",
  LIABILITIES = "LIABILITIES",
  INCOME = "INCOME",
  EXPENSES = "EXPENSES",
  EQUITY = "EQUITY"
}
export const CASH_ACCOUNT = "_com.ducount.isCashAccount";

export const IS_PLACEHOLDER = "_com.ducount.isPlaceholder";
