import { ImportedEntry, Split } from "../../model/bookkeeping";
import groupBy from "lodash/groupBy";
import { abs } from "../../model/scaled_value";
import forEach from "lodash/forEach";
import munkres from "munkres-js";

export function matchTransferEntries(entries: ImportedEntry[]) {
  const entriesByValue = groupBy(entries, e => abs(e.valueScaled));
  const matchedEntries: [ImportedEntry, ImportedEntry][] = [];
  forEach(entriesByValue, entries => {
    const positive: ImportedEntry[] = [],
      negative: ImportedEntry[] = [];

    for (const entry of entries) {
      if (entry.valueScaled > 0) {
        positive.push(entry);
      } else if (entry.valueScaled < 0) {
        negative.push(entry);
      }
    }

    if (positive.length === 0 || negative.length === 0) {
      return;
    }

    const dist = (posEntry: ImportedEntry, negEntry: ImportedEntry) =>
      posEntry.externalAccountId === negEntry.externalAccountId
        ? 1 << 29
        : Math.abs(
            (posEntry.datetime.datetime
              .diff(negEntry.datetime.datetime)
              .valueOf() /
              1000) |
              0
          );

    const match = munkres(
      positive.map(posEntry =>
        negative.map(negEntry => dist(posEntry, negEntry))
      )
    );

    for (const [i, j] of match) {
      const d = dist(positive[i], negative[j]);
      if (d < 7 * 24 * 3600) {
        matchedEntries.push([positive[i], negative[j]]);
      }
    }
  });

  return matchedEntries;
}

export function matchEntryAndSplit(entries: ImportedEntry[], splits: Split[]) {
  const dist = (entry: ImportedEntry, split: Split) => {
    if (entry.valueScaled !== split.valueScaled) {
      return 1 << 29;
    }
    const dateScore = Math.exp(
      Math.abs(
        entry.datetime.datetime.diff(split.datetime.datetime).valueOf() /
          (1000 * 3600 * 24)
      )
    );
    return dateScore;
  };

  const match = munkres(
    entries.map(entry => splits.map(split => dist(entry, split)))
  );

  const matched: [ImportedEntry, Split][] = [];
  for (const [i, j] of match) {
    const d = dist(entries[i], splits[j]);
    if (d < 50) {
      matched.push([entries[i], splits[j]]);
    }
  }

  return matched;
}
