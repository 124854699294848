import { createSelector } from "reselect";
import { AppState } from "../store";
import { IMPORTER_NS, entryIsCandidate } from "./types";
import { ExternalAccount } from "../../model/bookkeeping";
import sortBy from "lodash/sortBy";
import { matchCriteria } from "lib/importing/automation";
import { accountByExternalIdSelector } from "data/accounts/selectors";
import { IS_DUPLICATE, IS_HIDDEN } from "./tags";
import { UUID } from "lib/core/uuid";

export const importerNsSelector = (state: AppState) => state[IMPORTER_NS];

export const externalAccountDataListSelector = createSelector(
  importerNsSelector,
  (state) =>
    sortBy(
      Object.values(state.externalAccountsData),
      (data) => data.account.name
    )
);

export const showHiddenAccountOnlySelector = createSelector(
  importerNsSelector,
  (state) => !!state.showHiddenAccountOnly
);

export const displayingExternalAccountDataListSelector = createSelector(
  externalAccountDataListSelector,
  showHiddenAccountOnlySelector,
  (externalAccountData, showHiddenOnly) =>
    externalAccountData.filter(
      (data) => !!+data.account.tags[IS_HIDDEN] === showHiddenOnly
    )
);

export const externalAccountByIdSelector = createSelector(
  externalAccountDataListSelector,
  (accountData) =>
    accountData.reduce((acc, data) => {
      acc[data.account.id] = data.account;
      return acc;
    }, {} as { [key: string]: ExternalAccount })
);

export const externalAccountByIdMapSelector = createSelector(
  externalAccountByIdSelector,
  (extAccountById) => new Map(Object.entries(extAccountById))
);

export const externalAccountDataSelector = (state: AppState) => (
  externalId: string
) => importerNsSelector(state).externalAccountsData[externalId];

export const entryDataByIdSelector = createSelector(
  importerNsSelector,
  (state) => state.entries
);

export const externalIdSetInAccountSelector = createSelector(
  entryDataByIdSelector,
  externalAccountDataSelector,
  (entryDataById, selector) => (externalId: string) => {
    const set = new Set<string>();
    for (const e of selector(externalId).entryIds) {
      set.add(entryDataById[e].entry.externalId);
    }
    return set;
  }
);

export const candidateTransactionsSelector = (state: AppState) =>
  importerNsSelector(state).candidateTransactions;

export const candidateTransactionByIdSelector = createSelector(
  candidateTransactionsSelector,
  (transactions) => (id: string) => transactions[id]
);

export const selectedEntryDataSelector = createSelector(
  importerNsSelector,
  entryDataByIdSelector,
  (state, entryById) =>
    Object.keys(state.selectedEntries).map((id) => entryById[id])
);

export const csvSelector = createSelector(
  importerNsSelector,
  (state) => state.csv
);

export const linkedInstitutionsSelector = createSelector(
  importerNsSelector,
  (state) => sortBy(state.linkedInstitutions, "name")
);

export const automationRuleSelector = createSelector(
  importerNsSelector,
  (state) => state.automationRule
);

export const transactionByEntryIdSelector = createSelector(
  entryDataByIdSelector,
  candidateTransactionByIdSelector,
  (entryDataById, candidateTransactionById) => (id: UUID) => {
    const entryData = entryDataById[id];
    if (entryData && entryIsCandidate(entryData)) {
      return candidateTransactionById(entryData.candidateTransactionId) || null;
    }
    return null;
  }
);

export const automationEntriesSelector = createSelector(
  entryDataByIdSelector,
  automationRuleSelector,
  accountByExternalIdSelector,
  externalAccountByIdSelector,
  (dataMap, rule, accountByExtId, extAccountById) => {
    if (rule.criteria.externalAccounts || rule.criteria.memo) {
      return sortBy(
        Object.values(dataMap)
          .map((data) => data.entry)
          .filter((entry) => {
            const extAccount = extAccountById[entry.externalAccountId];
            return (
              !entry.splitId &&
              !+entry.tags[IS_DUPLICATE] &&
              matchCriteria(entry, rule.criteria) &&
              extAccount &&
              !extAccount.tags[IS_HIDDEN] &&
              accountByExtId(extAccount.externalId)
            );
          }),
        (ent) => -ent.datetime.datetime.valueOf()
      );
    }
    return [];
  }
);
