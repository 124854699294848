import React, { PureComponent } from "react";
import cx from "classnames";
import { ScaledValue, format, zero, sum } from "model/scaled_value";
import { Account } from "model/bookkeeping";
import {
  treeify,
  breakAccountName,
  joinAccountName
} from "lib/accounts/hierarchy";
import { ReactComponent as CaretRight } from "assets/font-awesome-solid/caret-right.svg";
import css from "./account_value.module.css";
import buttonCss from "components/styles/button.module.css";

interface Tree {
  name: string;
  fullPath: string;
  value?: ScaledValue;
  children: Tree[];
}

type AccountRowState = {
  isOpen?: boolean;
};

class AccountRow extends PureComponent<{ tree: Tree }, AccountRowState> {
  state: AccountRowState = {};

  render() {
    const { tree } = this.props;
    return (
      <li>
        {tree.children.length > 0 && (
          <button
            className={buttonCss.styleless}
            onClick={() => this.setState({ isOpen: !this.state.isOpen })}
          >
            <CaretRight
              className={cx(css.caret, {
                [css.caretOpen]: !!this.state.isOpen,
                [css.caretClosed]: !this.state.isOpen
              })}
              width={12}
              height={12}
            />
          </button>
        )}
        {tree.name}
        {tree.value != null && (
          <span className={css.value}>{format(tree.value)}</span>
        )}
        {tree.children.length > 0 && this.state.isOpen && (
          <ul className={css.list}>
            {tree.children.map(tree => (
              <AccountRow key={tree.fullPath} tree={tree} />
            ))}
          </ul>
        )}
      </li>
    );
  }
}

function sumChildren(tree: Tree) {
  let total = zero();
  for (const child of tree.children) {
    sumChildren(child);
    total = sum(total, child.value || zero());
  }
  tree.value = sum(total, tree.value || zero());
}

export class AccountValueDisplay extends PureComponent<
  {
    values: [Account, ScaledValue][];
    sumChildren?: boolean;
  },
  {}
> {
  render() {
    const forest = treeify<Tree, [Account, ScaledValue]>(
      this.props.values,
      ([account, scaledValue]) => breakAccountName(account.name),
      t => t.name,
      t => t.children,
      (acc, soFar) =>
        ({
          name: soFar[soFar.length - 1],
          children: [],
          fullPath: joinAccountName(soFar)
        } as Tree),
      ([account, valueScaled], tree) => (tree.value = valueScaled)
    );

    if (this.props.sumChildren) {
      for (const t of forest) {
        sumChildren(t);
      }
    }

    return (
      <ul className={css.listRoot}>
        {forest.map(tree => (
          <AccountRow key={tree.fullPath} tree={tree} />
        ))}
      </ul>
    );
  }
}
