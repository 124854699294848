import React, { PureComponent } from "react";
import cx from "classnames";
import buttonCss from "components/styles/button.module.css";

interface Button<T> {
  label: string;
  value: T;
}

export class SegmentedButton<T> extends PureComponent<
  { buttons: Button<T>[]; value?: T; onChange?(newValue: T): void },
  {}
> {
  render() {
    return (
      <ul className={buttonCss.segmentWrapper}>
        {this.props.buttons.map(button => (
          <li
            className={buttonCss.segmentButtonWrapper}
            key={"" + button.value}
          >
            <button
              onClick={() =>
                this.props.onChange && this.props.onChange(button.value)
              }
              className={cx(
                buttonCss.segmentButton,
                this.props.value === button.value
                  ? buttonCss.buttonBlue
                  : buttonCss.buttonBlueOutline
              )}
            >
              {button.label}
            </button>
          </li>
        ))}
      </ul>
    );
  }
}
