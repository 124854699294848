import cx from "classnames";
import React from "react";

import {
  unscaleValue,
  ScaledValue,
  scaleValue,
  negate,
} from "../../model/scaled_value";

import css from "./value_scaled_input.module.css";
import inputCss from "components/styles/input.module.css";

interface OwnProps {
  value?: ScaledValue;
  onChange?(numer: ScaledValue): void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const ValueScaledInput: React.FC<
  Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof OwnProps> & OwnProps
> = (props) => {
  const { inputRef, value, onChange, ...rest } = props;
  return (
    <input
      className={cx(inputCss.input, css.input)}
      ref={props.inputRef}
      type="number"
      min={0}
      step={0.01}
      {...rest}
      value={props.value ? unscaleValue(+props.value as ScaledValue) : ""}
      defaultValue={
        props.defaultValue &&
        "" + unscaleValue(+props.defaultValue as ScaledValue)
      }
      onChange={(e) =>
        props.onChange
          ? props.onChange(scaleValue(e.currentTarget.value))
          : undefined
      }
    />
  );
};

export function debitCreditFactory(
  props: {
    accountType?: "credit" | "debit";
    valueScaled?: ScaledValue;
    readOnly?: boolean;
    disabled?: boolean;
    onChange?(numer: ScaledValue): void;
    additionalClassName?: string;
  },
  refs: [
    React.RefObject<HTMLInputElement> | undefined,
    React.RefObject<HTMLInputElement> | undefined
  ] = [undefined, undefined]
): [React.ReactNode, React.ReactNode] {
  const [refDr] = refs;
  return [
    <ValueScaledInput
      inputRef={refDr}
      className={cx(
        inputCss.input,
        css.input,
        {
          [css.increaseInput]: props.accountType === "debit",
          [css.decreaseInput]: props.accountType === "credit",
        },
        props.additionalClassName
      )}
      readOnly={props.readOnly}
      disabled={props.disabled}
      value={
        props.valueScaled !== undefined && props.valueScaled > 0
          ? props.valueScaled
          : undefined
      }
      onChange={props.onChange}
    />,
    <ValueScaledInput
      className={cx(
        inputCss.input,
        css.input,
        {
          [css.increaseInput]: props.accountType === "credit",
          [css.decreaseInput]: props.accountType === "debit",
        },
        props.additionalClassName
      )}
      readOnly={props.readOnly}
      disabled={props.disabled}
      value={
        props.valueScaled !== undefined && props.valueScaled < 0
          ? negate(props.valueScaled)
          : undefined
      }
      onChange={(newValue) =>
        props.onChange && props.onChange(negate(newValue))
      }
    />,
  ];
}
