export function readFile(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(e) {
      if (typeof this.result === "string") {
        resolve(this.result);
      } else {
        reject("Invalid result");
      }
    };
    reader.readAsText(file);
  });
}
