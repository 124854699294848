import React from "react";
import cx from "classnames";
import css from "./section_wrapper.module.css";
import pageCss from "components/styles/page.module.css";

export const Section: React.FC<{ title: string }> = props => (
  <div className={cx(css.sectionWrapper, pageCss.block)}>
    <h2 className={css.sectionWrapperTitle}>{props.title}</h2>
    <div className={css.sectionWrapperMain}>{props.children}</div>
  </div>
);
