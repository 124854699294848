import { Filter } from "model/filtering";

export function setFilter(index: number, filter: Partial<Filter>) {
  return {
    type: "TRANSACTIONS/SET_FILTER" as const,
    payload: { index, filter }
  };
}

export function clearFilter() {
  return {
    type: "TRANSACTIONS/CLEAR_FILTER" as const
  };
}

export function removeFilter(index: number) {
  return {
    type: "TRANSACTIONS/REMOVE_FILTER" as const,
    payload: { index }
  };
}

export function setSearchTerm(term: string) {
  return {
    type: "TRANSACTIONS/SET_SEARCH_TERM" as const,
    payload: { term }
  };
}

export type TransactionsAction =
  | ReturnType<typeof setFilter>
  | ReturnType<typeof clearFilter>
  | ReturnType<typeof removeFilter>
  | ReturnType<typeof setSearchTerm>;
