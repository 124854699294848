import { Dispatch } from "../store";
import { Scenario, buildBasicAccounts } from "../../static_data/basic_accounts";
import { addAccounts } from "../accounts/actions";
import { finishOobe } from "./action_creators";

export { runOobe, finishOobe } from "./action_creators";

export function finishSetupAccounts(scenarios: Set<Scenario>) {
  return async (dispatch: Dispatch) => {
    const accounts = buildBasicAccounts(scenarios);
    await dispatch(addAccounts(accounts));
    await dispatch(finishOobe());
  };
}
