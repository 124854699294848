export function redGreenColorScale(value: number, s: number, v: number) {
  return hsvToHex(value / 3, s, v);
}

export function hsvToHex(h: number, s: number, v: number): string {
  let r = 0,
    g = 0,
    b = 0,
    i,
    f,
    p,
    q,
    t;

  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }
  return (
    "#" +
    Math.floor(r * 255).toString(16) +
    Math.floor(g * 255).toString(16) +
    Math.floor(b * 255).toString(16)
  );
}
