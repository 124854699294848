import { LedgersState } from "./types";
import { rangeOfToday } from "lib/datetime/date_range";
import { BASIC_ACCOUNT_VALUES } from "data/accounts/tags";

export const LEDGERS_DEFAULT_STATE: LedgersState = {
  selectedAccountIds: [],
  selectedAccountBackStack: [],
  selectedAccountForwardStack: [],
  selectedSplitIds: {},
  pagingOffset: 0,
  pagingSize: 100,
  charts: {
    default: [
      {
        id: "2521c18e-c198-4d48-99f0-f46af07f9fed",
        name: "Net Worth",
        options: {
          type: "line",
          data: "balance",
          accounts: "sum",
          grouping: "month",
          origin: "auto",
          signConventionDebit: "positive",
          signConventionCredit: "negative",
          extrapolateDays: 0,
        },
        filters: [{ dateRange: rangeOfToday("year") }],
        accounts: [
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.ASSETS,
            },
            policy: "include",
          },
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.LIABILITIES,
            },
            policy: "include",
          },
        ],
      },
      {
        id: "ab2e0010-7b08-47b0-8d03-499765ac9355",
        name: "Net Worth Change",
        options: {
          type: "bar",
          data: "delta",
          accounts: "sum",
          grouping: "month",
          origin: "auto",
          signConventionDebit: "positive",
          signConventionCredit: "negative",
          extrapolateDays: 0,
        },
        filters: [{ dateRange: rangeOfToday("year") }],
        accounts: [
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.ASSETS,
            },
            policy: "include",
          },
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.LIABILITIES,
            },
            policy: "include",
          },
        ],
      },
      {
        id: "ba8a26ef-8c96-4d57-9da6-62f0f952da8b",
        name: "Net Income",
        options: {
          type: "bar",
          data: "delta",
          accounts: "sum",
          grouping: "month",
          origin: "auto",
          signConventionDebit: "negative",
          signConventionCredit: "positive",
          extrapolateDays: 0,
        },
        filters: [{ dateRange: rangeOfToday("year") }],
        accounts: [
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.INCOME,
            },
            policy: "include",
          },
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.EXPENSES,
            },
            policy: "include",
          },
        ],
      },
      {
        id: "4f1f9205-17c1-4f81-a3ab-72db29360acf",
        name: "Income",
        options: {
          type: "bar",
          data: "delta",
          accounts: "sum",
          grouping: "month",
          origin: "auto",
          signConventionDebit: "negative",
          signConventionCredit: "positive",
          extrapolateDays: 0,
        },
        filters: [{ dateRange: rangeOfToday("year") }],
        accounts: [
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.INCOME,
            },
            policy: "include",
          },
        ],
      },
      {
        id: "662523d1-ce19-45fa-a648-983dc1c089b8",
        name: "Expenses",
        options: {
          type: "bar",
          data: "delta",
          accounts: "sum",
          grouping: "month",
          origin: "auto",
          signConventionDebit: "positive",
          signConventionCredit: "negative",
          extrapolateDays: 0,
        },
        filters: [{ dateRange: rangeOfToday("year") }],
        accounts: [
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.EXPENSES,
            },
            policy: "include",
          },
        ],
      },
      {
        id: "1277add6-45ce-404c-a3ab-ba864da2d2e4",
        name: "Expenses By Category",
        options: {
          type: "treemap",
          data: "cumulative",
          accounts: "separate",
          grouping: "month",
          origin: "auto",
          signConventionDebit: "positive",
          signConventionCredit: "negative",
          extrapolateDays: 0,
        },
        filters: [{ dateRange: rangeOfToday("year") }],
        accounts: [
          {
            accountDescriptor: {
              _tag: "type",
              type: BASIC_ACCOUNT_VALUES.EXPENSES,
            },
            policy: "include",
          },
        ],
      },
    ],
    user: [],
  },
};
