import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "../../data/store";
import { UUID } from "../../lib/core/uuid";
import { Account } from "../../model/bookkeeping";
import { accountsMapSelector } from "../../data/accounts/selectors";
import { AccountSelector, AccountDisplay } from "./account";

import css from "./multi_account_selector.module.css";
import { TokenizedMultiSelect } from "./tokenized_multiselect";

type Props = {
  selectedAccounts: UUID[];
  onChange(accounts: UUID[]): void;
  accounts: Map<UUID, Account>;
};

export class MultiAccountSelector extends PureComponent<Props, {}> {
  filter = (account: Account) =>
    !this.props.selectedAccounts.includes(account.id);

  render() {
    return (
      <TokenizedMultiSelect
        selectedIds={this.props.selectedAccounts}
        data={this.props.accounts}
        id={(data) => data.id}
        display={(account) => <AccountDisplay accountName={account.name} />}
        selector={(onSelected) => (
          <AccountSelector
            accounts={this.props.accounts}
            value={undefined}
            accountsFilter={this.filter}
            onChange={onSelected}
            theme={{
              input: css.accountSelectInput,
              container: css.accountSelectContainer,
            }}
          />
        )}
        onChange={this.props.onChange}
      />
    );
  }
}

export const ConnectedMultiAccountSelector = connect((state: AppState) => ({
  accounts: accountsMapSelector(state),
}))(MultiAccountSelector);
