import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState, Dispatch } from "../../data/store";
import { accountsListSelector } from "../../data/accounts/selectors";
import { addAccount } from "../../data/accounts/actions";
import { newUuid, UUID } from "../../lib/core/uuid";

class AutoCompletingInput extends PureComponent<
  {
    defaultValue?: string;
    onChange?: React.ChangeEventHandler;
    optionsSorted: string[];
  },
  {}
> {
  private onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
    let newValue;
    const value = e.currentTarget.value;
    if (value) {
      const inputType = (e.nativeEvent as any).inputType;
      if (inputType && inputType.startsWith("delete")) {
        return;
      }

      for (const option of this.props.optionsSorted) {
        if (option.startsWith(value)) {
          newValue = option;
        }
      }

      if (newValue) {
        e.currentTarget.value = newValue;
        e.currentTarget.setSelectionRange(value.length, newValue.length);
      }
    }
  };

  render() {
    return (
      <input
        defaultValue={this.props.defaultValue}
        onChange={this.onChange}
        onBlur={this.onChange}
      />
    );
  }
}

type AccountCreatorProps = {
  accounts: string[];
  dispatch: Dispatch;
  defaultValue?: string;
  tags?: {
    [s: string]: string;
  };
  onCreated?: (id: UUID) => void;
};

export class AccountCreator extends PureComponent<
  AccountCreatorProps,
  { name: string }
> {
  constructor(props: AccountCreatorProps) {
    super(props);
    this.state = {
      name: props.defaultValue || ""
    };
  }

  onSave = async () => {
    const id = newUuid();
    await this.props.dispatch(
      addAccount({
        name: this.state.name,
        id,
        tags: this.props.tags || {}
      })
    );

    if (this.props.onCreated) {
      this.props.onCreated(id);
    }
  };

  onChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    this.setState({ name: e.currentTarget.value });
  };
  render() {
    const nameExists = this.props.accounts.includes(this.state.name);
    return (
      <div>
        <AutoCompletingInput
          onChange={this.onChange}
          optionsSorted={this.props.accounts}
          defaultValue={this.props.defaultValue}
        />
        <button onClick={this.onSave} disabled={nameExists}>
          OK
        </button>
      </div>
    );
  }
}

export const ConnectedAccountCreator = connect((state: AppState) => ({
  accounts: accountsListSelector(state)
    .map(a => a.name)
    .sort()
}))(AccountCreator);
