import cx from "classnames";
import React, { PureComponent, useState } from "react";
import { DateTime } from "luxon";
import {
  DateRangeData,
  prevRange,
  humanizeRange,
  nextRange
} from "lib/datetime/date_range";
import { SimpleDropdown } from "./simple_dropdown_menu";
import Calendar from "react-calendar";
import range from "lodash/range";
import css from "./date_range.module.css";
import buttonCss from "components/styles/button.module.css";

type Props = {
  range: DateRangeData;
  onChange(r: DateRangeData): void;
};

type State = {
  range: DateRangeData["range"];
};

const YearList: React.FC<{
  year: number;
  setYear(y: number): void;
}> = props => {
  const yearRange = range(DateTime.local().year, DateTime.local().year - 6, -1);
  return (
    <ul className={css.subyearYearList}>
      {yearRange.map(year => (
        <li className={css.subyearYearListItem} key={year}>
          <button
            className={cx(buttonCss.styleless, css.subyearYearButton)}
            onClick={() => props.setYear(year)}
            disabled={year === props.year}
          >
            {year}
          </button>
        </li>
      ))}
      <li className={css.subyearYearListItem}>
        <input
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder="Other"
          onBlur={e =>
            +e.currentTarget.value && props.setYear(+e.currentTarget.value)
          }
          className={css.subyearYearListOther}
        />
      </li>
    </ul>
  );
};

const QuarterPicker: React.FC<{
  range: DateRangeData;
  onChange(range: DateRangeData): void;
}> = props => {
  const [year, setYear] = useState(props.range.startTime.year);
  const now = DateTime.local().valueOf();
  return (
    <div className={css.subyearPicker}>
      <YearList year={year} setYear={setYear} />
      <ul className={css.quarterList}>
        {range(0, 4).map(quarter => {
          const d = DateTime.local(year, quarter * 3 + 1);
          const thisRange: DateRangeData = {
            range: "quarter",
            startTime: d.startOf("quarter"),
            endTime: d.endOf("quarter")
          };
          return (
            <li className={css.quarterListItem} key={quarter}>
              <button
                className={cx(buttonCss.styleless)}
                onClick={() => props.onChange(thisRange)}
                disabled={thisRange.startTime.valueOf() > now}
              >
                {`Q${quarter + 1}`}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const MonthPicker: React.FC<{
  range: DateRangeData;
  onChange(range: DateRangeData): void;
}> = props => {
  const [year, setYear] = useState(props.range.startTime.year);
  const now = DateTime.local().valueOf();
  return (
    <div className={css.subyearPicker}>
      <YearList year={year} setYear={setYear} />
      <ul className={css.monthList}>
        {range(1, 13).map(month => {
          const d = DateTime.local(year, month);
          const thisRange: DateRangeData = {
            range: "month",
            startTime: d.startOf("month"),
            endTime: d.endOf("month")
          };
          return (
            <li className={css.monthListItem} key={month}>
              <button
                className={cx(buttonCss.styleless)}
                onClick={() => props.onChange(thisRange)}
                disabled={thisRange.startTime.valueOf() > now}
              >
                {DateTime.local(year, month).monthShort}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export class DateRangePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { range: props.range.range };
  }
  render() {
    const now = DateTime.local();
    return (
      <span className={css.wrapper}>
        <button
          className={cx(buttonCss.styleless, css.prevNextButton)}
          onClick={() => this.props.onChange(prevRange(this.props.range))}
          disabled={this.props.range.range === "custom"}
        >
          {"<"}
        </button>
        <SimpleDropdown
          wrapperClass={css.dropdownWrapper}
          buttonClass={buttonCss.styleless}
          contents={(ref, onClick) => (
            <div ref={ref} className={css.picker}>
              <ul className={css.rangeType}>
                <li className={css.rangeTypeItem}>
                  <button
                    onClick={() => this.setState({ range: "year" })}
                    disabled={this.state.range === "year"}
                  >
                    Year
                  </button>
                </li>
                <li className={css.rangeTypeItem}>
                  <button
                    onClick={() => this.setState({ range: "quarter" })}
                    disabled={this.state.range === "quarter"}
                  >
                    Quarter
                  </button>
                </li>
                <li className={css.rangeTypeItem}>
                  <button
                    onClick={() => this.setState({ range: "month" })}
                    disabled={this.state.range === "month"}
                  >
                    Month
                  </button>
                </li>
                <li className={css.rangeTypeItem}>
                  <button
                    onClick={() => this.setState({ range: "custom" })}
                    disabled={this.state.range === "custom"}
                  >
                    Custom
                  </button>
                </li>
              </ul>
              {this.state.range === "year" && (
                <div>
                  <ul className={css.yearList}>
                    {range(
                      DateTime.local().year,
                      DateTime.local().year - 15,
                      -1
                    ).map(year => (
                      <li className={css.yearListItem} key={year}>
                        <button
                          onClick={() => {
                            this.props.onChange({
                              range: "year",
                              startTime: DateTime.local(year).startOf("year"),
                              endTime: DateTime.local(year).endOf("year")
                            });
                            onClick();
                          }}
                          className={cx(buttonCss.styleless, css.yearButton)}
                        >
                          {year}
                        </button>
                      </li>
                    ))}

                    <li className={css.yearListItem}>
                      <input
                        className={css.yearOther}
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="Other"
                        onBlur={e => {
                          this.props.onChange({
                            range: "year",
                            startTime: DateTime.local(
                              +e.currentTarget.value
                            ).startOf("year"),
                            endTime: DateTime.local(
                              +e.currentTarget.value
                            ).endOf("year")
                          });
                          onClick();
                        }}
                      />
                    </li>
                  </ul>
                </div>
              )}
              {this.state.range === "quarter" && (
                <QuarterPicker
                  range={this.props.range}
                  onChange={range => {
                    this.props.onChange(range);
                    onClick();
                  }}
                />
              )}
              {this.state.range === "month" && (
                <MonthPicker
                  range={this.props.range}
                  onChange={range => {
                    this.props.onChange(range);
                    onClick();
                  }}
                />
              )}
              {this.state.range === "custom" && (
                <Calendar
                  className={css.calendar}
                  selectRange={true}
                  returnValue="range"
                  onChange={range => {
                    if (Array.isArray(range)) {
                      const [start, end] = range;
                      this.props.onChange({
                        startTime: DateTime.fromJSDate(start),
                        endTime: DateTime.fromJSDate(end),
                        range: "custom"
                      });
                      onClick();
                    }
                  }}
                />
              )}
            </div>
          )}
        >
          {humanizeRange(this.props.range)}
        </SimpleDropdown>
        <button
          className={cx(buttonCss.styleless, css.prevNextButton)}
          onClick={() => this.props.onChange(nextRange(this.props.range))}
          disabled={
            this.props.range.range === "custom" ||
            this.props.range.endTime.valueOf() >= now.valueOf()
          }
        >
          {">"}
        </button>
      </span>
    );
  }
}
