export type ScaledValue = number & { __brand: "ScaledValue" };

const SCALE_FACTOR = 10000;
const DECIMAL_PLACES = Math.round(Math.log10(SCALE_FACTOR));
const CENTS = 100;

export function scaleValue(value: number | string): ScaledValue {
  return Math.round(+value * SCALE_FACTOR) as ScaledValue;
}

export function unscaleValue(value: ScaledValue): number {
  return value / SCALE_FACTOR;
}

export function sum(...values: ScaledValue[]): ScaledValue {
  return values.reduce((acc, v) => (acc + v) as ScaledValue, 0 as ScaledValue);
}

export function roundToCents(value: ScaledValue): ScaledValue {
  const toCents = Math.round(SCALE_FACTOR / CENTS);
  return (Math.round(value / toCents) * toCents) as ScaledValue;
}

export function negate(value: ScaledValue): ScaledValue {
  return -value as ScaledValue;
}

export function zero(): ScaledValue {
  return 0 as ScaledValue;
}

export function abs(value: ScaledValue): ScaledValue {
  if (value >= 0) {
    return value;
  }
  return -value as ScaledValue;
}

export function formatUnscaled(value: number): string {
  if (Object.is(value, -0)) {
    value = 0;
  }
  return value.toLocaleString(undefined, {
    style: "currency",
    currency: "USD"
  });
}

export function format(value: ScaledValue): string {
  return formatUnscaled(unscaleValue(value));
}

export function formatDebitCredit(
  value: ScaledValue
): [string, undefined] | [undefined, string] {
  if (value > 0) {
    return [format(value), undefined];
  } else {
    return [undefined, format(negate(value))];
  }
}

export function fromString(num: string): ScaledValue {
  const str = num.replace(/[^0-9.]/g, "");
  const [integer, decimal] = str.split(".");
  return fromParts(integer, decimal);
}

export function fromParts(
  integer: string,
  decimal: string | undefined
): ScaledValue {
  return (parseInt(integer, 10) * SCALE_FACTOR +
    (decimal
      ? parseInt(decimal.padEnd(DECIMAL_PLACES, "0"), 10)
      : 0)) as ScaledValue;
}
