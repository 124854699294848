export function reducerInNs<T, K extends keyof T, A>(
  ns: K,
  r: (state: T[K], action: A, fullState: Readonly<T>) => T[K]
) {
  return function(state: T, action: A): T {
    return {
      ...state,
      [ns]: r(state[ns], action, state)
    };
  };
}

type DefinedReducer<S, A> = (state: S, action: A) => S;
type Reducer<S, A> = (state: S | undefined, action: A) => S;

export function reduceReducers<S, A>(
  initialState: S,
  ...reducers: (DefinedReducer<S, A>)[]
): Reducer<S, A> {
  return (prevState, value, ...args) => {
    const prevStateIsUndefined = typeof prevState === "undefined";
    const valueIsUndefined = typeof value === "undefined";

    if (prevStateIsUndefined && valueIsUndefined && initialState) {
      return initialState;
    }

    return reducers.reduce(
      (newState, reducer, index) => {
        if (typeof reducer === "undefined") {
          throw new TypeError(
            `An undefined reducer was passed in at index ${index}`
          );
        }

        return reducer(newState, value, ...args);
      },
      prevStateIsUndefined && !valueIsUndefined && initialState
        ? initialState
        : prevState || initialState
    );
  };
}

export function reduceDefinedReducers<S, A>(
  ...reducers: (DefinedReducer<S, A>)[]
): DefinedReducer<S, A> {
  return (prevState, value, ...args) => {
    return reducers.reduce((newState, reducer, index) => {
      if (typeof reducer === "undefined") {
        throw new TypeError(
          `An undefined reducer was passed in at index ${index}`
        );
      }

      return reducer(newState, value, ...args);
    }, prevState);
  };
}
