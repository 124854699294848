import { UUID } from "../../lib/core/uuid";
import { Filter } from "../../model/filtering";
import { DateTime } from "luxon";

export function setSelectedAccount(accountIds: UUID[]) {
  return {
    type: "LEDGERS/SET_SELECTED_ACCOUNT" as const,
    payload: { accountIds }
  };
}

export function selectedAccountsBack() {
  return {
    type: "LEDGERS/SELECTED_ACCOUNTS_BACK" as const
  };
}

export function selectedAccountsForward() {
  return {
    type: "LEDGERS/SELECTED_ACCOUNTS_FORWARD" as const
  };
}

export function setFilter(index: number, filter: Partial<Filter>) {
  return {
    type: "LEDGERS/SET_FILTER" as const,
    payload: { index, filter }
  };
}

export function clearFilter() {
  return {
    type: "LEDGERS/CLEAR_FILTER" as const
  };
}

export function removeFilter(index: number) {
  return {
    type: "LEDGERS/REMOVE_FILTER" as const,
    payload: { index }
  };
}

export function setFilterDateRange(start: DateTime, end: DateTime) {
  return {
    type: "LEDGERS/SET_FILTER_DATE_RANGE" as const,
    payload: { start, end }
  };
}

export function setSplitSelected(selectedSplits: { [id: string]: boolean }) {
  return {
    type: "LEDGERS/SET_SPLIT_SELECTED" as const,
    payload: { selectedSplits }
  };
}

export function clearSplitSelected() {
  return {
    type: "LEDGERS/CLEAR_SPLIT_SELECTED" as const
  };
}

export function setPaging(offset: number, size: number) {
  return {
    type: "LEDGERS/SET_PAGING" as const,
    payload: { offset, size }
  };
}

export type LedgersAction =
  | ReturnType<typeof setSelectedAccount>
  | ReturnType<typeof selectedAccountsBack>
  | ReturnType<typeof selectedAccountsForward>
  | ReturnType<typeof setFilter>
  | ReturnType<typeof clearFilter>
  | ReturnType<typeof removeFilter>
  | ReturnType<typeof setFilterDateRange>
  | ReturnType<typeof setSplitSelected>
  | ReturnType<typeof clearSplitSelected>
  | ReturnType<typeof setPaging>;
