import React, { useState } from "react";
import cx from "classnames";
import pageCss from "components/styles/page.module.css";
import css from "./accounts_settings.module.css";
import mainCss from "./main.module.css";
import { connect } from "react-redux";
import { AppState } from "data/store";
import {
  accountForestSelector,
  accountsListSelector,
  accountsMapSelector,
} from "data/accounts/selectors";
import { Account } from "model/bookkeeping";
import { IS_PLACEHOLDER } from "data/accounts/tags";
import buttonCss from "../styles/button.module.css";
import {
  addSubaccountHierarchy,
  deleteAccounts,
  updateAccount,
  setAccountTag,
  moveAllSplitsInAccount,
} from "data/accounts/actions";
import { breakAccountName, joinAccountName } from "lib/accounts/hierarchy";
import { UUID } from "lib/core/uuid";
import { AccountsList } from "./accounts_list";
import { selectedAccountIdSelector } from "data/ledgers/selectors";
import { AccountSelector } from "components/common/account";
import { ReactComponent as PenIcon } from "assets/font-awesome-solid/pen.svg";

const Renamer = React.memo<{
  account: Account;
  accountNameSelf: string;
  parentAccount?: Account;
  allAccounts: Account[];
  onRenameAccount(id: UUID, name: string): void;
}>((props) => {
  const { accountNameSelf, parentAccount } = props;
  const [inRenaming, setInRenaming] = useState(false);
  const [renamedName, setRenamedName] = useState(accountNameSelf);
  const newName = joinAccountName([parentAccount?.name || "", renamedName]);
  const newNameOccupied = !!props.allAccounts.find(
    (acc) => acc.name === newName
  );

  if (inRenaming) {
    return (
      <>
        <input
          value={renamedName}
          onChange={(e) => setRenamedName(e.currentTarget.value)}
        />
        <button
          disabled={!newName || newNameOccupied}
          className={cx(buttonCss.button, buttonCss.buttonPurple)}
          onClick={() => {
            props.onRenameAccount(props.account.id, newName);
            setInRenaming(false);
          }}
        >
          Rename
        </button>
        <button
          className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
          onClick={() => {
            setInRenaming(false);
          }}
        >
          Cancel
        </button>
      </>
    );
  } else {
    return (
      <>
        {accountNameSelf}{" "}
        <button
          className={buttonCss.styleless}
          onClick={() => setInRenaming(true)}
        >
          <PenIcon width={10} height={10} />
        </button>
      </>
    );
  }
});

const Mover = React.memo<{
  account: Account;
  accountsMap: Map<UUID, Account>;
  accountNameSelf: string;
  parentAccount?: Account;
  allAccounts: Account[];
  onRenameAccount(id: UUID, name: string): void;
}>((props) => {
  const { accountNameSelf, parentAccount } = props;

  const [inMoving, setInMoving] = useState(false);
  const [newParentAccount, setNewParentAccount] = useState(parentAccount?.id);
  const movedFullName = joinAccountName([
    (newParentAccount && props.accountsMap.get(newParentAccount)?.name) || "",
    accountNameSelf,
  ]);
  const movedFullNameOccupied = !!props.allAccounts.find(
    (acc) => acc.name === movedFullName
  );

  if (inMoving) {
    return (
      <>
        <AccountSelector
          accounts={props.accountsMap}
          value={newParentAccount}
          onChange={setNewParentAccount}
          hidePlaceholders={false}
        />
        <button
          disabled={!movedFullName || movedFullNameOccupied}
          className={cx(buttonCss.button, buttonCss.buttonPurple)}
          onClick={() => {
            props.onRenameAccount(props.account.id, movedFullName);
            setInMoving(false);
          }}
        >
          Move
        </button>
        <button
          className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
          onClick={() => {
            setInMoving(false);
          }}
        >
          Cancel
        </button>
      </>
    );
  } else {
    return (
      <>
        {parentAccount ? parentAccount.name : "(None)"}{" "}
        <button
          className={buttonCss.styleless}
          onClick={() => setInMoving(true)}
        >
          <PenIcon width={10} height={10} />
        </button>
      </>
    );
  }
});

export const SingleAccount: React.FC<{
  account: Account;
  allAccounts: Account[];
  accountsMap: Map<UUID, Account>;
  onCreateAccount(name: string): void;
  onDeleteAccount(id: UUID, moveTo: UUID): void;
  onRenameAccount(id: UUID, name: string): void;
  onMakeAccountPlaceholder(moveTo: UUID): Promise<void>;
  onMakeAccountNotPlaceholder(): void;
}> = React.memo((props) => {
  const accountNameHierarchy = breakAccountName(props.account.name);
  const accountNameSelf = accountNameHierarchy.pop()!;
  const parentAccountName = joinAccountName(accountNameHierarchy);
  const parentAccount = props.allAccounts.find(
    (acc) => acc.name === parentAccountName
  );

  const [inAccountCreation, setInAccountCreation] = useState(false);
  const [newAccountName, setNewAccountName] = useState("");

  const [inDeletion, setInDeletion] = useState(false);
  const [accountToMoveTo, setAccountToMoveTo] = useState<string | undefined>(
    undefined
  );

  const [inMakePlaceholder, setInMakePlaceholder] = useState(false);
  const [placeholderMoveToAccount, setPlaceholderMoveToAccount] = useState<
    UUID | undefined
  >(undefined);

  return (
    <div className={cx(pageCss.block, css.settings)}>
      <h2>{accountNameSelf}</h2>
      <div>
        <div>
          <h3>Basic Information</h3>
          <table>
            <tbody>
              <tr>
                <td>Placeholder Account</td>
                <td>
                  {!!+props.account.tags[IS_PLACEHOLDER] ? (
                    <>
                      Yes{" "}
                      <button
                        className={cx(buttonCss.styleless, css.link)}
                        onClick={() => props.onMakeAccountNotPlaceholder()}
                      >
                        [Convert to Regular Account]
                      </button>
                    </>
                  ) : inMakePlaceholder ? (
                    <>
                      Move existing legs into:
                      <AccountSelector
                        accounts={props.accountsMap}
                        value={placeholderMoveToAccount}
                        onChange={setPlaceholderMoveToAccount}
                        hidePlaceholders={true}
                      />
                      <button
                        className={cx(buttonCss.button, buttonCss.buttonPurple)}
                        disabled={!placeholderMoveToAccount}
                        onClick={async () => {
                          if (placeholderMoveToAccount) {
                            await props.onMakeAccountPlaceholder(
                              placeholderMoveToAccount
                            );
                            setInMakePlaceholder(false);
                          }
                        }}
                      >
                        OK
                      </button>
                      <button
                        className={cx(
                          buttonCss.button,
                          buttonCss.buttonPurpleOutline
                        )}
                        onClick={() => setInMakePlaceholder(false)}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      No{" "}
                      <button
                        className={cx(buttonCss.styleless, css.link)}
                        onClick={() => setInMakePlaceholder(true)}
                      >
                        [Convert to Placeholder]
                      </button>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td>Account Name</td>
                <td>
                  <Renamer
                    account={props.account}
                    allAccounts={props.allAccounts}
                    accountNameSelf={accountNameSelf}
                    parentAccount={parentAccount}
                    onRenameAccount={props.onRenameAccount}
                  />
                </td>
              </tr>
              <tr>
                <td>Parent Account</td>
                <td>
                  <Mover
                    account={props.account}
                    accountsMap={props.accountsMap}
                    allAccounts={props.allAccounts}
                    accountNameSelf={accountNameSelf}
                    parentAccount={parentAccount}
                    onRenameAccount={props.onRenameAccount}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <h3>Actions</h3>
          <ul className={css.actions}>
            <li>
              {inAccountCreation ? (
                <>
                  <input
                    type="text"
                    placeholder="Account name"
                    value={newAccountName}
                    onChange={(e) => setNewAccountName(e.currentTarget.value)}
                  />
                  <button
                    className={cx(buttonCss.button, buttonCss.buttonBlue)}
                    onClick={() => {
                      props.onCreateAccount(newAccountName);
                      setNewAccountName("");
                      setInAccountCreation(false);
                    }}
                  >
                    OK
                  </button>
                  <button
                    className={cx(
                      buttonCss.button,
                      buttonCss.buttonBlueOutline
                    )}
                    onClick={() => {
                      setInAccountCreation(false);
                    }}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  className={cx(buttonCss.styleless, css.link)}
                  onClick={() => setInAccountCreation(true)}
                >
                  Create a new subaccount...
                </button>
              )}
            </li>
            <li>
              {inDeletion ? (
                <>
                  Before deleting, move all entries into...
                  <AccountSelector
                    accounts={props.accountsMap}
                    value={accountToMoveTo}
                    onChange={setAccountToMoveTo}
                    hidePlaceholders={true}
                  />
                  <button
                    disabled={!accountToMoveTo}
                    className={cx(buttonCss.button, buttonCss.buttonPurple)}
                    onClick={() => {
                      if (accountToMoveTo) {
                        props.onDeleteAccount(
                          props.account.id,
                          accountToMoveTo
                        );
                        setInDeletion(false);
                      }
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className={cx(
                      buttonCss.button,
                      buttonCss.buttonBlueOutline
                    )}
                    onClick={() => {
                      setInDeletion(false);
                    }}
                  >
                    Cancel
                  </button>
                </>
              ) : (
                <button
                  className={cx(buttonCss.styleless, css.link)}
                  onClick={() => setInDeletion(true)}
                >
                  Delete this account...
                </button>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

SingleAccount.displayName = "SingleAccount";

const attachment = () => undefined;

export const AccountsSettings: React.FC<{
  accountForest: ReturnType<typeof accountForestSelector>;
  accountsList: Account[];
  selectedAccountIds: UUID[];

  accountsMap: Map<UUID, Account>;
  addSubaccountHierarchy(parentName: string, childName: string): void;
  deleteAccounts(accounts: { accountId: UUID; moveTo: UUID }[]): void;
  updateAccount(id: UUID, name: string): void;
  setAccountTag(id: UUID, tag: string, value: string): Promise<void>;
  moveAllSplitsInAccount(
    accounts: { accountId: UUID; moveTo: UUID }[]
  ): Promise<void>;
}> = (props) => {
  const [accountId, setAccountId] = useState<UUID | undefined>(
    props.selectedAccountIds[0]
  );

  const setSelectedAccount = (ids: UUID[]) => {
    setAccountId(ids[0]);
  };

  const account = accountId && props.accountsMap.get(accountId);

  return (
    <>
      <div className={cx(mainCss.accounts)}>
        <div className={cx(pageCss.block, mainCss.accountsBlock)}>
          <AccountsList
            accountForest={props.accountForest}
            multiSelect={false}
            attachment={attachment}
            selectedAccounts={accountId ? [accountId] : []}
            onSelectAccounts={setSelectedAccount}
          />
        </div>
      </div>
      <div className={cx(mainCss.display)}>
        {account && accountId && (
          <SingleAccount
            key={account.id}
            account={account}
            accountsMap={props.accountsMap}
            allAccounts={props.accountsList}
            onCreateAccount={(name) =>
              props.addSubaccountHierarchy(account.name, name)
            }
            onDeleteAccount={(accountId, moveTo) =>
              props.deleteAccounts([{ accountId, moveTo }])
            }
            onRenameAccount={props.updateAccount}
            onMakeAccountNotPlaceholder={() => {
              props.setAccountTag(accountId, IS_PLACEHOLDER, "0");
            }}
            onMakeAccountPlaceholder={async (moveTo) => {
              await props.moveAllSplitsInAccount([{ accountId, moveTo }]);
              await props.setAccountTag(accountId, IS_PLACEHOLDER, "1");
            }}
          />
        )}
      </div>
    </>
  );
};

AccountsSettings.displayName = "AccountsSettings";

export const ConnectedAccountsSettings = connect(
  (state: AppState) => ({
    accountForest: accountForestSelector(state),
    accountsList: accountsListSelector(state),
    accountsMap: accountsMapSelector(state),
    selectedAccountIds: selectedAccountIdSelector(state),
  }),
  {
    addSubaccountHierarchy,
    deleteAccounts,
    updateAccount,
    setAccountTag,
    moveAllSplitsInAccount,
  }
)(AccountsSettings);
