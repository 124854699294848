import { BMUser } from "data/auth/types";
import { Feature, FEATURE_PLAN, FEATURE_ENABLED } from "static_data/billing";

export function hasFeatureEnabled(user: BMUser, feature: Feature) {
  for (const plan of user.billingPlans) {
    if (
      FEATURE_PLAN[feature] &&
      FEATURE_PLAN[feature][plan] === FEATURE_ENABLED
    ) {
      return true;
    }
  }
  return false;
}
