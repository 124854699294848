export function runOobe() {
  return {
    type: "OOBE/RUN_OOBE" as const
  };
}

export function finishOobe() {
  return {
    type: "OOBE/FINISH_OOBE" as const
  };
}

export type Action = ReturnType<typeof runOobe> | ReturnType<typeof finishOobe>;
