import { Account, ExternalAccountType } from "../model/bookkeeping";
import { newUuid } from "../lib/core/uuid";
import {
  OPENING_BALANCE,
  TYPE,
  TYPE_VALUES,
  EXTERNAL_ACCOUNT_TYPE,
  ENTRY_CATEGORY,
  BASIC_ACCOUNT,
  BASIC_ACCOUNT_VALUES,
  CASH_ACCOUNT,
  IS_PLACEHOLDER
} from "../data/accounts/tags";
import { EntryCategory } from "./categories";
import { addIntermediateAccountsInPlace } from "lib/accounts/hierarchy";

export enum Scenario {
  INVESTMENT = "INVESTMENT",
  DEPOSIT = "DEPOSIT",
  RENT_RESIDENCE = "RENT_RESIDENCE",
  OWN_RESIDENCE = "OWN_RESIDENCE",
  AUTO = "AUTO",
  CHILD = "CHILD",
  PET = "PET"
}

export function buildBasicAccounts(scenario: Set<Scenario>) {
  const accounts: Account[] = [
    {
      id: newUuid(),
      name: "Assets",
      tags: {
        [TYPE]: TYPE_VALUES.DEBIT,
        [BASIC_ACCOUNT]: BASIC_ACCOUNT_VALUES.ASSETS,
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Assets:Checking",
      tags: {
        [EXTERNAL_ACCOUNT_TYPE]: ExternalAccountType.CHECKING,
        [CASH_ACCOUNT]: "1",
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Assets:Savings",
      tags: {
        [EXTERNAL_ACCOUNT_TYPE]: ExternalAccountType.SAVINGS,
        [CASH_ACCOUNT]: "1",
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Assets:Certificate of Deposit",
      tags: {
        [EXTERNAL_ACCOUNT_TYPE]: ExternalAccountType.CD,
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Assets:Money Market",
      tags: {
        [EXTERNAL_ACCOUNT_TYPE]: ExternalAccountType.MONEY_MARKET,
        [CASH_ACCOUNT]: "1",
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Liabilities",
      tags: {
        [TYPE]: TYPE_VALUES.CREDIT,
        [BASIC_ACCOUNT]: BASIC_ACCOUNT_VALUES.LIABILITIES,
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Liabilities:Credit Cards",
      tags: {
        [EXTERNAL_ACCOUNT_TYPE]: ExternalAccountType.CREDIT_CARD,
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Income",
      tags: {
        [TYPE]: TYPE_VALUES.CREDIT,
        [BASIC_ACCOUNT]: BASIC_ACCOUNT_VALUES.INCOME,
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Income:Regular Pay",
      tags: {
        [ENTRY_CATEGORY]: "" + EntryCategory.REGULAR_PAY
      }
    },
    {
      id: newUuid(),
      name: "Income:Bonus Pay",
      tags: {
        [ENTRY_CATEGORY]: "" + EntryCategory.BONUS_INCOME
      }
    },
    {
      id: newUuid(),
      name: "Income:Benefits",
      tags: {
        [ENTRY_CATEGORY]: "" + EntryCategory.BENEFIT_INCOME
      }
    },
    {
      id: newUuid(),
      name: "Expenses",
      tags: {
        [TYPE]: TYPE_VALUES.DEBIT,
        [BASIC_ACCOUNT]: BASIC_ACCOUNT_VALUES.EXPENSES,
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Equity",
      tags: {
        [TYPE]: TYPE_VALUES.CREDIT,
        [BASIC_ACCOUNT]: BASIC_ACCOUNT_VALUES.EQUITY,
        [IS_PLACEHOLDER]: "1"
      }
    },
    {
      id: newUuid(),
      name: "Equity:Opening Balance",
      tags: {
        [OPENING_BALANCE]: "1"
      }
    }
  ];

  // Expenses
  accounts.push(
    {
      id: newUuid(),
      name: "Expenses:Auto",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.AUTO }
    },
    {
      id: newUuid(),
      name: "Expenses:Auto:Auto Insurance",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.AUTO_INSURANCE }
    },
    {
      id: newUuid(),
      name: "Expenses:Auto:Auto Maintenance",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.AUTO_MAINTENANCE }
    },
    {
      id: newUuid(),
      name: "Expenses:Auto:Auto Payment",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.AUTO_PAYMENT }
    },
    {
      id: newUuid(),
      name: "Expenses:Auto:Gas",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.GAS }
    },
    {
      id: newUuid(),
      name: "Expenses:Auto:Parking",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PARKING }
    },
    {
      id: newUuid(),
      name: "Expenses:Auto:Toll",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ROAD_TOLL }
    },
    {
      id: newUuid(),
      name: "Expenses:Childcare:Allowance",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ALLOWANCE }
    },
    {
      id: newUuid(),
      name: "Expenses:Childcare:Baby Supplies",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.BABY_SUPPLIES }
    },
    {
      id: newUuid(),
      name: "Expenses:Childcare:Babysitter",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.BABYSITTER }
    },
    {
      id: newUuid(),
      name: "Expenses:Childcare:Child Activities",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.CHILD_ACTIVITIES }
    },
    {
      id: newUuid(),
      name: "Expenses:Childcare:Daycare",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.DAYCARE }
    },
    {
      id: newUuid(),
      name: "Expenses:Childcare:Toys",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.TOYS }
    },
    {
      id: newUuid(),
      name: "Expenses:Community",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.COMMUNITY }
    },
    {
      id: newUuid(),
      name: "Expenses:Education:Books and Supplies",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.EDUCATION_BOOKS_SUPPLIES }
    },
    {
      id: newUuid(),
      name: "Expenses:Education:Professional Development",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PROFESSIONAL_DEVELOPMENT }
    },
    {
      id: newUuid(),
      name: "Expenses:Education:Student Loan",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.STUDENT_LOAN }
    },
    {
      id: newUuid(),
      name: "Expenses:Education:Tuition",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.TUITION }
    },
    {
      id: newUuid(),
      name: "Expenses:Entertainment",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ENTERTAINMENT }
    },
    {
      id: newUuid(),
      name: "Expenses:Entertainment:Amusement Park",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.AMUSEMENT_PARK }
    },
    {
      id: newUuid(),
      name: "Expenses:Entertainment:Cinema",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.CINEMA }
    },
    {
      id: newUuid(),
      name: "Expenses:Entertainment:Cultural",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.CULTURAL_ACTIVITIES }
    },
    {
      id: newUuid(),
      name: "Expenses:Entertainment:Music",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.MUSIC }
    },
    {
      id: newUuid(),
      name: "Expenses:Entertainment:Sports",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.SPORTS }
    },
    {
      id: newUuid(),
      name: "Expenses:Fees:ATM Fee",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ATM_FEE }
    },
    {
      id: newUuid(),
      name: "Expenses:Fees:Bank Account Fee",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.BANK_ACCOUNT_FEES }
    },
    {
      id: newUuid(),
      name: "Expenses:Fees:Credit Card Fee",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.CREDIT_CARD_FEES }
    },
    {
      id: newUuid(),
      name: "Expenses:Fees:Late Fee",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.LATE_FEE }
    },
    {
      id: newUuid(),
      name: "Expenses:Fees:Trade Commission",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.TRADE_COMMISSION }
    },
    {
      id: newUuid(),
      name: "Expenses:Financing:Interest",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.INTEREST }
    },
    {
      id: newUuid(),
      name: "Expenses:Food",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.FOOD }
    },
    {
      id: newUuid(),
      name: "Expenses:Food:Bar",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.BAR }
    },
    {
      id: newUuid(),
      name: "Expenses:Food:Coffee and Tea",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.COFFEE_TEA_SHOP }
    },
    {
      id: newUuid(),
      name: "Expenses:Food:Fast Food",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.FAST_FOOD }
    },
    {
      id: newUuid(),
      name: "Expenses:Food:Food Delivery",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.FOOD_DELIVERY }
    },
    {
      id: newUuid(),
      name: "Expenses:Food:Grocery",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.GROCERY }
    },
    {
      id: newUuid(),
      name: "Expenses:Food:Restaurant",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.RESTAURANT }
    },
    {
      id: newUuid(),
      name: "Expenses:Giving:Charity",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.CHARITY }
    },
    {
      id: newUuid(),
      name: "Expenses:Giving:Gift",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.GIFT }
    },
    {
      id: newUuid(),
      name: "Expenses:Government Service",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.GOVERNMENT }
    },
    {
      id: newUuid(),
      name: "Expenses:Healthcare:Dentist",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.DENTIST }
    },
    {
      id: newUuid(),
      name: "Expenses:Healthcare:Doctor",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.DOCTOR }
    },
    {
      id: newUuid(),
      name: "Expenses:Healthcare:Eyecare",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.EYECARE }
    },
    {
      id: newUuid(),
      name: "Expenses:Healthcare:Health Insurance",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.HEALTH_INSURANCE }
    },
    {
      id: newUuid(),
      name: "Expenses:Healthcare:Pharmacy",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PHARMACY }
    },
    {
      id: newUuid(),
      name: "Expenses:Home:Furnishing",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.FURNITURE }
    },
    {
      id: newUuid(),
      name: "Expenses:Home:Garden",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.GARDEN }
    },
    {
      id: newUuid(),
      name: "Expenses:Home:Home Improvement",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.HOME_IMPROVEMENT }
    },
    {
      id: newUuid(),
      name: "Expenses:Home:Home Services",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.HOME_SERVICES }
    },

    {
      id: newUuid(),
      name: "Expenses:Home:Household Supplies",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.HOUSEHOLD_ITEMS }
    },
    {
      id: newUuid(),
      name: "Expenses:Insurances",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.INSURANCE }
    },
    {
      id: newUuid(),
      name: "Expenses:Insurances:Life Insurance",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.LIFE_INSURANCE }
    },
    {
      id: newUuid(),
      name: "Expenses:Miscellaneous:Adjustments",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ADJUSTMENT }
    },
    {
      id: newUuid(),
      name: "Expenses:Miscellaneous:Cash Expenses",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ATM_WITHDRAWAL }
    },
    {
      id: newUuid(),
      name: "Expenses:Miscellaneous:Check",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.CHECK }
    },
    {
      id: newUuid(),
      name: "Expenses:Personal Care",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PERSONAL_CARE }
    },
    {
      id: newUuid(),
      name: "Expenses:Personal Care:Gym",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.GYM }
    },
    {
      id: newUuid(),
      name: "Expenses:Personal Care:Laundry",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.LAUNDRY }
    },
    {
      id: newUuid(),
      name: "Expenses:Personal Care:Massage",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.MASSAGE }
    },
    {
      id: newUuid(),
      name: "Expenses:Personal Care:Salon",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.SALON }
    },
    {
      id: newUuid(),
      name: "Expenses:Pets:Pet Food",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PET_FOOD }
    },
    {
      id: newUuid(),
      name: "Expenses:Pets:Pet Grooming",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PET_GROOMING }
    },
    {
      id: newUuid(),
      name: "Expenses:Pets:Veterinary",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.VETERINARY }
    },
    {
      id: newUuid(),
      name: "Expenses:Professional Services",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PROFESSIONAL_SERVICE }
    },
    {
      id: newUuid(),
      name: "Expenses:Professional Services:Advertising",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ADVERTISING }
    },
    {
      id: newUuid(),
      name: "Expenses:Professional Services:Financial Advisor",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.FINANCIAL_ADVISOR }
    },
    {
      id: newUuid(),
      name: "Expenses:Professional Services:Legal",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.LEGAL }
    },
    {
      id: newUuid(),
      name: "Expenses:Professional Services:Online Services",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ONLINE_SERVICES }
    },
    {
      id: newUuid(),
      name: "Expenses:Professional Services:Shipping",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.SHIPPING }
    },
    {
      id: newUuid(),
      name: "Expenses:Shopping",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.SHOPPING }
    },
    {
      id: newUuid(),
      name: "Expenses:Shopping:Bookstore",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.BOOKS }
    },
    {
      id: newUuid(),
      name: "Expenses:Shopping:Clothing",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.CLOTHING }
    },
    {
      id: newUuid(),
      name: "Expenses:Shopping:Electronics",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.ELECTRONICS }
    },
    {
      id: newUuid(),
      name: "Expenses:Shopping:Hobbies",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.HOBBY }
    },
    {
      id: newUuid(),
      name: "Expenses:Shopping:Office Supplies",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.OFFICE_SUPPLIES }
    },
    {
      id: newUuid(),
      name: "Expenses:Subscriptions:Magazine",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.MAGAZINE }
    },
    {
      id: newUuid(),
      name: "Expenses:Subscriptions:Newspaper",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.NEWSPAPER }
    },
    {
      id: newUuid(),
      name: "Expenses:Subscriptions:Streaming Services",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.STREAMING_SUBSCRIPTION }
    },
    {
      id: newUuid(),
      name: "Expenses:Taxes",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.TAX }
    },
    {
      id: newUuid(),
      name: "Expenses:Taxes:Federal Tax",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.FEDERAL_TAX }
    },
    {
      id: newUuid(),
      name: "Expenses:Taxes:Local Tax",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.LOCAL_TAX }
    },
    {
      id: newUuid(),
      name: "Expenses:Taxes:Sales Tax",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.SALES_TAX }
    },
    {
      id: newUuid(),
      name: "Expenses:Taxes:State Tax",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.STATE_TAX }
    },
    {
      id: newUuid(),
      name: "Expenses:Telecom:Landline",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.LANDLINE }
    },
    {
      id: newUuid(),
      name: "Expenses:Telecom:Mobile Phone",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.MOBILE_PHONE }
    },
    {
      id: newUuid(),
      name: "Expenses:Telecom:Television",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.TELEVISION }
    },
    {
      id: newUuid(),
      name: "Expenses:Transportation:Public Transit",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PUBLIC_TRANSIT }
    },
    {
      id: newUuid(),
      name: "Expenses:Transportation:Taxi",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.TAXI }
    },
    {
      id: newUuid(),
      name: "Expenses:Travel",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.TRAVEL }
    },
    {
      id: newUuid(),
      name: "Expenses:Travel:Airfare",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.AIRFARE }
    },
    {
      id: newUuid(),
      name: "Expenses:Travel:Hotel",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.HOTEL }
    },
    {
      id: newUuid(),
      name: "Expenses:Travel:Rental Car",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.RENTAL_CAR }
    },
    {
      id: newUuid(),
      name: "Expenses:Travel:Vacation",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.VACATION }
    },
    {
      id: newUuid(),
      name: "Expenses:Utilities:Electricity",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.UTILITIES_ELECTRICITY }
    },
    {
      id: newUuid(),
      name: "Expenses:Utilities:Gas",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.UTILITIES_GAS }
    },
    {
      id: newUuid(),
      name: "Expenses:Utilities:Internet",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.INTERNET }
    },
    {
      id: newUuid(),
      name: "Expenses:Utilities:Water",
      tags: { [ENTRY_CATEGORY]: "" + EntryCategory.UTILITIES_WATER }
    }
  );

  scenario.forEach(s => {
    switch (s) {
      case Scenario.INVESTMENT:
        accounts.push(
          {
            id: newUuid(),
            name: "Income:Investment Income:Stock Dividends (Qualified)",
            tags: {}
          },
          {
            id: newUuid(),
            name: "Income:Investment Income:Stock Dividends (Ordinary)",
            tags: {}
          },
          {
            id: newUuid(),
            name: "Income:Investment Income:Bond Dividends",
            tags: {
              [ENTRY_CATEGORY]: "" + EntryCategory.DIVIDEND_INCOME
            }
          },
          {
            id: newUuid(),
            name: "Income:Investment Income:Capital Gains (Short Term)",
            tags: {}
          },
          {
            id: newUuid(),
            name: "Income:Investment Income:Capital Gains (Long Term)",
            tags: {}
          }
        );
        break;
      case Scenario.DEPOSIT:
        accounts.push({
          id: newUuid(),
          name: "Income:Interest Income",
          tags: {
            [ENTRY_CATEGORY]: "" + EntryCategory.INTEREST_INCOME
          }
        });
        break;
      case Scenario.RENT_RESIDENCE:
        accounts.push(
          {
            id: newUuid(),
            name: "Expenses:Home:Rent",
            tags: {
              [ENTRY_CATEGORY]: "" + EntryCategory.RENT
            }
          },
          {
            id: newUuid(),
            name: "Expenses:Home:Renters Insurance",
            tags: {
              [ENTRY_CATEGORY]: "" + EntryCategory.RENTERS_INSURANCE
            }
          }
        );
        break;
      case Scenario.OWN_RESIDENCE:
        accounts.push(
          {
            id: newUuid(),
            name: "Expenses:Home:Mortgage Interest",
            tags: {}
          },
          {
            id: newUuid(),
            name: "Expenses:Home:Homeowner Insurance",
            tags: { [ENTRY_CATEGORY]: "" + EntryCategory.HOMEOWNER_INSURANCE }
          },

          {
            id: newUuid(),
            name: "Expenses:Home:Property Tax",
            tags: { [ENTRY_CATEGORY]: "" + EntryCategory.PROPERTY_TAX }
          }
        );
        break;
    }
  });

  addIntermediateAccountsInPlace(accounts);

  return accounts;
}
