export enum IngestionFormats {
  OFX = "OFX"
}

export const EXTENSIONS_FORMATS: { [ext: string]: IngestionFormats } = {
  ".qfx": IngestionFormats.OFX,
  ".ofx": IngestionFormats.OFX
};

export function formatFromExtension(ext: string): IngestionFormats | null {
  return EXTENSIONS_FORMATS[ext.toLowerCase()] || null;
}
