import cx from "classnames";
import React, { PureComponent } from "react";

import buttonCss from "components/styles/button.module.css";
import css from "./tokenized_multiselect.module.css";
import inputCss from "components/styles/input.module.css";

type Props<T, D> = {
  id(data: T): D;
  display(data: T): React.ReactNode;
  selector(onSelected: (id: D) => void): React.ReactNode;
  selectedIds: D[];
  onChange(accounts: D[]): void;
  data: Map<D, T>;
};

export class TokenizedMultiSelect<T, D> extends PureComponent<Props<T, D>, {}> {
  onAccountSelected = (id: D) => {
    this.props.onChange([...this.props.selectedIds, id]);
  };

  remove = (id: D) => {
    this.props.onChange(this.props.selectedIds.filter((el) => el !== id));
  };

  render() {
    return (
      <div className={cx(css.multiAccountInput, inputCss.input)}>
        {this.props.selectedIds.map((id) => (
          <span className={css.token}>
            {this.props.display(this.props.data.get(id)!)}
            <button
              className={buttonCss.styleless}
              onClick={() => this.remove(id)}
            >
              X
            </button>
          </span>
        ))}
        {this.props.selector(this.onAccountSelected)}
      </div>
    );
  }
}
