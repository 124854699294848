import { sum } from "model/scaled_value";
import { Split } from "model/bookkeeping";
import groupBy from "lodash/groupBy";

export function formValidTransactions(
  splits: Pick<Split, "transactionId" | "valueScaled">[]
) {
  return !Object.values(groupBy(splits, s => s.transactionId)).some(
    splits => sum(...splits.map(s => s.valueScaled)) !== 0
  );
}
