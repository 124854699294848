import React, { PureComponent } from "react";
import cx from "classnames";
import Autosuggest from "react-autosuggest";
import { connect } from "react-redux";

import { accountsMapSelector } from "../../data/accounts/selectors";
import { AppState } from "../../data/store";
import { UUID } from "../../lib/core/uuid";
import { Account } from "../../model/bookkeeping";
import { Tooltip } from "react-lightweight-tooltip";

import css from "./account.module.css";
import autosuggestCss from "./account_autosuggest.module.css";
import mergeWith from "lodash/mergeWith";
import { IS_PLACEHOLDER } from "data/accounts/tags";

import inputCss from "components/styles/input.module.css";

export const AccountDisplay: React.FC<{ accountName: string }> = React.memo(
  (props) => {
    const chunks = props.accountName.split(":");

    return (
      <Tooltip
        content={chunks.slice(0, -1).join(":")}
        styles={{
          wrapper: {
            zIndex: undefined,
            cursor: undefined,
            color: undefined,
          },
          tooltip: {
            minWidth: undefined,
          },
          content: {
            whiteSpace: "nowrap",
          },
          arrow: {},
          gap: {},
        }}
      >
        <span className={css.accountDisplay}>{chunks[chunks.length - 1]}</span>
      </Tooltip>
    );
  }
);

interface OwnProps {
  id?: string;
  value?: UUID;
  onChange?(value: UUID): void;
  accountsFilter?(a: Account): boolean;
  disabled?: boolean;
  readOnly?: boolean;
  inputProps?: React.InputHTMLAttributes<{}>;
  displayProps?: React.HTMLAttributes<{}>;
  theme?: Partial<Record<Autosuggest.ThemeKey, string>>;
  hidePlaceholders?: boolean;
}

interface DerivedProps {
  accounts: Map<UUID, Account>;
}

type Props = DerivedProps & OwnProps;

type Suggestion = [UUID, string];

interface State {
  suggestions: Suggestion[];
  inputValue?: string;
}

export class AccountSelector extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      suggestions: [],
    };
  }

  private inputRef = React.createRef<any>();

  componentDidUpdate(prevProps: Props) {
    if (this.props.value !== prevProps.value && this.state.inputValue != null) {
      this.setState({
        inputValue: this.props.value
          ? (this.props.accounts.get(this.props.value) || { name: "" }).name
          : "",
      });
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onInputChange = (e: any, { newValue }: Autosuggest.ChangeEvent) => {
    this.setState({ inputValue: newValue });
  };

  getSuggestionValue = (suggestion: [UUID, string]) => {
    return suggestion[1];
  };

  renderSuggestion: Autosuggest.RenderSuggestion<[UUID, string]> = (
    suggestion,
    { isHighlighted }
  ) => {
    return <div>{suggestion[1]}</div>;
  };

  onSuggestionsFetchRequested: Autosuggest.SuggestionsFetchRequested = ({
    value,
    reason,
  }) => {
    if (this.props.readOnly || this.props.disabled) {
      if (this.state.suggestions.length > 0) {
        this.setState({ suggestions: [] });
      }
      return;
    }
    const result: [UUID, string][] = [];
    this.props.accounts.forEach((account, key) => {
      const name = account.name;
      if (this.props.accountsFilter && !this.props.accountsFilter(account)) {
        return;
      }
      if (this.props.hidePlaceholders && !!+account.tags[IS_PLACEHOLDER]) {
        return;
      }
      if (name.toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
        result.push([key, name]);
      }
    });
    result.sort((a, b) => a[1].localeCompare(b[1]));
    this.setState({ suggestions: result });
  };

  onSuggestionSelected: Autosuggest.OnSuggestionSelected<Suggestion> = (
    e,
    { suggestion }
  ) => {
    this.setState({ inputValue: undefined });
    if (this.props.onChange) {
      this.props.onChange(suggestion[0]);
    }
  };

  onStartEditing = () => {
    this.setState({ inputValue: "" }, () => {
      if (this.inputRef.current) {
        this.inputRef.current.input.focus();
      }
    });
  };

  onBlur = () => {
    this.setState({ inputValue: undefined });
  };

  render() {
    if (this.state.inputValue == null && this.props.value != null) {
      return (
        <button
          onClick={this.onStartEditing}
          {...this.props.displayProps}
          className={cx(
            css.accountSelector,
            this.props.displayProps?.className ? undefined : inputCss.input,
            this.props.displayProps?.className
          )}
          disabled={this.props.disabled || this.props.readOnly}
        >
          <AccountDisplay
            accountName={
              (this.props.accounts.get(this.props.value) || { name: "" }).name
            }
          />
        </button>
      );
    }
    return (
      <Autosuggest
        id={this.props.id}
        ref={this.inputRef}
        suggestions={this.state.suggestions}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        inputProps={{
          placeholder: "Select an account",
          ...this.props.inputProps,
          className: this.props.inputProps?.className || inputCss.input,
          value: this.state.inputValue || "",
          onChange: this.onInputChange,
          disabled: this.props.disabled,
          readOnly: this.props.readOnly,
          onBlur: this.onBlur,
        }}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        theme={
          this.props.theme
            ? mergeWith(
                autosuggestCss,
                this.props.theme,
                (objValue, srcValue) => cx(objValue, srcValue)
              )
            : autosuggestCss
        }
      />
    );
  }
}

export const ConnectedAccountSelector = connect(
  (state: AppState, ownProps: OwnProps): DerivedProps => ({
    accounts: accountsMapSelector(state),
  })
)(AccountSelector);
