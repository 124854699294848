import React from "react";
import { ConnectedBalanceSheetSection } from "./sections/balance_sheet";
import css from "./main.module.css";
import pageCss from "components/styles/page.module.css";
import { ConnectedIncomeStatementSection } from "./sections/income_statement";
import { ConnectedCashFlowStatementSection } from "./sections/cash_flow_statement";

export const OverviewMain: React.FC<{}> = () => {
  return (
    <div className={pageCss.pageWrapper}>
      <div className={pageCss.pageTitleWrapper}>
        <h1 className={pageCss.pageTitle}>Overview</h1>
      </div>
      <div className={css.mainGrid}>
        <ConnectedBalanceSheetSection />
        <ConnectedIncomeStatementSection />
        <ConnectedCashFlowStatementSection />
      </div>
    </div>
  );
};
