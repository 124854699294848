export enum BillingPlan {
  STANDARD = "STANDARD"
}

export enum Feature {
  LINK_ACCOUNT = "LINK_ACCOUNT"
}

export const FEATURE_PLAN: {
  [feature: string]: { [plan: string]: string };
} = {};

function addFeaturePlan(feature: Feature, plan: BillingPlan, value: string) {
  if (!FEATURE_PLAN[feature]) {
    FEATURE_PLAN[feature] = {};
  }

  FEATURE_PLAN[feature][plan] = value;
}

export const FEATURE_ENABLED = "ENABLED";

addFeaturePlan(Feature.LINK_ACCOUNT, BillingPlan.STANDARD, FEATURE_ENABLED);
