import React, { PureComponent } from "react";
import cx from "classnames";
import { UUID } from "../../lib/core/uuid";
import { connect } from "react-redux";
import {
  accountForestSelector,
  AccountTree,
  accountsByBasicTypeMapSelector,
  totalBalanceSelector
} from "../../data/accounts/selectors";
import { AppState } from "../../data/store";
import { setSelectedAccount } from "../../data/ledgers/actions";
import { ReactComponent as CaretRight } from "assets/font-awesome-solid/caret-right.svg";
import { ReactComponent as CaretDown } from "assets/font-awesome-solid/caret-down.svg";
import css from "./accounts_list.module.css";
import buttonCss from "../styles/button.module.css";
import { selectedAccountIdSelector } from "data/ledgers/selectors";
import { IS_PLACEHOLDER, BASIC_ACCOUNT_VALUES } from "data/accounts/tags";
import { Account } from "model/bookkeeping";
import { ScaledValue, format } from "model/scaled_value";
import memoize from "memoize-one";

function traverse(root: AccountTree, func: (node: AccountTree) => void) {
  func(root);
  for (const children of root.children) {
    traverse(children, func);
  }
}

class CollapsibleAccounts extends PureComponent<
  {
    tree: AccountTree;
    onSelectAccounts(ids: UUID[]): void;
    level: number;
    selectedAccounts: UUID[];
    attachment(id: UUID): string | undefined;
    multiSelect: boolean;
  },
  { collapsed: boolean }
> {
  state = { collapsed: false };

  private toggle = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const allSubAccountIds: UUID[] = [];
    traverse(
      this.props.tree,
      node => node.account && allSubAccountIds.push(node.account.id)
    );
    const isSelected =
      this.props.tree.account &&
      this.props.selectedAccounts.includes(this.props.tree.account.id);
    const attachment =
      this.props.tree.account &&
      this.props.attachment(this.props.tree.account.id);
    const account = this.props.tree.account;
    return (
      <li>
        <div
          className={cx(css.accountLine, {
            [css.selectedAccountLine]: isSelected
          })}
          style={{
            paddingLeft: this.props.level * 12
          }}
        >
          {this.props.tree.children.length > 0 ? (
            <button
              onClick={this.toggle}
              className={cx(buttonCss.styleless, css.caret)}
            >
              {this.state.collapsed ? (
                <CaretRight width={8} height={8} />
              ) : (
                <CaretDown width={8} height={8} />
              )}
            </button>
          ) : null}
          <button
            onClick={e => {
              if (this.props.multiSelect) {
                let targetAccounts: Set<UUID>;
                if (e.altKey) {
                  targetAccounts = account ? new Set([account.id]) : new Set();
                } else {
                  targetAccounts = new Set(allSubAccountIds);
                }

                if (e.shiftKey) {
                  if (isSelected) {
                    this.props.onSelectAccounts(
                      this.props.selectedAccounts.filter(
                        id => !targetAccounts.has(id)
                      )
                    );
                  } else {
                    const newSelectedAccounts = [];
                    for (const id of this.props.selectedAccounts) {
                      if (!targetAccounts.has(id)) {
                        newSelectedAccounts.push(id);
                      }
                    }
                    for (const id of targetAccounts.values()) {
                      newSelectedAccounts.push(id);
                    }
                    this.props.onSelectAccounts(newSelectedAccounts);
                  }
                } else {
                  this.props.onSelectAccounts([...targetAccounts]);
                }
              } else {
                this.props.onSelectAccounts(account ? [account.id] : []);
              }
            }}
            className={cx(buttonCss.styleless, css.accountName)}
          >
            {this.props.tree.name}
          </button>
          {attachment ? (
            <span className={css.balance}>{attachment}</span>
          ) : null}
          {this.props.multiSelect &&
            allSubAccountIds.length > 1 &&
            account &&
            !account.tags[IS_PLACEHOLDER] && (
              <button
                onClick={() =>
                  this.props.onSelectAccounts([this.props.tree.account!.id])
                }
                className={cx(buttonCss.styleless, css.onlyButton)}
              >
                Only
              </button>
            )}
        </div>
        {this.state.collapsed ? null : (
          <ul className={css.accountList}>
            {this.props.tree.children.map(tree => (
              <CollapsibleAccounts
                key={(tree.account && tree.account.id) || tree.fullName}
                tree={tree}
                onSelectAccounts={this.props.onSelectAccounts}
                level={this.props.level + 1}
                selectedAccounts={this.props.selectedAccounts}
                attachment={this.props.attachment}
                multiSelect={this.props.multiSelect}
              />
            ))}
          </ul>
        )}
      </li>
    );
  }
}

export class AccountsList extends PureComponent<
  {
    accountForest: ReturnType<typeof accountForestSelector>;
    multiSelect?: boolean;
    onSelectAccounts(ids: UUID[]): void;
    selectedAccounts: UUID[];
    attachment(id: UUID): string | undefined;
  },
  {}
> {
  render() {
    const forest = this.props.accountForest;

    return (
      <ul className={css.rootAccountList}>
        {forest.map(tree => (
          <CollapsibleAccounts
            key={tree.account && tree.account.id}
            tree={tree}
            onSelectAccounts={this.props.onSelectAccounts}
            level={0}
            selectedAccounts={this.props.selectedAccounts}
            attachment={this.props.attachment}
            multiSelect={!!this.props.multiSelect}
          />
        ))}
      </ul>
    );
  }
}

const accountsShowingBalance = memoize(
  (accountsByBasicType: Map<BASIC_ACCOUNT_VALUES, Set<Account>>) => {
    const accountsShowingBalance = new Set<UUID>();
    accountsByBasicType
      .get(BASIC_ACCOUNT_VALUES.ASSETS)
      ?.forEach(({ id }) => accountsShowingBalance.add(id));
    accountsByBasicType
      .get(BASIC_ACCOUNT_VALUES.LIABILITIES)
      ?.forEach(({ id }) => accountsShowingBalance.add(id));

    return accountsShowingBalance;
  }
);

export class DisplayAccountsList extends PureComponent<{
  accountForest: ReturnType<typeof accountForestSelector>;
  onSelectAccounts(ids: UUID[]): void;
  selectedAccounts: UUID[];
  accountsByBasicType: Map<BASIC_ACCOUNT_VALUES, Set<Account>>;
  balance(id: UUID): ScaledValue;
}> {
  attachment = (accountId: UUID) => {
    if (accountsShowingBalance(this.props.accountsByBasicType).has(accountId)) {
      return format(this.props.balance(accountId));
    }
    return undefined;
  };

  render() {
    const forest = this.props.accountForest;
    return (
      <AccountsList
        accountForest={forest}
        multiSelect={true}
        attachment={this.attachment}
        onSelectAccounts={this.props.onSelectAccounts}
        selectedAccounts={this.props.selectedAccounts}
      />
    );
  }
}

export const ConnectedAccountsList = connect(
  (state: AppState) => ({
    accountForest: accountForestSelector(state),
    selectedAccounts: selectedAccountIdSelector(state),
    accountsByBasicType: accountsByBasicTypeMapSelector(state),
    balance: totalBalanceSelector(state),
    multiSelect: true
  }),
  {
    onSelectAccounts: setSelectedAccount
  }
)(DisplayAccountsList);
