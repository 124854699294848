import { reducerInNs } from "../utils";
import { OOBE_NS, OobeState } from "./types";
import { Action } from "./action_creators";
import { AppState } from "../store";
import { OOBE_DEFAULT_STATE } from "./default_state";

export const oobeReducer = reducerInNs(
  OOBE_NS,
  (
    state: OobeState = OOBE_DEFAULT_STATE,
    action: Action,
    appState: AppState
  ) => {
    switch (action.type) {
      case "OOBE/RUN_OOBE":
        return { ...state, step: 1 };
      case "OOBE/FINISH_OOBE":
        return { ...state, step: undefined };
    }
    return state;
  }
);
