import React from "react";
import {
  XYPlot,
  VerticalBarSeries,
  LineMarkSeries,
  LineSeries,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  Highlight,
} from "react-vis";
import { DataSet } from "./types";
import { setFilterDateRange } from "data/ledgers/actions";
import { hsvToHex } from "utils/color";
import { DateTime } from "luxon";
import { regression } from "./regression";
import { FlexibleContainer } from "./flexible_container";

export const XYGraph = React.memo<{
  type: "line" | "bar";
  dataSet: DataSet;
  origin: "zero" | "auto";

  regressionOptions: {
    regression?: "linear" | "power" | "exponential" | "logarithmic";
    extrapolateDays: number;
  };
  setFilterDateRange: typeof setFilterDateRange;
}>((props) => {
  const {
    type,
    dataSet,
    origin,
    setFilterDateRange,
    regressionOptions,
  } = props;

  const regDataSet: DataSet = regressionOptions.regression
    ? dataSet.map(({ series }) => ({
        series: regression(series, regressionOptions),
      }))
    : [];

  let minValue = +Infinity;
  let maxValue = -Infinity;

  const dataSetColor = dataSet.map((_, i) =>
    hsvToHex(i / dataSet.length, 0.46, 0.53)
  );
  const regDataSetColor = regDataSet.map((_, i) =>
    hsvToHex(i / dataSet.length, 0.26, 0.78)
  );

  for (const ds of [dataSet, regDataSet]) {
    for (const series of ds) {
      if (series) {
        for (const point of series.series) {
          if (point.y < minValue) {
            minValue = point.y;
          }
          if (point.y > maxValue) {
            maxValue = point.y;
          }
        }
      }
    }
  }

  let domain: [number, number] | undefined;
  if (origin === "zero") {
    if (minValue * maxValue <= 0) {
      domain = [minValue, maxValue];
    } else {
      if (maxValue > 0) {
        domain = [0, maxValue];
      } else {
        domain = [minValue, 0];
      }
    }
  }

  return (
    <FlexibleContainer height={300}>
      {(width, height) => (
        <XYPlot width={width} height={height} yDomain={domain}>
          <XAxis
            tickFormat={(val: number) => {
              return DateTime.fromMillis(val).toLocaleString(
                DateTime.DATE_SHORT
              );
            }}
            tickTotal={10}
          />
          <YAxis
            tickFormat={(val: number) => {
              let suffix = "";
              if (Math.abs(val) >= 1000) {
                val /= 1000;
                suffix = "K";
              }
              if (Math.abs(val) >= 1000) {
                val /= 1000;
                suffix = "M";
              }
              if (Math.abs(val) >= 1000) {
                val /= 1000;
                suffix = "B";
              }
              if (Math.abs(val) >= 1000) {
                val /= 1000;
                suffix = "T";
              }

              return +val.toPrecision(3) + suffix;
            }}
            width={50}
          />
          <VerticalGridLines />
          <HorizontalGridLines />
          {type === "bar" &&
            dataSet.map((data, index) => (
              <VerticalBarSeries
                key={`bar-${index}`}
                data={data.series.map((dataPoint) => ({ ...dataPoint, y0: 0 }))}
                color={dataSetColor[index]}
              />
            ))}
          {type === "line" &&
            dataSet.map((data, index) => (
              <LineMarkSeries
                key={`line-${index}`}
                data={data.series}
                color={dataSetColor[index]}
              />
            ))}
          {type === "line" &&
            regDataSet.map((regression, index) => {
              if (!regression) {
                return null;
              }

              return (
                <LineSeries
                  key={`reg-${index}`}
                  data={regression.series}
                  color={regDataSetColor[index]}
                  style={{
                    "stroke-dasharray": 5,
                  }}
                />
              );
            })}
          <Highlight
            enableX={true}
            enableY={false}
            onBrushEnd={(area: { left: number; right: number }) =>
              area &&
              setFilterDateRange(
                DateTime.fromMillis(area.left),
                DateTime.fromMillis(area.right)
              )
            }
          />
        </XYPlot>
      )}
    </FlexibleContainer>
  );
});
