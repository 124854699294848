import React, { PureComponent } from "react";

type AttrType<T> = T extends React.DetailedHTMLFactory<infer U, any> ? U : {};

export class BindedInput<
  T extends keyof React.ReactHTML,
  S,
  R extends keyof S
> extends PureComponent<{
  tagName: T;
  state: S;
  bindTo: R;
  onChange(s: S): void;
  props?: AttrType<React.ReactHTML[T]>;
  valueMap?: (s: string) => S[R];
  valueUnmap?: (v: S[R]) => string;
}> {
  render() {
    const {
      tagName,
      state,
      bindTo,
      onChange,
      children,
      valueMap,
      valueUnmap,
      props,
    } = this.props;

    return React.createElement(
      tagName,
      {
        value: valueUnmap ? valueUnmap(state[bindTo]) : "" + state[bindTo],
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
          onChange({
            ...state,
            [bindTo]: valueMap
              ? valueMap(e.currentTarget.value)
              : e.currentTarget.value,
          }),
        ...props,
      } as any,
      children
    );
  }
}
