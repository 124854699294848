import React from "react";
import { redGreenColorScale } from "../../../utils/color";

export const ProbabilityIndicator: React.FC<{ p: number }> = props => {
  return (
    <div
      style={{
        width: 10,
        height: 10,
        margin: 4,
        backgroundColor: redGreenColorScale(props.p, 0.16, 0.99)
      }}
    />
  );
};
