import React, { PureComponent } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import {
  selectedAccountsBack,
  selectedAccountsForward,
  setFilter,
  clearFilter
} from "data/ledgers/actions";
import { AppState } from "data/store";
import {
  canGoForwardSelector,
  canGoBackSelector,
  filtersSelector
} from "data/ledgers/selectors";
import { ReactComponent as FilterIcon } from "../../assets/font-awesome-solid/filter.svg";
import { ReactComponent as LeftIcon } from "../../assets/font-awesome-solid/caret-square-left.svg";
import { ReactComponent as RightIcon } from "../../assets/font-awesome-solid/caret-square-right.svg";

import css from "./top_bar.module.css";
import pageCss from "components/styles/page.module.css";
import buttonCss from "components/styles/button.module.css";
import { removeFilter } from "data/ledgers/action_creators";
import { FilterList } from "components/common/filtering/filter_list";

class NavigationWidget extends PureComponent<{
  onBack?: () => void;
  onForward?: () => void;
  className?: string;
}> {
  render() {
    return (
      <div className={this.props.className}>
        <button
          className={cx(buttonCss.styleless, css.navButton)}
          disabled={!this.props.onBack}
          onClick={this.props.onBack}
        >
          <LeftIcon width={20} height={20} />
        </button>
        <button
          className={cx(buttonCss.styleless, css.navButton)}
          disabled={!this.props.onForward}
          onClick={this.props.onForward}
        >
          <RightIcon width={20} height={20} />
        </button>
      </div>
    );
  }
}

export class TopBar extends PureComponent<{
  canGoBack: boolean;
  canGoForward: boolean;
  selectedAccountsBack: typeof selectedAccountsBack;
  selectedAccountsForward: typeof selectedAccountsForward;
  setFilter: typeof setFilter;
  removeFilter: typeof removeFilter;
  clearFilter: typeof clearFilter;
  filters: ReturnType<typeof filtersSelector>;
}> {
  toggle = () => {
    const { filters, setFilter, clearFilter } = this.props;
    if (filters && filters.length > 0) {
      clearFilter();
    } else {
      setFilter(0, {});
    }
  };

  render() {
    const { filters, setFilter, removeFilter } = this.props;
    return (
      <div className={cx(css.wrapper, pageCss.block)}>
        <div className={css.container}>
          <NavigationWidget
            className={css.left}
            onBack={
              this.props.canGoBack ? this.props.selectedAccountsBack : undefined
            }
            onForward={
              this.props.canGoForward
                ? this.props.selectedAccountsForward
                : undefined
            }
          />
          <div className={css.right}>
            <button
              className={cx(buttonCss.styleless, css.filterButton)}
              onClick={this.toggle}
              aria-expanded={filters && filters.length > 0}
            >
              <FilterIcon width={14} height={14} />
            </button>
          </div>
        </div>
        {filters && filters.length > 0 && (
          <FilterList
            filters={filters}
            setFilter={setFilter}
            removeFilter={removeFilter}
          />
        )}
      </div>
    );
  }
}

export const ConnectedTopBar = connect(
  (state: AppState) => ({
    canGoBack: canGoBackSelector(state),
    canGoForward: canGoForwardSelector(state),
    filters: filtersSelector(state)
  }),
  {
    selectedAccountsBack,
    selectedAccountsForward,
    setFilter,
    clearFilter,
    removeFilter
  }
)(TopBar);
