import cx from "classnames";
import React, { PureComponent } from "react";
import { Transaction, Split } from "model/bookkeeping";
import groupBy from "lodash/groupBy";
import { debitCreditFactory } from "components/common/value_scaled_input";
import { ConnectedAccountSelector } from "components/common/account";
import { UUID, newUuid } from "lib/core/uuid";
import { sum, negate, ScaledValue } from "model/scaled_value";
import { BMDateTime } from "lib/datetime/types";

import css from "./transactions_editor.module.css";
import { DateTime } from "luxon";
import { today } from "lib/datetime/date";
import { TransactionMutations } from "data/accounts/types";
import flatten from "lodash/flatten";
import { formValidTransactions } from "lib/accounts/validator";

import { ReactComponent as GripIcon } from "assets/font-awesome-solid/grip-vertical.svg";
import { ReactComponent as CalendarIcon } from "assets/font-awesome-solid/calendar-alt.svg";

import inputCss from "components/styles/input.module.css";
import { SimpleDropdown } from "components/common/simple_dropdown_menu";
import buttonCss from "components/styles/button.module.css";

interface DateEditorProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    "value" | "onChange"
  > {
  value: BMDateTime;
  onChange(date: BMDateTime): void;
}

export const DateEditor: React.FC<DateEditorProps> = (props) => {
  const { value, onChange, ...rest } = props;
  return (
    <input
      {...rest}
      type={value.hasTime ? "datetime-local" : "date"}
      value={
        value.hasTime
          ? value.datetime.toISO({
              suppressMilliseconds: true,
              suppressSeconds: true,
              includeOffset: false,
            })
          : value.datetime.toISODate()
      }
      onChange={(e) =>
        onChange({
          datetime: DateTime.fromISO(e.currentTarget.value).setZone(
            props.value.datetime.zone,
            { keepLocalTime: true }
          ),
          hasTime: props.value.hasTime,
        })
      }
    />
  );
};

const SplitRow = React.memo<{
  tx: Transaction;
  split: Split;
  updateSplit(splitId: UUID, updated: Partial<Split>): void;
  accountIsDebit?(accountId: UUID): boolean;
}>((props) => {
  const { tx, split, updateSplit } = props;
  const [drInput, crInput] = debitCreditFactory({
    valueScaled: split.valueScaled,
    onChange: (valueScaled) => updateSplit(split.id, { valueScaled }),
    additionalClassName: inputCss.fullWidth,
    accountType:
      props.accountIsDebit &&
      (props.accountIsDebit(split.accountId) ? "debit" : "credit"),
  });
  return (
    <li
      draggable={true}
      onDragStart={(e) => {
        e.dataTransfer.setData("text/x-ducount-split-id", split.id);
        e.dataTransfer.dropEffect = "move";
      }}
    >
      <div className={css.splitRow}>
        <div className={css.splitRowGripCell}>
          <GripIcon height={12} />
        </div>
        <div className={css.splitRowAccountCell}>
          <ConnectedAccountSelector
            inputProps={{ className: cx(inputCss.input, inputCss.fullWidth) }}
            displayProps={{ className: cx(inputCss.input, inputCss.fullWidth) }}
            value={split.accountId}
            onChange={(accountId) => updateSplit(split.id, { accountId })}
            hidePlaceholders={true}
          />
        </div>
        <div className={css.splitRowAmountCell}>{drInput}</div>
        <div className={css.splitRowAmountCell}>{crInput}</div>
        <div className={css.splitRowButtonCell}>
          <SimpleDropdown
            buttonClass={buttonCss.styleless}
            contents={(ref) => {
              return (
                <div ref={ref} className={css.dateEditor}>
                  <DateEditor
                    className={inputCss.input}
                    value={split.datetime}
                    onChange={(datetime) =>
                      updateSplit(split.id, {
                        datetime,
                      })
                    }
                  />
                  <button
                    onClick={() =>
                      updateSplit(split.id, {
                        datetime: tx.datetime,
                      })
                    }
                  >
                    Use Transaction Date
                  </button>
                </div>
              );
            }}
          >
            <CalendarIcon width={12} height={12} />
          </SimpleDropdown>
        </div>
      </div>
    </li>
  );
});

type Prop = {
  transactions: Transaction[];
  splits: Split[];
  onSave(mods: TransactionMutations): void;
  accountIsDebit?(accountId: UUID): boolean;
};

type State = TransactionMutations & {
  hoverTransactionId?: UUID | "_NEW";
};

export class TransactionsEditor extends PureComponent<Prop, State> {
  state: State = {
    dirtyTransactions: {},
    dirtySplits: {},
    newTransactions: [],
    newSplits: [],
    deletedTransactionIds: [],
  };

  componentDidUpdate(prevProps: Prop) {
    if (
      this.props.splits !== prevProps.splits ||
      this.props.transactions !== prevProps.transactions
    ) {
      this.setState({
        dirtyTransactions: {},
        dirtySplits: {},
        newSplits: [],
        newTransactions: [],
        deletedTransactionIds: [],
      });
    }
  }

  updateSplit = (splitId: UUID, updated: Partial<Split>) => {
    this.setState({
      dirtySplits: {
        ...this.state.dirtySplits,
        [splitId]: {
          ...this.state.dirtySplits[splitId],
          ...updated,
        },
      },
    });
  };

  updateTransaction = (txId: UUID, updated: Partial<Transaction>) => {
    this.setState({
      dirtyTransactions: {
        ...this.state.dirtyTransactions,
        [txId]: {
          ...this.state.dirtyTransactions[txId],
          ...updated,
        },
      },
    });
  };

  newSplit = (
    id: UUID,
    accountId: UUID,
    transactionId: UUID,
    valueScaled: ScaledValue,
    datetime: BMDateTime
  ) => {
    this.setState({
      newSplits: [
        ...this.state.newSplits,
        {
          id,
          accountId,
          transactionId,
          valueScaled,
          datetime,
          tags: {},
        },
      ],
    });
  };

  addTransaction = () => {
    const id = newUuid();
    this.setState({
      newTransactions: [
        ...this.state.newTransactions,
        {
          id,
          memo: "New Transaction",
          datetime: today(),
          tags: {},
        },
      ],
    });
    return id;
  };

  render() {
    const allTransactions = [
      ...this.props.transactions,
      ...this.state.newTransactions,
    ].map((tx) =>
      this.state.dirtyTransactions[tx.id]
        ? { ...tx, ...this.state.dirtyTransactions[tx.id] }
        : tx
    );

    const splits = [
      ...this.props.splits,
      ...this.state.newSplits,
    ].map((split) =>
      this.state.dirtySplits[split.id]
        ? { ...split, ...this.state.dirtySplits[split.id] }
        : split
    );

    const splitsByTransaction = groupBy(splits, (split) => split.transactionId);

    const disabled = !formValidTransactions(
      flatten(Object.values(splitsByTransaction))
    );

    return (
      <div>
        {allTransactions.map((tx) => {
          const splits = splitsByTransaction[tx.id];
          if (!splits) {
            return null;
          }
          const subtotal = sum(...splits.map((s) => s.valueScaled));

          const newSplitId = newUuid();

          const [remDrInput, remCrInput] = debitCreditFactory({
            valueScaled: negate(subtotal),
            readOnly: true,
          });

          return (
            <div
              className={cx(css.transactionBox, {
                [css.hoverTransaction]: this.state.hoverTransactionId === tx.id,
              })}
              onDragOver={(e) => {
                e.preventDefault();
                this.setState({ hoverTransactionId: tx.id });
              }}
              onDragLeave={() =>
                this.setState({ hoverTransactionId: undefined })
              }
              onDrop={(e) => {
                e.preventDefault();
                this.setState({ hoverTransactionId: undefined });
                const splitId = e.dataTransfer.getData(
                  "text/x-ducount-split-id"
                );
                if (splitId) {
                  this.updateSplit(splitId, {
                    transactionId: tx.id,
                  });
                }
              }}
            >
              <div className={css.transactionTop}>
                <input
                  className={inputCss.input}
                  type="text"
                  value={tx.memo}
                  onChange={(e) =>
                    this.updateTransaction(tx.id, {
                      memo: e.currentTarget.value,
                    })
                  }
                />
                <DateEditor
                  className={inputCss.input}
                  value={tx.datetime}
                  onChange={(datetime) =>
                    this.updateTransaction(tx.id, {
                      datetime,
                    })
                  }
                />
              </div>

              <ul className={css.splitList}>
                {splits
                  .map((split) => {
                    return (
                      <SplitRow
                        key={split.id}
                        tx={tx}
                        split={split}
                        updateSplit={this.updateSplit}
                        accountIsDebit={this.props.accountIsDebit}
                      />
                    );
                  })
                  .concat(
                    subtotal === 0
                      ? []
                      : [
                          <li key={newSplitId}>
                            <ConnectedAccountSelector
                              onChange={(accountId) =>
                                this.newSplit(
                                  newSplitId,
                                  accountId,
                                  tx.id,
                                  negate(subtotal),
                                  tx.datetime
                                )
                              }
                              hidePlaceholders={true}
                            />
                            {remDrInput}
                            {remCrInput}
                          </li>,
                        ]
                  )}
              </ul>
            </div>
          );
        })}
        <div
          className={cx(css.transactionBox, css.addTransactionBox, {
            [css.hoverTransaction]: this.state.hoverTransactionId === "_NEW",
          })}
          onDragOver={(e) => {
            e.preventDefault();
            this.setState({ hoverTransactionId: "_NEW" });
          }}
          onDragLeave={() => this.setState({ hoverTransactionId: undefined })}
          onDrop={(e) => {
            e.preventDefault();
            this.setState({ hoverTransactionId: undefined });
            const splitId = e.dataTransfer.getData("text/x-ducount-split-id");
            if (splitId) {
              const newTxId = this.addTransaction();
              this.updateSplit(splitId, {
                transactionId: newTxId,
              });
            }
          }}
        >
          + Drag leg here to create a new transaction
        </div>

        <button
          className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
          disabled={disabled}
          onClick={() =>
            this.props.onSave({
              newSplits: this.state.newSplits,
              newTransactions: this.state.newTransactions,
              dirtySplits: this.state.dirtySplits,
              dirtyTransactions: this.state.dirtyTransactions,
              deletedTransactionIds: this.state.deletedTransactionIds,
            })
          }
        >
          Save
        </button>
      </div>
    );
  }
}
