import { reducerInNs } from "../utils";
import { ACCOUNTS_NS, AccountsState } from "./types";
import { ACCOUNTS_DEFAULT_STATE } from "./default_state";
import { AccountsAction } from "./action_creators";
import { AppState } from "../store";
import produce from "immer";
import { UUID } from "lib/core/uuid";
import forEach from "lodash/forEach";
import { PATH_SEPARATOR } from "lib/accounts/hierarchy";

export const accountsReducer = reducerInNs(
  ACCOUNTS_NS,
  (
    state: AccountsState = ACCOUNTS_DEFAULT_STATE,
    action: AccountsAction,
    appState: AppState
  ) => {
    switch (action.type) {
      case "ACCOUNTS/READY":
        return { ...state, ready: true };
      case "ACCOUNTS/SET_ACCOUNT_TAG":
        return produce(state, draftState => {
          const { tag, value } = action.payload;
          draftState.accountData[action.payload.accountId].account.tags[
            tag
          ] = value;
        });
      case "ACCOUNTS/ADD_ACCOUNTS":
        return produce(state, draftState => {
          for (const account of action.payload) {
            draftState.accountData[account.id] = {
              account
            };
          }
        });
      case "ACCOUNTS/UPDATE_ACCOUNT": {
        return produce(state, draftState => {
          const oldPrefix =
            draftState.accountData[action.payload.id].account.name +
            PATH_SEPARATOR;
          const newPrefix = action.payload.name + PATH_SEPARATOR;

          draftState.accountData[action.payload.id].account.name =
            action.payload.name;

          forEach(draftState.accountData, accountData => {
            if (accountData.account.name.startsWith(oldPrefix)) {
              accountData.account.name =
                newPrefix + accountData.account.name.substr(oldPrefix.length);
            }
          });
        });
      }
      case "ACCOUNTS/DELETE_ACCOUNTS": {
        return produce(state, draftState => {
          const mapping = new Map<UUID, UUID>();
          for (const account of action.payload.accounts) {
            delete draftState.accountData[account.accountId];
            mapping.set(account.accountId, account.moveTo);
          }
          forEach(draftState.splits, sp => {
            const moveTo = mapping.get(sp.accountId);
            if (moveTo) {
              sp.accountId = moveTo;
            }
          });
        });
      }
      case "ACCOUNTS/ADD_TRANSACTIONS_SPLITS": {
        const { transactions, splits } = action.payload;

        return produce(state, draftState => {
          for (const tx of transactions) {
            draftState.transactions[tx.id] = tx;
          }

          for (const split of splits) {
            draftState.splits[split.id] = split;
          }
        });
      }
      case "ACCOUNTS/UPDATE_TRANSACTIONS_AND_SPLITS": {
        const { mutations } = action.payload;

        return produce(state, draftState => {
          for (const tx of mutations.newTransactions) {
            draftState.transactions[tx.id] = tx;
          }
          for (const id of Object.keys(mutations.dirtyTransactions)) {
            draftState.transactions[id] = {
              ...draftState.transactions[id],
              ...mutations.dirtyTransactions[id]
            };
          }

          for (const split of mutations.newSplits) {
            draftState.splits[split.id] = split;
          }
          for (const id of Object.keys(mutations.dirtySplits)) {
            draftState.splits[id] = {
              ...draftState.splits[id],
              ...mutations.dirtySplits[id]
            };
          }

          if (mutations.deletedTransactionIds.length > 0) {
            for (const key of Object.keys(draftState.splits)) {
              if (
                mutations.deletedTransactionIds.includes(
                  draftState.splits[key].transactionId
                )
              ) {
                delete draftState.splits[key];
              }
            }

            for (const key of mutations.deletedTransactionIds) {
              delete draftState.transactions[key];
            }
          }
        });
      }

      default:
        return state;
    }
  }
);
