import cx from "classnames";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AppState } from "data/store";
import { externalAccountByIdMapSelector } from "data/importer/selectors";
import { UUID } from "lib/core/uuid";
import { Account, ExternalAccount } from "model/bookkeeping";
import { TokenizedMultiSelect } from "./tokenized_multiselect";
import Autosuggest from "react-autosuggest";
import autosuggestCss from "./account_autosuggest.module.css";
import mergeWith from "lodash/mergeWith";
import multiaccountCss from "./multi_account_selector.module.css";

type Suggestion = [UUID, string];

const ExternalAccountSelector = React.memo<{
  data: Map<UUID, ExternalAccount>;
  onSelect(id: UUID): void;
}>((props) => {
  const { data, onSelect } = props;
  const [inputValue, setInputValue] = React.useState("");
  const [suggestions, setSuggestions] = React.useState<Suggestion[]>([]);
  const onSuggestionsClearRequested = React.useCallback(
    () => setSuggestions([]),
    [setSuggestions]
  );
  const getSuggestionValue = React.useCallback((suggest: Suggestion) => "", []);
  const onSuggestionSelected = React.useCallback(
    (e, { suggestion }) => onSelect(suggestion[0]),
    [onSelect]
  );
  const onSuggestionsFetchRequested = React.useCallback(
    ({ value }) => {
      const suggestions: Suggestion[] = [];
      data.forEach((val, key) => {
        if (val.name.toLocaleLowerCase().includes(value)) {
          suggestions.push([key, val.name]);
        }
      });
      setSuggestions(suggestions);
    },
    [setSuggestions, data]
  );
  const renderSuggestion = React.useCallback(
    (suggestion: Suggestion) => <div>{suggestion[1]}</div>,
    []
  );

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      getSuggestionValue={getSuggestionValue}
      inputProps={{
        value: inputValue,
        onChange: (e, { newValue }) => setInputValue(newValue),
      }}
      renderSuggestion={renderSuggestion}
      multiSection={false}
      theme={mergeWith(
        autosuggestCss,
        {
          input: multiaccountCss.accountSelectInput,
          container: multiaccountCss.accountSelectContainer,
        },
        (objValue, srcValue) => cx(objValue, srcValue)
      )}
    />
  );
});

type Props = {
  selectedExternalAccounts: UUID[];
  onChange(accounts: UUID[]): void;
  accounts: Map<UUID, ExternalAccount>;
};

export class MultiExternalAccountSelector extends PureComponent<Props, {}> {
  filter = (account: Account) =>
    !this.props.selectedExternalAccounts.includes(account.id);

  render() {
    return (
      <TokenizedMultiSelect
        selectedIds={this.props.selectedExternalAccounts}
        data={this.props.accounts}
        id={(data) => data.id}
        display={(account) => <div>{account.name}</div>}
        selector={(onSelected) => (
          <ExternalAccountSelector
            data={this.props.accounts}
            onSelect={onSelected}
          />
        )}
        onChange={this.props.onChange}
      />
    );
  }
}

export const ConnectedMultiExternalAccountSelector = connect(
  (state: AppState) => ({
    accounts: externalAccountByIdMapSelector(state),
  })
)(MultiExternalAccountSelector);
