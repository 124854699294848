import { DateTime } from "luxon";

type PredefinedRanges = "year" | "quarter" | "month";

export interface DateRangeData {
  range: "custom" | PredefinedRanges;
  startTime: DateTime;
  endTime: DateTime;
}

export function defaultDateRange() {
  return rangeOfToday("month");
}

export function prevRange(data: DateRangeData): DateRangeData {
  if (data.range !== "custom") {
    const endTime = data.startTime.minus(1);
    const startTime = endTime.startOf(data.range);
    return { ...data, startTime, endTime };
  }
  return data;
}

export function nextRange(data: DateRangeData): DateRangeData {
  if (data.range !== "custom") {
    const startTime = data.endTime.plus(1);
    const endTime = startTime.endOf(data.range);
    return { ...data, startTime, endTime };
  }
  return data;
}

export function rangeOfToday(range: PredefinedRanges): DateRangeData {
  const datetime = DateTime.local();
  return {
    range,
    startTime: datetime.startOf(range),
    endTime: datetime.endOf(range)
  };
}

export function rangeEqual(data1: DateRangeData, data2: DateRangeData) {
  return (
    data1.range === data2.range &&
    data1.startTime.equals(data2.startTime) &&
    data1.endTime.equals(data2.endTime)
  );
}

export function humanizeRange(data: DateRangeData) {
  const thisYear = DateTime.local().year;
  if (data.range === "year") {
    if (data.startTime.year === thisYear) {
      return "This Year";
    } else if (data.startTime.year === thisYear - 1) {
      return "Last Year";
    } else {
      return data.startTime.year;
    }
  } else if (data.range === "quarter") {
    const todayRange = rangeOfToday("quarter");
    if (rangeEqual(data, todayRange)) {
      return "This quarter";
    }
    const lastRange = prevRange(todayRange);

    if (rangeEqual(data, lastRange)) {
      return "Last quarter";
    }
    return `Q${data.startTime.quarter} ${data.startTime.year}`;
  } else if (data.range === "month") {
    const todayRange = rangeOfToday("month");
    if (rangeEqual(data, todayRange)) {
      return "This month";
    }
    const lastRange = prevRange(todayRange);

    if (rangeEqual(data, lastRange)) {
      return "Last month";
    }
    return data.startTime.toFormat("MMM yyyy");
  } else {
    return (
      data.startTime.toLocaleString(DateTime.DATE_SHORT) +
      " - " +
      data.endTime.toLocaleString(DateTime.DATE_SHORT)
    );
  }
}
