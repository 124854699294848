export function mergeSort<T>(a1: T[], a2: T[], key: (item: T) => number): T[] {
  return nWayMergeSort([a1, a2], key);
}

export function nWayMergeSort<T>(data: T[][], key: (item: T) => number): T[] {
  let totalNum = 0;
  const indices: number[] = [];

  for (let i = 0; i < data.length; i++) {
    indices.push(0);
    totalNum += data[i].length;
  }

  const result: T[] = [];

  for (let _ = 0; _ < totalNum; _++) {
    let min = Number.MAX_SAFE_INTEGER,
      minIndex = -1;
    for (let i = 0; i < indices.length; i++) {
      if (data[i][indices[i]]) {
        const val = key(data[i][indices[i]]);
        if (val < min) {
          min = val;
          minIndex = i;
        }
      }
    }
    result.push(data[minIndex][indices[minIndex]++]);
  }

  return result;
}
