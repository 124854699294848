import { Persist } from "./base";
import { openDB, DBSchema, IDBPTransaction } from "idb";
import md5 from "crypto-js/md5";
import {
  Split,
  Transaction,
  Account,
  ImportedEntry,
  ExternalAccount
} from "../model/bookkeeping";
import { UUID } from "../lib/core/uuid";
import {
  SerializeDateTime,
  serializeDateTime,
  unserializeDateTime
} from "./serializer";
import { BackupDataSchema } from "./backup";
import { TransactionMutations } from "data/accounts/types";
import { formValidTransactions } from "lib/accounts/validator";
import { PATH_SEPARATOR } from "lib/accounts/hierarchy";

interface Schema extends DBSchema {
  splits: {
    key: string;
    value: SerializeDateTime<Split>;
    indexes: { by_transaction_id: string; by_account_id: string };
  };
  transactions: {
    key: string;
    value: SerializeDateTime<Transaction>;
  };
  accounts: {
    key: string;
    value: Account;
  };
  imported_entries: {
    key: string;
    value: SerializeDateTime<ImportedEntry>;
  };
  external_accounts: {
    key: string;
    value: ExternalAccount;
  };
}

function importedEntry2to3(
  r: SerializeDateTime<ImportedEntry>
): SerializeDateTime<ImportedEntry> {
  const row = { ...r };
  if (
    row["externalId"].startsWith("OFX:") &&
    !row["externalId"].includes("OFXHASH:")
  ) {
    const importedEntry: ImportedEntry = unserializeDateTime(row);
    row["externalId"] +=
      ";OFXHASH:" +
      md5(
        importedEntry.memo.toLowerCase().replace(/\s/g, "") +
          "|" +
          importedEntry.datetime.datetime.toFormat("D") +
          "|" +
          importedEntry.valueScaled
      );
  }
  return row;
}

function updateTagsFactory(
  table: "imported_entries" | "accounts" | "external_accounts"
) {
  return async function(
    this: PersistLocal,
    id: UUID,
    tags: { [tag: string]: string }
  ) {
    const db = await this.getDB();
    const tx = db.transaction(table, "readwrite");
    const row = await tx.objectStore(table).get(id);

    if (!row) {
      throw new Error("Invalid account ID");
    }

    row.tags = { ...row.tags, ...tags };

    await tx.objectStore(table).put(row);

    await tx.done;

    this.notifyChanges();
  };
}

export class PersistLocal extends Persist {
  private databaseName: string;

  constructor(databaseName = "bm") {
    super();
    this.databaseName = databaseName;
  }

  async getDB() {
    let isBrandNew = false;
    const db = await openDB<Schema>(this.databaseName, 5, {
      upgrade: (db, oldVersion, newVersion, tx) => {
        switch (oldVersion) {
          case 0:
            db.createObjectStore("splits", { keyPath: "id" });
            db.createObjectStore("transactions", { keyPath: "id" });
            db.createObjectStore("accounts", { keyPath: "id" });
            db.createObjectStore("external_accounts", { keyPath: "id" });
            db.createObjectStore("imported_entries", { keyPath: "id" });
          // falls through
          case 1:
            tx.objectStore("splits").createIndex(
              "by_transaction_id",
              "transactionId",
              { unique: false }
            );
            tx.objectStore("splits").createIndex("by_account_id", "accountId", {
              unique: false
            });
          // falls through
          case 2: {
            const store = tx.objectStore("imported_entries");
            store.getAll().then(data => {
              for (const row of data) {
                if (
                  row["externalId"].startsWith("OFX:") &&
                  !row["externalId"].includes("OFXHASH:")
                ) {
                  store.put(importedEntry2to3(row));
                }
              }
            });
          }
          // falls through
          case 3: {
            const store = tx.objectStore("external_accounts");
            store.getAll().then(data => {
              for (const row of data) {
                if (!row.hasOwnProperty("tags")) {
                  row.tags = {};
                  store.put(row);
                }
              }
            });
          }
          // falls through
          case 4: {
            const store = tx.objectStore("imported_entries");
            store.getAll().then(data => {
              for (const row of data) {
                if (!row.hasOwnProperty("tags")) {
                  row.tags = {};
                  store.put(row);
                }
              }
            });
          }
        }

        if (oldVersion === 0) {
          isBrandNew = true;
        }
      }
    });

    if (isBrandNew) {
      setTimeout(() => this.runNewAccountHandler(), 0);
    }
    return db;
  }

  listenForChange() {
    window.addEventListener("storage", e => {
      if (e.key === "db_notify_channel") {
        this.onChange();
      }
    });
  }

  notifyChanges() {
    localStorage.setItem("db_notify_channel", Math.random() + "");
  }

  async addTransactions(transactions: Transaction[], splits: Split[]) {
    const db = await this.getDB();
    const tx = db.transaction(["splits", "transactions"], "readwrite");

    for (const trans of transactions) {
      tx.objectStore("transactions").put(serializeDateTime(trans));
    }

    for (const split of splits) {
      tx.objectStore("splits").put(serializeDateTime(split));
    }

    await tx.done;

    this.notifyChanges();
  }

  async addAccounts(accounts: Account[]) {
    const db = await this.getDB();
    const tx = db.transaction("accounts", "readwrite");
    for (const account of accounts) {
      tx.store.put(account);
    }
    await tx.done;
    this.notifyChanges();
  }

  async updateAccount(id: UUID, name: string) {
    const db = await this.getDB();
    const tx = db.transaction("accounts", "readwrite");
    const rows = await tx.store.getAll();
    for (const row of rows) {
      if (row.name === name) {
        throw new Error("Duplicate name");
      }
    }

    const accountRow = await tx.store.get(id);
    if (!accountRow) {
      throw new Error("Invalid account");
    }

    const prefix = accountRow.name + PATH_SEPARATOR;

    for (const row of rows) {
      if (row.id === id) {
        row.name = name;
        tx.store.put(row);
      } else if (row.name.startsWith(prefix)) {
        row.name = name + PATH_SEPARATOR + row.name.substr(prefix.length);
        tx.store.put(row);
      }
    }
    await tx.done;
    this.notifyChanges();
  }

  private async _moveSplitsInAccount(
    tx: IDBPTransaction<Schema, ("accounts" | "splits")[]>,
    accounts: {
      accountId: UUID;
      moveTo: UUID;
    }[]
  ) {
    const map = new Map<UUID, UUID>();
    for (const account of accounts) {
      map.set(account.accountId, account.moveTo);
    }
    for (const split of await tx.objectStore("splits").getAll()) {
      const moveTo = map.get(split.accountId);
      if (moveTo) {
        split.accountId = moveTo;
        tx.objectStore("splits").put(split);
      }
    }
  }

  async deleteAccounts(
    accounts: {
      accountId: UUID;
      moveTo: UUID;
    }[]
  ) {
    const db = await this.getDB();
    const tx = db.transaction(["accounts", "splits"], "readwrite");

    for (const account of accounts) {
      tx.objectStore("accounts").delete(account.accountId);
    }

    await this._moveSplitsInAccount(tx, accounts);

    await tx.done;
    this.notifyChanges();
  }

  async moveAllSplitsInAccount(
    accounts: {
      accountId: UUID;
      moveTo: UUID;
    }[]
  ) {
    const db = await this.getDB();
    const tx = db.transaction(["accounts", "splits"], "readwrite");

    await this._moveSplitsInAccount(tx, accounts);

    await tx.done;
    this.notifyChanges();
  }

  async getAccountData() {
    const db = await this.getDB();
    const tx = db.transaction(
      ["splits", "transactions", "accounts"],
      "readonly"
    );

    const [accounts, transactions, splits] = await Promise.all([
      tx.objectStore("accounts").getAll(),
      tx.objectStore("transactions").getAll(),
      tx.objectStore("splits").getAll()
    ]);

    await tx.done;

    return {
      accounts,
      transactions: transactions.map(unserializeDateTime),
      splits: splits.map(unserializeDateTime)
    };
  }

  async getImporterData() {
    const db = await this.getDB();
    const tx = db.transaction(
      ["imported_entries", "external_accounts"],
      "readonly"
    );

    const [importedEntries, externalAccounts] = await Promise.all([
      tx.objectStore("imported_entries").getAll(),
      tx.objectStore("external_accounts").getAll()
    ]);

    await tx.done;

    return {
      importedEntries: importedEntries.map(unserializeDateTime),
      externalAccounts
    };
  }

  async updateImportedEntrySplitIds(mapping: Map<UUID, UUID>) {
    const db = await this.getDB();
    const tx = db.transaction("imported_entries", "readwrite");

    const updateAll: Promise<void>[] = [];

    mapping.forEach((splitId, entryId) => {
      updateAll.push(
        (async function() {
          const entry = await tx.store.get(entryId);

          if (entry) {
            entry.splitId = splitId;
            await tx.store.put(entry);
          } else {
            console.error(`Invalid entry ID ${entryId}`);
          }
        })()
      );
    });

    await Promise.all(updateAll);

    await tx.done;
    this.notifyChanges();
  }

  async addExternalAccountsAndEntries(
    externalAccount: ExternalAccount | undefined,
    entries: ImportedEntry[]
  ) {
    const db = await this.getDB();
    const tx = db.transaction(
      ["imported_entries", "external_accounts"],
      "readwrite"
    );

    const addedIds = [];

    if (externalAccount) {
      await tx.objectStore("external_accounts").put(externalAccount);
    }

    for (const entry of entries) {
      try {
        await tx.objectStore("imported_entries").add(serializeDateTime(entry));
        addedIds.push(entry.id);
      } catch (e) {}
    }

    await tx.done;
    this.notifyChanges();

    return addedIds;
  }

  async updateExternalAccount(
    externalAccount: Partial<ExternalAccount> & Pick<ExternalAccount, "id">
  ) {
    const db = await this.getDB();
    const tx = db.transaction(["external_accounts"], "readwrite");

    const existing = await tx
      .objectStore("external_accounts")
      .get(externalAccount.id);

    if (!existing) {
      throw new Error("Invalid external account id");
    }

    await tx
      .objectStore("external_accounts")
      .put({ ...existing, ...externalAccount });

    await tx.done;
    this.notifyChanges();
  }

  async updateTransactionsAndSplits(mutations: TransactionMutations) {
    const db = await this.getDB();
    const tx = db.transaction(
      ["transactions", "splits", "imported_entries"],
      "readwrite"
    );

    // Find all splits
    const affectedTransactionIds = new Set<UUID>();
    const modifiedSplits: { [k: string]: Split } = {};
    const existingSplits: { [k: string]: Split } = {};
    const mod = { ...mutations.dirtySplits };
    for (const split of mutations.newSplits) {
      const mut = mutations.dirtySplits[split.id];
      if (mut) {
        modifiedSplits[split.id] = {
          ...split,
          ...mut
        };
        delete mod[split.id];
        affectedTransactionIds.add(mut.transactionId || split.transactionId);
      } else {
        modifiedSplits[split.id] = split;
        affectedTransactionIds.add(split.transactionId);
      }
    }

    for (const id of Object.keys(mod)) {
      const data = await tx.objectStore("splits").get(id);
      if (!data) {
        throw new Error(`Split ${id} not found`);
      }

      affectedTransactionIds.add(data.transactionId);

      const split: Split = Object.assign(unserializeDateTime(data), mod[id]);

      affectedTransactionIds.add(split.transactionId);
      modifiedSplits[id] = split;
    }

    for (const id of affectedTransactionIds) {
      const splits: Split[] = (
        await tx
          .objectStore("splits")
          .index("by_transaction_id")
          .getAll(id)
      ).map(unserializeDateTime);

      for (const split of splits) {
        if (!modifiedSplits[split.id]) {
          existingSplits[split.id] = split;
        }
      }
    }

    const existingSplitsArray = Object.values(existingSplits);
    const modifiedSplitsArray = Object.values(modifiedSplits);
    // Verify
    if (
      !formValidTransactions([...existingSplitsArray, ...modifiedSplitsArray])
    ) {
      throw new Error("Invalid transaction. Splits do not sum to zero");
    }

    await Promise.all(
      modifiedSplitsArray.map(split =>
        tx.objectStore("splits").put(serializeDateTime(split))
      )
    );

    const remainingTransactions = { ...mutations.dirtyTransactions };

    for (const transaction of mutations.newTransactions) {
      await tx.objectStore("transactions").add(
        serializeDateTime({
          ...transaction,
          ...remainingTransactions[transaction.id]
        })
      );
      delete remainingTransactions[transaction.id];
    }

    for (const id of Object.keys(remainingTransactions)) {
      const mod = remainingTransactions[id];
      const existing = await tx.objectStore("transactions").get(id);
      if (!existing) {
        throw new Error(`Transaction ${id} does not exist`);
      }
      await tx
        .objectStore("transactions")
        .put(serializeDateTime({ ...unserializeDateTime(existing), ...mod }));
    }

    const deletedSplits = new Set<UUID>();
    for (const id of mutations.deletedTransactionIds) {
      const splitsKeys = await tx
        .objectStore("splits")
        .index("by_transaction_id")
        .getAllKeys(id);

      for (const split of splitsKeys) {
        deletedSplits.add(split);
        await tx.objectStore("splits").delete(split);
      }
      await tx.objectStore("transactions").delete(id);
    }

    if (deletedSplits.size > 0) {
      const importedEntries = await tx.objectStore("imported_entries").getAll();
      for (const entry of importedEntries) {
        if (entry.splitId && deletedSplits.has(entry.splitId)) {
          entry.splitId = undefined;
          await tx.objectStore("imported_entries").put(entry);
        }
      }
    }

    await tx.done;

    this.notifyChanges();
  }

  updateExternalAccountTags = updateTagsFactory("external_accounts");

  updateAccountTags = updateTagsFactory("accounts");

  updateImportedEntryTags = updateTagsFactory("imported_entries");

  async backup(): Promise<BackupDataSchema> {
    const db = await this.getDB();
    const tx = db.transaction(
      [
        "splits",
        "transactions",
        "imported_entries",
        "accounts",
        "external_accounts"
      ],
      "readonly"
    );
    return {
      metadata: {
        schema_version: 3
      },
      tables: {
        accounts: await tx.objectStore("accounts").getAll(),
        splits: await tx.objectStore("splits").getAll(),
        external_accounts: await tx.objectStore("external_accounts").getAll(),
        transactions: await tx.objectStore("transactions").getAll(),
        imported_entries: await tx.objectStore("imported_entries").getAll()
      }
    };
  }

  async restore(data: BackupDataSchema) {
    const db = await this.getDB();
    const tx = db.transaction(
      [
        "splits",
        "transactions",
        "imported_entries",
        "accounts",
        "external_accounts"
      ],
      "readwrite"
    );

    await Promise.all([
      tx.objectStore("splits").clear(),
      tx.objectStore("transactions").clear(),
      tx.objectStore("imported_entries").clear(),
      tx.objectStore("accounts").clear(),
      tx.objectStore("external_accounts").clear()
    ]);

    await Promise.all(
      data.tables.splits.map(sp => tx.objectStore("splits").add(sp))
    );

    await Promise.all(
      data.tables.transactions.map(sp => tx.objectStore("transactions").add(sp))
    );

    await Promise.all(
      data.tables.imported_entries.map(sp => {
        if (data.metadata.schema_version <= 2) {
          sp = importedEntry2to3(sp);
        }
        return tx.objectStore("imported_entries").add(sp);
      })
    );

    await Promise.all(
      data.tables.accounts.map(sp => tx.objectStore("accounts").add(sp))
    );

    await Promise.all(
      data.tables.external_accounts.map(sp =>
        tx.objectStore("external_accounts").add(sp)
      )
    );

    await tx.done;

    this.notifyChanges();
  }
}

if (
  process.env.REACT_APP_PERSIST === "cloud" &&
  process.env.NODE_ENV !== "test"
) {
  // @ts-ignore dead code elimination
  PersistLocal = function() {};
}
