import { SavedAccounts } from "./types";
import { UUID } from "lib/core/uuid";
import { Account } from "model/bookkeeping";
import { BASIC_ACCOUNT_VALUES } from "data/accounts/tags";

export function expandSavedAccounts(
  savedAccounts: SavedAccounts,
  accountsMap: Map<UUID, Account>,
  accountsByBasicTypeMap: Map<BASIC_ACCOUNT_VALUES, Set<Account>>,
  subaccountMap: (acc: Account) => Account[]
) {
  const result = new Set<UUID>();
  for (const savedAccount of savedAccounts) {
    const action =
      savedAccount.policy === "include"
        ? (id: UUID) => {
            result.add(id);
          }
        : (id: UUID) => {
            result.delete(id);
          };
    const baseAccounts: Account[] = [];
    let includeSubaccount;
    if (savedAccount.accountDescriptor._tag === "regular") {
      const account = accountsMap.get(savedAccount.accountDescriptor.accountId);
      if (account) {
        baseAccounts.push(account);
      }
      includeSubaccount = savedAccount.accountDescriptor.includeSubAccounts;
    } else {
      const set = accountsByBasicTypeMap.get(
        savedAccount.accountDescriptor.type
      );
      if (set) {
        for (const account of set) {
          baseAccounts.push(account);
        }
      }
      includeSubaccount = true;
    }

    for (const account of baseAccounts) {
      action(account.id);

      if (includeSubaccount) {
        for (const subaccount of subaccountMap(account)) {
          action(subaccount.id);
        }
      }
    }
  }
  return result;
}
