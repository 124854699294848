import React, { createRef, createContext, PureComponent } from "react";
import { unstable_batchedUpdates } from "react-dom";

type Listener = (
  x0: number,
  y0: number,
  x1: number,
  y1: number,
  done: boolean
) => void;

interface ContextValue {
  registerListener(l: Listener): void;
  unregisterListener(l: Listener): void;
}

const Context = createContext<ContextValue | null>(null);

export class CheckboxContainer extends PureComponent<{
  selectionClassName?: string;
}> {
  private listeners = new Set<Listener>();

  private element: HTMLDivElement | null = null;
  private xs = 0;
  private ys = 0;

  private nextTickScheduled = false;

  updateDiv = (e: MouseEvent, done = false) => {
    if (this.element) {
      let x0 = Math.min(this.xs, e.pageX),
        y0 = Math.min(this.ys, e.pageY),
        x1 = Math.max(this.xs, e.pageX),
        y1 = Math.max(this.ys, e.pageY);
      this.element.style.left = x0 + "px";
      this.element.style.top = y0 + "px";
      this.element.style.width = x1 - x0 + "px";
      this.element.style.height = y1 - y0 + "px";
      if (x1 - x0 > 4 || y1 - y0 > 4) {
        unstable_batchedUpdates(() =>
          this.listeners.forEach(l => l(x0, y0, x1, y1, done))
        );
      }
    }
  };

  onMouseDown = (e: MouseEvent) => {
    if (!this.element && this.listeners.size > 0 && e.buttons === 1) {
      this.element = document.createElement("div");
      if (this.props.selectionClassName) {
        this.element.className = this.props.selectionClassName;
      } else {
        this.element.style.border = "1px solid red";
        this.element.style.backgroundColor = "rgba(255,0,0,0.1)";
        this.element.style.position = "absolute";
        this.element.style.zIndex = "1000";
        this.element.style.pointerEvents = "none";
      }
      document.body.appendChild(this.element);
      this.xs = e.pageX;
      this.ys = e.pageY;

      this.updateDiv(e);
    }
  };
  onMouseUp = (e: MouseEvent) => {
    this.updateDiv(e, true);
    if (this.element) {
      const leavingElement = this.element;
      this.element = null;
      leavingElement.style.transition = "opacity 0.2s linear";
      leavingElement.style.opacity = "0";
      leavingElement.addEventListener("transitionend", () => {
        document.body.removeChild(leavingElement);
      });
    }
  };
  onMouseMove = (e: MouseEvent) => {
    if (!this.nextTickScheduled) {
      this.nextTickScheduled = true;
      window.requestAnimationFrame(() => {
        this.nextTickScheduled = false;
        this.updateDiv(e);
      });
    }
    if (this.element) {
      e.preventDefault();
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.onMouseDown);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.onMouseDown);
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("mouseup", this.onMouseUp);
  }

  render() {
    const self = this;

    const contextValue: ContextValue = {
      registerListener(l) {
        self.listeners.add(l);
      },

      unregisterListener(l) {
        self.listeners.delete(l);
      }
    };
    return (
      <Context.Provider value={contextValue}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export class Checkbox extends PureComponent<
  React.InputHTMLAttributes<HTMLInputElement>
> {
  private ref = createRef<HTMLInputElement>();

  static contextType = Context;

  private revert = false;

  private listener: Listener = (x0, y0, x1, y1, done) => {
    if (this.ref.current && (!this.ref.current.checked || this.revert)) {
      const rect = this.ref.current.getBoundingClientRect();
      if (rect) {
        const cx0 = rect.left + window.scrollX;
        const cx1 = cx0 + rect.width;
        const cy0 = rect.top + window.scrollY;
        const cy1 = cy0 + rect.height;

        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "checked"
        )?.set;
        if (cx0 >= x0 && cx1 <= x1 && cy0 >= y0 && cy1 <= y1) {
          nativeInputValueSetter?.call(this.ref.current, true);
          this.revert = true;
          this.ref.current.dispatchEvent(new Event("click", { bubbles: true }));
        } else if (this.revert) {
          nativeInputValueSetter?.call(this.ref.current, false);
          this.revert = false;
          this.ref.current.dispatchEvent(new Event("click", { bubbles: true }));
        }
      }
    }
    if (done) {
      this.revert = false;
    }
  };

  componentDidMount() {
    if (this.context) {
      this.context.registerListener(this.listener);
    }
  }

  componentWillUnmount() {
    this.context.unregisterListener(this.listener);
  }

  render() {
    return <input type="checkbox" ref={this.ref} {...this.props} />;
  }
}
