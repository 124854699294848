import React from "react";
import produce from "immer";
import { ChartingOptions } from "data/ledgers/types";
import css from "./options.module.css";
import { BindedInput } from "components/common/binded_input";

export const DEFAULT_OPTIONS: ChartingOptions = {
  type: "line",
  data: "delta",
  accounts: "separate",
  grouping: "month",
  origin: "zero",
  signConventionDebit: "positive",
  signConventionCredit: "negative",
  extrapolateDays: 0,
};

function cleanUpOption(options: ChartingOptions) {
  return produce(options, (draftOptions) => {
    if (options.type === "bar") {
      draftOptions.regression = undefined;
      draftOptions.accounts = "sum";
    } else if (options.type === "pie" || options.type === "treemap") {
      draftOptions.regression = undefined;
      draftOptions.accounts = "separate";
      if (options.data === "delta") {
        draftOptions.data = "cumulative";
      }
    }
  });
}

export const ChartingSettings: React.FC<{
  options: ChartingOptions;
  onChange(newOptions: ChartingOptions): void;
}> = ({ options, onChange: rawOnChange }) => {
  const onChange = (options: ChartingOptions) =>
    rawOnChange(cleanUpOption(options));
  return (
    <fieldset className={css.options}>
      <legend>Graph Options</legend>
      <ul className={css.optionsList}>
        <li>
          <label>
            Type:
            <BindedInput
              tagName="select"
              state={options}
              bindTo="type"
              onChange={onChange}
            >
              <option value="line">Line</option>
              <option value="bar">Bar</option>
              <option value="treemap">Treemap</option>
              <option value="pie">Pie</option>
            </BindedInput>
          </label>
        </li>
        <li>
          <label>
            Plot:
            <select
              onChange={(e) =>
                onChange({
                  ...options,
                  data: e.currentTarget.value as ChartingOptions["data"],
                })
              }
              value={options.data}
            >
              <option value="balance">Balance</option>
              <option
                value="delta"
                disabled={options.type === "pie" || options.type === "treemap"}
              >
                Change
              </option>
              <option value="cumulative">Cumulative Change</option>
            </select>
          </label>
        </li>
        {options.data === "delta" && (
          <li>
            <label>
              Group By
              <select
                onChange={(e) =>
                  onChange({
                    ...options,
                    grouping: e.currentTarget
                      .value as ChartingOptions["grouping"],
                  })
                }
                value={options.grouping}
              >
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="quarter">Quarter</option>
                <option value="year">Year</option>
              </select>
            </label>
          </li>
        )}

        <li>
          <label>
            Origin:
            <BindedInput
              tagName="select"
              state={options}
              bindTo="origin"
              onChange={onChange}
            >
              <option value="zero">Zero</option>
              <option value="auto">Auto</option>
            </BindedInput>
          </label>
        </li>
        <li>
          <label>
            Multiple Accounts:
            <BindedInput
              tagName="select"
              state={options}
              bindTo="accounts"
              onChange={onChange}
              props={{ disabled: options.type !== "line" }}
            >
              <option value="separate">Separate</option>
              <option value="sum">Sum</option>
            </BindedInput>
          </label>
        </li>
        <li>
          Sign conventions: Debit:
          <BindedInput
            tagName="select"
            state={options}
            bindTo="signConventionDebit"
            onChange={onChange}
          >
            <option value="positive">Positive</option>
            <option value="negative">Negative</option>
          </BindedInput>
          Credit:
          <BindedInput
            tagName="select"
            state={options}
            bindTo="signConventionCredit"
            onChange={onChange}
          >
            <option value="positive">Positive</option>
            <option value="negative">Negative</option>
          </BindedInput>
        </li>
        {options.type === "line" && (
          <li>
            <label>
              Regression:
              <BindedInput
                tagName="select"
                state={options}
                bindTo="regression"
                onChange={onChange}
                valueMap={(v) =>
                  v === "none" ? undefined : (v as typeof options["regression"])
                }
                valueUnmap={(v) => v || "none"}
              >
                <option value="none">None</option>
                <option value="linear">Linear</option>
                <option value="power">Power</option>
                <option value="exponential">Exponential</option>
                <option value="logarithmic">Logarithmic</option>
              </BindedInput>
            </label>
          </li>
        )}
        {options.regression && (
          <li>
            <label>
              Projection:
              <BindedInput
                tagName="select"
                state={options}
                bindTo="extrapolateDays"
                onChange={onChange}
                valueMap={(v) => +v}
                valueUnmap={(v) => "" + v}
              >
                <option value="0">None</option>
                <option value="30">30 days</option>
                <option value="60">60 days</option>
                <option value="90">90 days</option>
                <option value="180">180 days</option>
                <option value="365">365 days</option>
              </BindedInput>
            </label>
          </li>
        )}
      </ul>
    </fieldset>
  );
};
