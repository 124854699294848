import React, { PureComponent } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import {
  ExternalAccount,
  Account,
  Split,
  Transaction,
  ImportedEntry,
} from "../../model/bookkeeping";
import { Dispatch, AppState } from "../../data/store";
import {
  entryDataByIdSelector,
  displayingExternalAccountDataListSelector,
} from "../../data/importer/selectors";
import {
  ExternalAccountData,
  entryIsReconciling,
  SettledEntry,
} from "../../data/importer/types";
import { format } from "../../model/scaled_value";
import { UUID } from "../../lib/core/uuid";
import {
  accountByExternalIdSelector,
  splitsAndTransactionsByAccountIdFuncSelector,
} from "../../data/accounts/selectors";
import { setImportToAccount } from "../../data/accounts/actions";
import {
  reconcile,
  markEntriesReconciled,
  autoReconcile,
} from "data/importer/actions";

import css from "./reconcile.module.css";
import commonCss from "./common.module.css";
import pageCss from "components/styles/page.module.css";
import buttonCss from "components/styles/button.module.css";
import { ImportToAccountOptions } from "./widgets/import_to_account";
import forEach from "lodash/forEach";
import { ConnectedSettings, ConnectedHiddenAccountToggle } from "./settings";
import { RichPager } from "components/common/pagination";

interface DerivedProps {
  externalAccountData: ExternalAccountData[];
  accountByExternalId: ReturnType<typeof accountByExternalIdSelector>;
  splitsAndTransactionsByAccountIdFunc: ReturnType<
    typeof splitsAndTransactionsByAccountIdFuncSelector
  >;
  entryDataById: ReturnType<typeof entryDataByIdSelector>;
}

interface DispatchProps {
  dispatch: Dispatch;
}

const PAGE_SIZE = 25;

const AccountTable: React.FC<{
  externalAccountData: ExternalAccountData;
  entryDataById: ReturnType<typeof entryDataByIdSelector>;
  account?: Account;
  splits: { split: Split; transaction: Transaction }[];
  setImportToAccount(extAccount: ExternalAccount, accountId: string): void;
  reconcile(entryId: UUID, splitId: UUID | null): void;
  autoReconcile(entries: ImportedEntry[], splits: Split[]): void;
}> = React.memo((props) => {
  const { account: extAccount, entryIds } = props.externalAccountData;

  const entryDataList = entryIds.map((id) => props.entryDataById[id]);
  const account = props.account;

  const unreconciledEntries: SettledEntry[] = [];
  const toSaveEntries = [];
  const savedEntries = [];

  const reconciledSplitIds = new Set<UUID>();

  const [selectedSplit, setSelectedSplit] = React.useState<UUID | undefined>(
    undefined
  );

  const [selectedEntry, setSelectedEntry] = React.useState<UUID | undefined>(
    undefined
  );

  const [entryStart, setEntryStart] = React.useState(0);
  const [splitStart, setSplitStart] = React.useState(0);

  for (const entryData of entryDataList) {
    if (entryData.entry.splitId) {
      savedEntries.push(entryData);
      reconciledSplitIds.add(entryData.entry.splitId);
    } else if (entryIsReconciling(entryData)) {
      toSaveEntries.push(entryData);
      reconciledSplitIds.add(entryData.reconcileSplitId);
    } else {
      unreconciledEntries.push(entryData);
    }
  }

  if (unreconciledEntries.length === 0 && toSaveEntries.length === 0) {
    return null;
  }

  const unreconciledSplits: { split: Split; transaction: Transaction }[] = [];

  const reconciledSplits: {
    [id: string]: { split: Split; transaction: Transaction };
  } = {};

  for (const split of props.splits) {
    if (reconciledSplitIds.has(split.split.id)) {
      reconciledSplits[split.split.id] = split;
    } else {
      unreconciledSplits.push(split);
    }
  }


  return (
    <div key={extAccount.id} className={cx(commonCss.account, pageCss.block)}>
      <div
        className={cx({
          [commonCss.accountUnselected]: !account,
        })}
      >
        <div className={css.unreconciled}>
          <div className={css.unreconciledEntries}>
            <h3>{extAccount.name}</h3>
            <table className={css.unreconciledTable}>
              <tbody>
                {unreconciledEntries
                  .slice(entryStart, entryStart + PAGE_SIZE)
                  .map((entryData) => {
                    const key = entryData.entry.id;
                    return (
                      <tr key={key}>
                        <td className={css.selector}>
                          <input
                            id={key}
                            type="radio"
                            name={`ent_${extAccount.id}`}
                            checked={entryData.entry.id === selectedEntry}
                            onClick={() => {
                              setSelectedEntry(entryData.entry.id);
                              if (selectedSplit) {
                                props.reconcile(
                                  entryData.entry.id,
                                  selectedSplit
                                );
                                setSelectedSplit(undefined);
                                setSelectedEntry(undefined);
                              }
                            }}
                          />
                        </td>
                        <td>
                          <label htmlFor={key}>{entryData.entry.memo}</label>
                        </td>
                        <td className={css.dateCell}>
                          {entryData.entry.datetime.datetime.toFormat("D")}
                        </td>
                        <td className={css.amountCell}>
                          {format(entryData.entry.valueScaled)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <RichPager
              data={unreconciledEntries}
              start={entryStart}
              size={PAGE_SIZE}
              pageSizes={[PAGE_SIZE]}
              onChange={setEntryStart}
              hideSinglePage={true}
            />
          </div>
          <div className={css.spacer}></div>
          <div className={css.unreconciledSplits}>
            {account && (
              <>
                <h3>{account.name}</h3>
                <table className={css.unreconciledTable}>
                  <tbody>
                    {unreconciledSplits
                      .slice(splitStart, splitStart + PAGE_SIZE)
                      .map((split) => {
                        const key = split.split.id;
                        return (
                          <tr key={key}>
                            <td className={css.selector}>
                              <input
                                id={key}
                                type="radio"
                                name={`split_${extAccount.id}`}
                                checked={split.split.id === selectedSplit}
                                onClick={() => {
                                  setSelectedSplit(split.split.id);
                                  if (selectedEntry) {
                                    props.reconcile(
                                      selectedEntry,
                                      split.split.id
                                    );
                                    setSelectedSplit(undefined);
                                    setSelectedEntry(undefined);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <label htmlFor={key}>
                                {split.split.memo || split.transaction.memo}
                              </label>
                            </td>
                            <td className={css.dateCell}>
                              {split.transaction.datetime.datetime.toFormat(
                                "D"
                              )}
                            </td>
                            <td className={css.amountCell}>
                              {format(split.split.valueScaled)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <RichPager
                  data={unreconciledSplits}
                  start={splitStart}
                  size={PAGE_SIZE}
                  pageSizes={[PAGE_SIZE]}
                  onChange={setSplitStart}
                  hideSinglePage={true}
                />
              </>
            )}
          </div>
        </div>
        <div className={css.reconcilation}>
          <div className={css.reconcilationTitle}>
            <h3>Reconcilation</h3>
            <button
              className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
              onClick={() =>
                props.autoReconcile(
                  unreconciledEntries.map((e) => e.entry),
                  unreconciledSplits.map((s) => s.split)
                )
              }
            >
              Auto Reconcile
            </button>
          </div>
          {toSaveEntries.length > 0 ? (
            <table className={css.reconcilationTable}>
              {toSaveEntries.map((entryData) => {
                const split = reconciledSplits[entryData.reconcileSplitId];
                return (
                  <tr>
                    <td>{entryData.entry.memo}</td>
                    <td className={css.dateCell}>
                      {entryData.entry.datetime.datetime.toFormat("D")}
                    </td>
                    <td className={css.amountCell}>
                      {format(entryData.entry.valueScaled)}
                    </td>
                    <td className={css.spacer}>
                      <button
                        onClick={() =>
                          props.reconcile(entryData.entry.id, null)
                        }
                      >
                        X
                      </button>
                    </td>
                    <td>{split.split.memo || split.transaction.memo}</td>
                    <td className={css.dateCell}>
                      {split.transaction.datetime.datetime.toFormat("D")}
                    </td>
                    <td className={css.amountCell}>
                      {format(entryData.entry.valueScaled)}
                    </td>
                  </tr>
                );
              })}
            </table>
          ) : (
              <div className={css.reconcilationHelp}>
                To reconcile a transaction, select the corresponding lines above
              </div>
            )}
        </div>
        <ConnectedSettings extAccount={extAccount} />
      </div>
      {!account && (
        <ImportToAccountOptions
          externalAccount={extAccount}
          disableCreation={true}
          onSetAccount={(accountId) =>
            props.setImportToAccount(extAccount, accountId)
          }
        />
      )}
    </div>
  );
});

class Reconciler extends PureComponent<DerivedProps & DispatchProps, {}> {
  renderTable() {
    return this.props.externalAccountData.map((externalAccountData) => {
      const account = this.props.accountByExternalId(
        externalAccountData.account.externalId
      );

      return (
        <AccountTable
          key={externalAccountData.account.id}
          externalAccountData={externalAccountData}
          entryDataById={this.props.entryDataById}
          account={account}
          splits={
            account
              ? this.props.splitsAndTransactionsByAccountIdFunc(account.id)
              : []
          }
          setImportToAccount={(extAccount, id) =>
            this.props.dispatch(setImportToAccount(extAccount, id))
          }
          reconcile={(entryId, splitId) =>
            this.props.dispatch(reconcile(entryId, splitId))
          }
          autoReconcile={(entries, splits) =>
            this.props.dispatch(autoReconcile(entries, splits))
          }
        />
      );
    });
  }

  onSaveTransactions = () => {
    const mapping = new Map<UUID, UUID>();
    forEach(this.props.entryDataById, (val) => {
      if (entryIsReconciling(val)) {
        mapping.set(val.entry.id, val.reconcileSplitId);
      }
    });
    this.props.dispatch(markEntriesReconciled(mapping));
  };

  render() {
    return (
      <div>
        <div className={cx(pageCss.block, commonCss.actions)}>
          <ul className={commonCss.actionList}>
            <li>
              <button
                onClick={this.onSaveTransactions}
                className={cx(buttonCss.button, buttonCss.buttonBlue)}
              >
                Save
              </button>
            </li>
          </ul>
        </div>
        {this.renderTable()}
        <ConnectedHiddenAccountToggle />
      </div>
    );
  }
}

export const ConnectedReconciler = connect((state: AppState, ownProps: {}) => ({
  externalAccountData: displayingExternalAccountDataListSelector(state),
  accountByExternalId: accountByExternalIdSelector(state),
  splitsAndTransactionsByAccountIdFunc: splitsAndTransactionsByAccountIdFuncSelector(
    state
  ),
  entryDataById: entryDataByIdSelector(state),
}))(Reconciler);
