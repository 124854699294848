import { AppState } from "data/store";
import { TRANSACTIONS_NS } from "./types";
import { createSelector } from "reselect";
import {
  reverseSortedTransactionsSelector,
  splitsByTransactionSelector
} from "data/accounts/selectors";
import { Filter } from "model/filtering";
import { Transaction, Split } from "model/bookkeeping";

export const filtersSelector = (state: AppState) =>
  state[TRANSACTIONS_NS].filters;

export const searchTermSelector = (state: AppState) =>
  state[TRANSACTIONS_NS].searchTerm;

function searchTermMatch(haystack: string, needle: string): boolean {
  return haystack.toLocaleLowerCase().includes(needle.toLocaleLowerCase());
}

function filterTransaction(filter: Filter, tx: Transaction, splits: Split[]) {
  if (filter.dateRange) {
    const time = tx.datetime.datetime.toMillis();

    if (
      time < filter.dateRange.startTime.toMillis() ||
      time >= filter.dateRange.endTime.toMillis()
    ) {
      return false;
    }
  }
  if (
    filter.accountsInvolved &&
    filter.accountsInvolved.length > 0 &&
    filter.accountsInvolved.some(id => splits.every(sp => sp.accountId !== id))
  ) {
    return false;
  }
  if (filter.memo && !searchTermMatch(tx.memo, filter.memo)) {
    return false;
  }

  return true;
}

export const filteredTransactions = createSelector(
  reverseSortedTransactionsSelector,
  splitsByTransactionSelector,
  filtersSelector,
  searchTermSelector,
  (txs, splitsByTx, filters, searchTerm) => {
    if (filters.length === 0 && searchTerm) {
      filters = [
        {
          memo: searchTerm
        }
      ];
    }

    const newfilters = filters.filter(f => Object.keys(f).length > 0);

    if (newfilters.length > 0) {
      return txs.filter(tx =>
        newfilters.some(f => filterTransaction(f, tx, splitsByTx[tx.id]))
      );
    } else {
      return txs;
    }
  }
);
