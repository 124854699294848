import React, { PureComponent, useEffect, createRef } from "react";
import cx from "classnames";
import { Split, Transaction } from "model/bookkeeping";
import { connect } from "react-redux";
import {
  transactionByIdSelector,
  accountsMapSelector,
  splitsByTransactionSelector
} from "data/accounts/selectors";
import { AppState } from "data/store";
import {
  filteredSplitsSelector,
  checkedSplitIdsSelector,
  checkedSplitsSelector,
  pagingSelector,
  selectedAccountIdSelector
} from "data/ledgers/selectors";
import {
  formatDebitCredit,
  zero,
  sum,
  ScaledValue,
  negate
} from "model/scaled_value";
import { ReactComponent as CaretLeft } from "assets/font-awesome-solid/caret-left.svg";
import { ReactComponent as CaretDown } from "assets/font-awesome-solid/caret-down.svg";
import { ReactComponent as Checkmark } from "assets/font-awesome-solid/check.svg";
import {
  AccountDisplay,
  ConnectedAccountSelector
} from "components/common/account";
import buttonCss from "../styles/button.module.css";
import css from "./ledger.module.css";
import {
  setSelectedAccount,
  setSplitSelected,
  clearSplitSelected,
  setPaging
} from "data/ledgers/actions";
import pageCss from "components/styles/page.module.css";
import { UUID, newUuid } from "lib/core/uuid";
import { RichPager } from "components/common/pagination";
import { ReactComponent as MenuIcon } from "assets/font-awesome-solid/bars.svg";
import { SimpleDropdownMenu } from "components/common/simple_dropdown_menu";
import { visit } from "components/core/routing";
import { mutate, addTransactions } from "data/accounts/actions";
import { TransactionMutations } from "data/accounts/types";
import { DateTime } from "luxon";
import { ValueScaledInput } from "components/common/value_scaled_input";

interface RowProps {
  index: number;
  split: Split;
  transaction: Transaction;
  checked: boolean;
  accountsMap: ReturnType<typeof accountsMapSelector>;
  splitsByTransaction: ReturnType<typeof splitsByTransactionSelector>;
  setSelectedAccount: typeof setSelectedAccount;
  onSelectionChange(id: UUID, selected: boolean): void;
  onMoveSplit(splitId: UUID, accountId: UUID): Promise<void>;
}

interface RowState {
  showFullTransaction?: boolean;
  movingSplit?: UUID;
}

const OutsideClickListener: React.FC<React.HTMLAttributes<HTMLDivElement> & {
  onClickOutside(): void;
}> = props => {
  const { children, ...rest } = props;
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    function onClickHandler(e: Event) {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        props.onClickOutside();
      }
    }

    document.addEventListener("click", onClickHandler);

    return () => {
      document.removeEventListener("click", onClickHandler);
    };
  });

  return (
    <div {...rest} ref={ref}>
      {children}
    </div>
  );
};

class RowTr extends PureComponent<
  {
    split: Split;
    transaction: Transaction;
    className?: string;
    accountsMap: ReturnType<typeof accountsMapSelector>;

    setSelectedAccount: typeof setSelectedAccount;

    checkBoxCell?: React.ReactNode;
    expandCell?: React.ReactNode;
    otherAccountCell?: React.ReactNode;

    moving: boolean;
    onMove(splitId: UUID, accountId: UUID): Promise<void>;

    onMoveStart(splitId: UUID): void;
    onMoveEnd(): void;
  },
  {}
  > {
  render() {
    const {
      split,
      transaction,
      className,
      accountsMap,
      expandCell,
      checkBoxCell,
      otherAccountCell
    } = this.props;
    const [dr, cr] = formatDebitCredit(split.valueScaled);
    return (
      <tr className={className}>
        <td className={css.checkboxCell}>{checkBoxCell}</td>
        <td className={css.dateCell}>
          {split.datetime.datetime.toFormat("D")}
        </td>
        <td>{split.memo || transaction.memo}</td>
        <td className={css.accountCell}>
          {this.props.moving ? (
            <OutsideClickListener onClickOutside={this.props.onMoveEnd}>
              <ConnectedAccountSelector
                onChange={id => this.props.onMove(split.id, id)}
                hidePlaceholders={true}
              />
            </OutsideClickListener>
          ) : (
              <button
                className={buttonCss.styleless}
                onClick={() => this.props.setSelectedAccount([split.accountId])}
              >
                <AccountDisplay
                  accountName={accountsMap.get(split.accountId)!.name}
                />
              </button>
            )}
        </td>
        <td className={css.accountCell}>{otherAccountCell}</td>
        <td className={css.amountCell}>{dr}</td>
        <td className={css.amountCell}>{cr}</td>
        <td className={css.menuCell}>{expandCell}</td>
        <td className={css.menuCell}>
          <SimpleDropdownMenu
            buttonClass={buttonCss.styleless}
            items={[
              {
                title: "Move this leg...",
                action: () => this.props.onMoveStart(split.id)
              },
              {
                title: "Jump to transaction",
                action: () => {
                  visit("/transactions");
                  window.setTimeout(() => {
                    window.location.hash = "#" + transaction.id;
                  }, 16);
                }
              }
            ]}
          >
            <MenuIcon width={12} height={12} />
          </SimpleDropdownMenu>
        </td>
      </tr>
    );
  }
}

class Row extends PureComponent<RowProps, RowState> {
  state: RowState = {};
  onMoveEnd = () => this.setState({ movingSplit: undefined });
  onMoveStart = (id: UUID) =>
    this.setState({
      movingSplit: id
    });

  render() {
    const { showFullTransaction } = this.state;
    const {
      index,
      split,
      transaction,
      accountsMap,
      splitsByTransaction,
      checked: selected
    } = this.props;
    const splitsInTransaction = splitsByTransaction[split.transactionId];

    const otherSplits = splitsInTransaction.filter(s => s.id !== split.id);

    const otherAccount = showFullTransaction ? null : otherSplits.length > 1 ? (
      <span>Multileg</span>
    ) : (
        <button
          className={buttonCss.styleless}
          onClick={() =>
            this.props.setSelectedAccount([otherSplits[0].accountId])
          }
        >
          <AccountDisplay
            accountName={accountsMap.get(otherSplits[0].accountId)!.name}
          />
        </button>
      );

    const fullTransaction = showFullTransaction
      ? otherSplits.map(split => {
        return (
          <RowTr
            key={split.id}
            split={split}
            transaction={transaction}
            className={css.expandRow}
            accountsMap={accountsMap}
            setSelectedAccount={this.props.setSelectedAccount}
            moving={this.state.movingSplit === split.id}
            onMove={this.props.onMoveSplit}
            onMoveEnd={this.onMoveEnd}
            onMoveStart={this.onMoveStart}
          />
        );
      })
      : [];

    const mainRow = (
      <RowTr
        key={split.id}
        className={cx({
          [css.evenRow]: index % 2,
          [css.expandedRow]: showFullTransaction
        })}
        split={split}
        transaction={transaction}
        accountsMap={accountsMap}
        setSelectedAccount={this.props.setSelectedAccount}
        moving={split.id === this.state.movingSplit}
        onMove={this.props.onMoveSplit}
        onMoveEnd={this.onMoveEnd}
        onMoveStart={this.onMoveStart}
        checkBoxCell={
          <input
            type="checkbox"
            checked={selected}
            onChange={e =>
              this.props.onSelectionChange(split.id, e.currentTarget.checked)
            }
          />
        }
        otherAccountCell={otherAccount}
        expandCell={
          <button
            className={buttonCss.styleless}
            onClick={() =>
              this.setState({
                showFullTransaction: !showFullTransaction
              })
            }
          >
            {showFullTransaction ? (
              <CaretDown width={12} height={12} />
            ) : (
                <CaretLeft width={12} height={12} />
              )}
          </button>
        }
      />
    );
    return [mainRow, ...fullTransaction];
  }
}

type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  indeterminate?: boolean;
};

class IndetermineCheckbox extends PureComponent<CheckboxProps> {
  ref = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.ref.current) {
      this.ref.current.indeterminate = !!this.props.indeterminate;
    }
  }

  componentDidUpdate(prevProps: CheckboxProps) {
    if (
      this.props.indeterminate !== prevProps.indeterminate &&
      this.ref.current
    ) {
      this.ref.current.indeterminate = !!this.props.indeterminate;
    }
  }

  render() {
    const { indeterminate: indetermine, ...rest } = this.props;

    return <input type="checkbox" ref={this.ref} {...rest} />;
  }
}

type QuickAddProps = {
  thisAccount?: UUID;
  onAddTransactions(txs: Transaction[], splits: Split[]): Promise<void>;
};

type QuickAddState = {
  memo: string;
  date: string;
  amount: ScaledValue;
  thisAccount?: UUID;
  otherAccount?: UUID;

  isAdding: boolean;
  showError: boolean;
};

class QuickAdd extends PureComponent<QuickAddProps, QuickAddState> {
  private firstInputRef = React.createRef<HTMLInputElement>();

  constructor(props: QuickAddProps) {
    super(props);

    this.state = {
      memo: "",
      date: DateTime.local().toISODate(),
      amount: zero(),
      isAdding: false,
      showError: false
    };
  }

  componentDidUpdate(prevProps: QuickAddProps) {
    if (
      this.props.thisAccount !== prevProps.thisAccount &&
      prevProps.thisAccount === this.state.thisAccount
    ) {
      this.setState({ thisAccount: this.props.thisAccount, showError: false });
    }
  }

  async addTransaction() {
    this.setState({ showError: true });
    const dt = DateTime.fromISO(this.state.date);
    if (
      !this.state.thisAccount ||
      !this.state.otherAccount ||
      !this.state.amount ||
      !dt.isValid
    ) {
      return;
    }
    try {
      const datetime = {
        datetime: dt,
        hasTime: false
      };
      const tx: Transaction = {
        id: newUuid(),
        memo: this.state.memo,
        tags: {},
        datetime
      };
      const thisSplit: Split = {
        id: newUuid(),
        transactionId: tx.id,
        datetime,
        accountId: this.state.thisAccount,
        valueScaled: this.state.amount,
        tags: {}
      };

      const otherSplit: Split = {
        id: newUuid(),
        transactionId: tx.id,
        datetime,
        accountId: this.state.otherAccount,
        valueScaled: negate(this.state.amount),
        tags: {}
      };

      this.setState({ isAdding: true });
      await this.props.onAddTransactions([tx], [thisSplit, otherSplit]);

      this.setState(
        {
          memo: "",
          amount: zero(),
          thisAccount: undefined,
          otherAccount: undefined,
          showError: false,
          isAdding: false
        },
        () => this.firstInputRef.current && this.firstInputRef.current.focus()
      );
    } catch (e) {
      this.setState({ isAdding: false });
    }
  }

  onKeyUp: React.KeyboardEventHandler = e => {
    if (e.key === "Enter") {
      this.addTransaction();
    }
  };

  render() {
    return (
      <tr className={css.quickAddRow}>
        <td className={css.checkboxCell} />
        <td className={css.dateCell}>
          <input
            type="date"
            className={cx(css.dateInput, {
              [css.inputError]:
                this.state.showError &&
                !DateTime.fromISO(this.state.date).isValid
            })}
            disabled={this.state.isAdding}
            value={this.state.date}
            onChange={e => this.setState({ date: e.currentTarget.value })}
            ref={this.firstInputRef}
            onKeyUp={this.onKeyUp}
          />
        </td>
        <td>
          <input
            type="text"
            className={cx(css.memoInput, {
              [css.inputError]: this.state.showError && !this.state.memo
            })}
            value={this.state.memo}
            onChange={e => this.setState({ memo: e.currentTarget.value })}
            disabled={this.state.isAdding}
            placeholder="Memo"
            onKeyUp={this.onKeyUp}
          />
        </td>
        <td className={css.accountCell}>
          <ConnectedAccountSelector
            disabled={this.state.isAdding}
            value={this.state.thisAccount}
            onChange={v => this.setState({ thisAccount: v })}
            hidePlaceholders={true}
            displayProps={{
              className: css.accountInput
            }}
            inputProps={{
              className: cx(css.accountInput, {
                [css.inputError]:
                  this.state.showError && !this.state.thisAccount
              }),
              placeholder: "Account"
            }}
          />
        </td>
        <td className={css.accountCell}>
          <ConnectedAccountSelector
            disabled={this.state.isAdding}
            value={this.state.otherAccount}
            onChange={v => this.setState({ otherAccount: v })}
            hidePlaceholders={true}
            displayProps={{
              className: css.accountInput
            }}
            inputProps={{
              className: cx(css.accountInput, {
                [css.inputError]:
                  this.state.showError && !this.state.otherAccount
              }),
              placeholder: "Transfer Account"
            }}
          />
        </td>
        <td className={css.amountCell}>
          <ValueScaledInput
            className={cx(css.amountInput, {
              [css.inputError]: this.state.showError && !this.state.amount
            })}
            disabled={this.state.isAdding}
            value={this.state.amount >= 0 ? this.state.amount : undefined}
            onChange={v => this.setState({ amount: v })}
            onKeyUp={this.onKeyUp}
          />
        </td>
        <td className={css.amountCell}>
          <ValueScaledInput
            className={cx(css.amountInput, {
              [css.inputError]: this.state.showError && !this.state.amount
            })}
            disabled={this.state.isAdding}
            value={
              this.state.amount < 0 ? negate(this.state.amount) : undefined
            }
            onChange={v => this.setState({ amount: negate(v) })}
            onKeyUp={this.onKeyUp}
          />
        </td>
        <td className={css.menuCell}>
          <button
            className={cx(buttonCss.styleless, css.quickAddButton)}
            onClick={() => this.addTransaction()}
          >
            <Checkmark width={12} height={12} />
          </button>
        </td>
        <td className={css.menuCell} />
      </tr>
    );
  }
}

class SplitsDisplay extends PureComponent<
  {
    splits: Split[];
    transactions: { [id: string]: Transaction };
    accountsMap: ReturnType<typeof accountsMapSelector>;
    splitsByTransaction: ReturnType<typeof splitsByTransactionSelector>;
    checkedSplitIds: ReturnType<typeof checkedSplitIdsSelector>;
    checkedSplits: ReturnType<typeof checkedSplitsSelector>;
    paging: [number, number];
    selectedAccounts: UUID[];

    setSelectedAccount: typeof setSelectedAccount;
    setSplitSelected: typeof setSplitSelected;
    clearSplitSelected: typeof clearSplitSelected;

    mutate(mutations: TransactionMutations): Promise<void>;
    addTransactions(txs: Transaction[], splits: Split[]): Promise<void>;

    setPaging: typeof setPaging;
  },
  {
    showQuickAdd: boolean;
  }
  > {
  state = { showQuickAdd: false };

  changeSelection = (id: UUID, selected: boolean) => {
    this.props.setSplitSelected({ [id]: selected });
  };

  checkAll = () => {
    this.props.clearSplitSelected();
    this.props.setSplitSelected(
      this.props.splits.reduce((acc, split) => {
        acc[split.id] = true;
        return acc;
      }, {} as { [k: string]: boolean })
    );
  };

  uncheckAll = () => {
    this.props.clearSplitSelected();
  };

  footer() {
    const { checkedSplits } = this.props;
    const total = checkedSplits.reduce(
      (acc, split) => sum(acc, split.valueScaled),
      zero()
    );
    const [dr, cr] = formatDebitCredit(total);
    return (
      <tfoot>
        <tr>
          <td className={css.checkboxCell} />
          <td className={css.dateCell} />
          <td>Selected Legs</td>
          <td className={css.accountCell} />
          <td className={css.accountCell} />
          <td className={css.amountCell}>{dr}</td>
          <td className={css.amountCell}>{cr}</td>
          <td className={css.menuCell} />
          <td className={css.menuCell} />
        </tr>
      </tfoot>
    );
  }

  onMoveSplit = (splitId: UUID, accountId: UUID) =>
    this.props.mutate({
      dirtySplits: {
        [splitId]: {
          accountId
        }
      },
      dirtyTransactions: {},
      newTransactions: [],
      newSplits: [],
      deletedTransactionIds: []
    });

  render() {
    const {
      splits,
      transactions,
      accountsMap,
      splitsByTransaction,
      checkedSplitIds,
      checkedSplits,
      paging: [pagingOffset, pagingSize]
    } = this.props;

    const countSelected = checkedSplits.length;

    return (
      <div className={cx(css.wrapper, pageCss.block)}>
        <table className={css.ledgerTable}>
          <thead>
            <tr>
              <th className={css.checkboxCell}>
                <IndetermineCheckbox
                  checked={countSelected > 0}
                  indeterminate={
                    countSelected > 0 && countSelected < splits.length
                  }
                  onChange={e =>
                    e.currentTarget.checked
                      ? this.checkAll()
                      : this.uncheckAll()
                  }
                />
              </th>
              <th className={css.dateCell}>Date</th>
              <th>Memo</th>
              <th className={css.accountCell}>Account</th>
              <th className={css.accountCell}>Transfer Account</th>
              <th className={css.amountCell}>Debit</th>
              <th className={css.amountCell}>Credit</th>
              <th className={css.menuCell} />
              <th className={css.menuCell} />
            </tr>
          </thead>
          <tbody>
            {splits
              .slice(pagingOffset, pagingOffset + pagingSize)
              .map(
                (split, i) =>
                  transactions[split.transactionId] && (
                    <Row
                      key={split.id}
                      index={i + 1}
                      split={split}
                      transaction={transactions[split.transactionId]}
                      accountsMap={accountsMap}
                      splitsByTransaction={splitsByTransaction}
                      setSelectedAccount={this.props.setSelectedAccount}
                      checked={!!checkedSplitIds[split.id]}
                      onSelectionChange={this.changeSelection}
                      onMoveSplit={this.onMoveSplit}
                    />
                  )
              )}
            {splits.length === 0 && (
              <tr>
                <td colSpan={9} className={css.empty}>
                  No entries found
                </td>
              </tr>
            )}
            {this.state.showQuickAdd && (
              <QuickAdd
                onAddTransactions={this.props.addTransactions}
                thisAccount={
                  this.props.selectedAccounts.length === 1
                    ? this.props.selectedAccounts[0]
                    : undefined
                }
              />
            )}
          </tbody>
          {checkedSplits.length > 0 && this.footer()}
        </table>
        {splits.length > 0 && (
          <div className={css.bottom}>
            <div className={css.pager}>
              <RichPager
                data={splits}
                start={pagingOffset}
                size={pagingSize}
                onChange={this.props.setPaging}
                pageSizes={[50, 100, 250, 500]}
              />
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  checked={this.state.showQuickAdd}
                  onChange={e =>
                    this.setState({ showQuickAdd: e.currentTarget.checked })
                  }
                />{" "}
                Show Transaction Creator
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const ConnectedSplitsDisplay = connect(
  (state: AppState) => ({
    splits: filteredSplitsSelector(state),
    transactions: transactionByIdSelector(state),
    accountsMap: accountsMapSelector(state),
    splitsByTransaction: splitsByTransactionSelector(state),
    checkedSplitIds: checkedSplitIdsSelector(state),
    checkedSplits: checkedSplitsSelector(state),
    paging: pagingSelector(state),
    selectedAccounts: selectedAccountIdSelector(state)
  }),
  {
    setSelectedAccount,
    setSplitSelected,
    clearSplitSelected,
    setPaging,
    mutate,
    addTransactions
  }
)(SplitsDisplay);
