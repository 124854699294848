import { DateTime } from "luxon";
import regressionLib from "regression";

export function regression(
  series: { x: DateTime; y: number }[],
  options: {
    regression?: "linear" | "power" | "exponential" | "logarithmic";
    extrapolateDays: number;
  }
): { x: DateTime; y: number }[] {
  const NUM_POINTS = 100;
  const { regression, extrapolateDays } = options;
  if (!regression) {
    return [];
  }
  if (series.length > 1) {
    const dX = series[0].x.toMillis() - Math.E;
    const dY = Math.min(...series.map(dp => dp.y)) - Math.E;
    const datapoints = series.map(
      dp =>
        [(dp.x.toMillis() - dX) / 1000 / 3600, dp.y - dY] as [number, number]
    );

    const func = regressionLib[regression](datapoints, { precision: 15 });

    const lastX = datapoints[datapoints.length - 1][0] + extrapolateDays * 24;
    const firstX = datapoints[0][0];
    const interval = (lastX - firstX) / NUM_POINTS;

    const result = [];
    for (let x = firstX; x < lastX; x += interval) {
      const [, y] = func.predict(x);
      result.push({
        x: DateTime.fromMillis(dX + x * 1000 * 3600),
        y: y + dY
      });
    }
    return result;
  }
  return [];
}
