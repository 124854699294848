import React, { PureComponent, Suspense } from "react";
import cx from "classnames";
import { Dispatch, AppState } from "data/store";
import { connect } from "react-redux";
import {
  parseExternalFiles,
  addExternalAccountAndEntries,
  clearCSV
} from "../../data/importer/actions";
import { EXTENSIONS_FORMATS } from "data/importer/constants";
import css from "./upload.module.css";
import buttonCss from "components/styles/button.module.css";
import pageCss from "components/styles/page.module.css";
import ReactModal from "react-modal";
import {
  csvSelector,
  externalAccountByIdSelector
} from "data/importer/selectors";
import { importCSV } from "data/importer/actions";
import { ExternalAccount, ImportedEntry } from "model/bookkeeping";

const LazyCSVImporter = React.lazy(() =>
  import("./csv").then(m => ({ default: m.CSVImporter }))
);

const CSVModal: React.FC<{
  csv?: string[][];
  externalAccounts: { [id: string]: ExternalAccount };
  addExternalAccountAndEntries(
    acc: ExternalAccount,
    entries: ImportedEntry[]
  ): void;
  clearCSV: typeof clearCSV;
}> = props => {
  if (!props.csv) {
    return null;
  }
  return (
    <ReactModal isOpen={true} onRequestClose={props.clearCSV}>
      <h1>CSV Import Wizard</h1>
      <Suspense fallback={<div />}>
        <LazyCSVImporter
          csv={props.csv}
          externalAccounts={props.externalAccounts}
          onSave={(acc, ents) => {
            props.addExternalAccountAndEntries(acc, ents);
            props.clearCSV();
          }}
        />
      </Suspense>
    </ReactModal>
  );
};

const ConnectedCSVModal = connect(
  (state: AppState) => ({
    csv: csvSelector(state),
    externalAccounts: externalAccountByIdSelector(state)
  }),
  { addExternalAccountAndEntries, clearCSV }
)(CSVModal);

class DropElement extends PureComponent<
  { onChange: (files: File[] | null) => void; accept?: string },
  { isOver?: boolean }
> {
  onFileChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    this.props.onChange(
      e.currentTarget.files && Array.from(e.currentTarget.files)
    );
    e.currentTarget.value = "";
  };

  onDragOver: React.DragEventHandler<{}> = e => {
    e.preventDefault();
    this.setState({ isOver: true });
  };

  onDragExit: React.DragEventHandler<{}> = e => {
    e.preventDefault();
    this.setState({ isOver: false });
  };

  onDrop: React.DragEventHandler<{}> = e => {
    e.preventDefault();
    this.setState({ isOver: false });
    if (e.dataTransfer.items) {
      const files = [];
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === "file") {
          const file = e.dataTransfer.items[i].getAsFile();
          if (file) {
            files.push(file);
          }
        }
      }
      this.props.onChange(files);
    } else {
      this.props.onChange(null);
    }
  };

  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragExit}
        className={cx(
          css.dropTarget,
          {
            [css.dropTargetOver]: this.state && this.state.isOver
          },
          pageCss.block
        )}
      >
        <label
          role="button"
          className={cx(
            buttonCss.button,
            buttonCss.buttonBlue,
            css.uploadButton
          )}
        >
          Select Files{" "}
          <input
            type="file"
            onChange={this.onFileChange}
            multiple
            accept={this.props.accept}
          />
        </label>
        <div>Or, drag and drop your files here</div>
      </div>
    );
  }
}

export class Upload extends PureComponent<{
  dispatch: Dispatch;
  onUploaded: () => void;
}> {
  onFileChange = async (files: File[] | null) => {
    if (files) {
      await this.props.dispatch(parseExternalFiles(files));
      this.props.onUploaded();
    }
  };

  onCSVChange = async (files: File[] | null) => {
    if (files && files.length >= 1) {
      await this.props.dispatch(importCSV(files[0]));
    }
  };

  render() {
    return (
      <div className={css.uploadPage}>
        <h2>OFX/QFX File</h2>
        <DropElement
          onChange={this.onFileChange}
          accept={Object.keys(EXTENSIONS_FORMATS).join(",")}
        />
        <div className={pageCss.block}>
          <h3>Tips</h3>
          <ul>
            <li>
              You can download OFX/QFX file from your financial institution
              under Quicken Web Connect feature.
            </li>
            <li>You can upload multiple files at once.</li>
            <li>
              It is ok if two files have overlapping transactions. Duplicates
              are automatically ignored.
            </li>
          </ul>
        </div>
        <h2>CSV File</h2>
        <DropElement onChange={this.onCSVChange} accept=".csv" />
        <ConnectedCSVModal />
      </div>
    );
  }
}

export const ConnectedUpload = connect()(Upload);
