import { Persist } from "./base";
import { PersistLocal } from "./local";
import { PersistCloud } from "./cloud";

let persist: Persist;

export function getPersistence(): Persist {
  if (!persist) {
    if (process.env.REACT_APP_PERSIST === "cloud") {
      persist = new PersistCloud();
    } else {
      persist = new PersistLocal();
    }
  }
  return persist;
}
