import { Route } from "./lib";
import { ExternalAccountProvider } from "lib/ingestion/types";

export const linkingListRoute = new Route<
  {},
  {
    id: string;
    name: string;
    provider: ExternalAccountProvider;
    externalAccountIds: string[];
    updateKey?: string;
  }[]
>("/linking/list");
