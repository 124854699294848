import React, { useState, useEffect } from "react";

const RouterContext = React.createContext("");

let setPathGlobal: ((path: string) => void) | undefined = undefined;

function recursiveForEach(
  children: React.ReactNode,
  fn: (node: React.ReactElement) => void
) {
  React.Children.map(children, child => {
    if (React.isValidElement<any>(child)) {
      if (child.props.children) {
        recursiveForEach(child.props.children, fn);
      }
      fn(child);
    }
  });
}

function recursiveMap(
  children: React.ReactNode,
  fn: (node: React.ReactElement) => React.ReactElement
): React.ReactNode {
  return React.Children.map(children, child => {
    if (React.isValidElement<any>(child)) {
      if (child.props.children) {
        return fn(
          React.cloneElement(child, {
            children: recursiveMap(child.props.children, fn)
          })
        );
      } else {
        return fn(child);
      }
    } else {
      return child;
    }
  });
}

export const Router: React.FC<{}> = props => {
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    const onPop = (e: PopStateEvent) => {
      setPath(window.location.pathname);
    };
    setPathGlobal = setPath;
    window.addEventListener("popstate", onPop);
    return () => {
      window.removeEventListener("popstate", onPop);
      setPathGlobal = undefined;
    };
  }, []);

  const matchers: Matcher[] = [];

  recursiveForEach(props.children, node => {
    if (node.type === Route) {
      matchers.push(node.props);
    }
  });

  return (
    <RouterContext.Provider value={path}>
      {recursiveMap(props.children, node =>
        node.type === FallbackRoute
          ? React.cloneElement(node, { matchers })
          : node
      )}
    </RouterContext.Provider>
  );
};

interface ExactMatcher {
  match: string;
}

interface PrefixMatcher {
  matchPrefix: string;
}

type Matcher = ExactMatcher | PrefixMatcher;

function isExactMatcher(matcher: Matcher): matcher is ExactMatcher {
  return !!(matcher as ExactMatcher).match;
}

function matches(matcher: Matcher, value: string): boolean {
  return isExactMatcher(matcher) && value === matcher.match;
}

export const Route: React.FC<Matcher> = props => {
  return (
    <RouterContext.Consumer>
      {value => {
        if (matches(props, value)) {
          return props.children;
        }

        return null;
      }}
    </RouterContext.Consumer>
  );
};

export const FallbackRoute: React.FC<{}> = props => {
  return (
    <RouterContext.Consumer>
      {value => {
        const actualProps = props as { matchers?: Matcher[] };
        if (!actualProps.matchers) {
          throw new TypeError("FallbackRoute should be used inside a Router");
        }
        if (actualProps.matchers.some(matcher => matches(matcher, value))) {
          return null;
        } else {
          return props.children;
        }
      }}
    </RouterContext.Consumer>
  );
};

export function visit(path: string) {
  if (setPathGlobal) {
    setPathGlobal(path);
    window.history.pushState({}, "", path);
  }
}
