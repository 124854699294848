import { DataSet, SplitAndTxDate } from "./types";
import { UUID } from "lib/core/uuid";
import groupBy from "lodash/groupBy";
import { DateTime } from "luxon";
import { unscaleValue, ScaledValue } from "model/scaled_value";
import { dateToNumber, numberToDate } from "lib/datetime/date";
import { findDateIndex } from "data/accounts/selectors";
import { Filter } from "model/filtering";
import memoize from "memoize-one";
import { ChartingOptions } from "data/ledgers/types";

export function signForAccount(
  isDebit: boolean,
  options: Pick<ChartingOptions, "signConventionDebit" | "signConventionCredit">
) {
  if (isDebit) {
    return options.signConventionDebit === "negative" ? -1 : 1;
  } else {
    return options.signConventionCredit === "positive" ? -1 : 1;
  }
}

export const deltaDataSet = memoize(
  (
    splits: SplitAndTxDate[],
    accountIsDebit: (id: UUID) => boolean,
    options: Pick<
      ChartingOptions,
      "signConventionDebit" | "signConventionCredit" | "accounts" | "grouping"
    >
  ): DataSet => {
    const groupedSplits =
      options.accounts === "separate"
        ? Object.values(groupBy(splits, s => s[0].accountId))
        : [splits];

    return groupedSplits.map(splits => {
      let date: DateTime | undefined = undefined;
      const data: { x: DateTime; y: number }[] = [];
      for (let i = splits.length - 1; i >= 0; i--) {
        const [split, txDateTime] = splits[i];
        if (txDateTime.toMillis() <= 24 * 3600 * 1000) {
          // Ignore opening balance
          continue;
        }
        const dt = txDateTime.startOf(options.grouping);

        const value =
          signForAccount(accountIsDebit(split.accountId), options) *
          unscaleValue(split.valueScaled);

        if (
          date &&
          dt.year === date.year &&
          dt.month === date.month &&
          dt.day === date.day
        ) {
          data[data.length - 1].y += value;
        } else {
          date = DateTime.local(dt.year, dt.month, dt.day);
          data.push({ x: date, y: value });
        }
      }

      return {
        series: data,
        accountId:
          options.accounts === "separate" ? splits[0][0].accountId : undefined
      };
    });
  }
);

export const balanceDataSet = memoize(
  (
    balanceListFunc: (accountId: UUID) => [number, ScaledValue][],
    selectedAccountId: UUID[],
    filters: Filter[] | undefined,
    accountIsDebit: (id: UUID) => boolean,
    options: Pick<
      ChartingOptions,
      "signConventionCredit" | "signConventionDebit" | "accounts"
    >
  ): DataSet => {
    let minDateNum = Infinity,
      maxDateNum = -Infinity;
    if (filters) {
      for (const filter of filters) {
        if (filter.dateRange) {
          const startNum = dateToNumber(filter.dateRange.startTime);
          const endNum = dateToNumber(filter.dateRange.endTime);
          if (startNum < minDateNum) {
            minDateNum = startNum;
          }
          if (endNum > maxDateNum) {
            maxDateNum = endNum;
          }
        }
      }
    }

    if (minDateNum > maxDateNum) {
      minDateNum = -Infinity;
      maxDateNum = Infinity;
    }

    let dataSet: DataSet = selectedAccountId
      .map(accountId => {
        const data: { x: DateTime; y: number }[] = [];
        const balances = balanceListFunc(accountId);

        if (balances.length > 0) {
          const sign = signForAccount(accountIsDebit(accountId), options);
          let startIndex = findDateIndex(balances, minDateNum);
          if (startIndex < 0) startIndex = 0;
          let isFirst = true;
          while (
            startIndex < balances.length &&
            balances[startIndex][0] < maxDateNum
          ) {
            data.push({
              x: numberToDate(
                isFirst && minDateNum > balances[startIndex][0]
                  ? minDateNum
                  : balances[startIndex][0]
              ),
              y: unscaleValue(balances[startIndex][1]) * sign
            });
            isFirst = false;
            startIndex++;
          }
        }

        return { series: data, accountId };
      })
      .filter(l => l.series.length > 0);

    if (options.accounts === "sum") {
      const series: { x: DateTime; y: number }[] = [];
      let lastDateTime = undefined;
      let totalNum = 0;
      const indices: number[] = [];
      const balances: number[] = [];

      for (let i = 0; i < dataSet.length; i++) {
        indices.push(0);
        balances.push(0);
        totalNum += dataSet[i].series.length;
      }

      for (let _ = 0; _ < totalNum; _++) {
        let min = Number.MAX_SAFE_INTEGER,
          minIndex = -1;
        for (let i = 0; i < indices.length; i++) {
          if (dataSet[i].series[indices[i]]) {
            const val = dateToNumber(dataSet[i].series[indices[i]].x);
            if (val < min) {
              min = val;
              minIndex = i;
            }
          }
        }

        if (!lastDateTime) {
          lastDateTime = dataSet[minIndex].series[indices[minIndex]].x;
        }

        if (
          !dataSet[minIndex].series[indices[minIndex]].x.equals(lastDateTime)
        ) {
          series.push({
            x: lastDateTime,
            y: balances.reduce((a, b) => a + b)
          });
          lastDateTime = dataSet[minIndex].series[indices[minIndex]].x;
        }

        balances[minIndex] = dataSet[minIndex].series[indices[minIndex]].y;
        indices[minIndex]++;
      }

      if (lastDateTime) {
        series.push({
          x: lastDateTime,
          y: balances.reduce((a, b) => a + b)
        });
      }

      if (series.length > 0) {
        dataSet = [{ series }];
      }
    }

    for (const { series } of dataSet) {
      while (series.length > 0 && series[0].x.toMillis() <= 24 * 3600 * 1000) {
        series.shift();
      }
    }
    return dataSet.filter(({ series }) => series.length > 0);
  }
);

export const cumulativeDataSet = memoize(
  (
    splits: SplitAndTxDate[],
    accountIsDebit: (id: UUID) => boolean,
    options: Pick<
      ChartingOptions,
      "signConventionCredit" | "signConventionDebit" | "accounts"
    >
  ): DataSet => {
    const groupedSplits =
      options.accounts === "separate"
        ? Object.values(groupBy(splits, s => s[0].accountId))
        : [splits];

    return groupedSplits.map(splits => {
      let date: DateTime | undefined = undefined;
      const data: { x: DateTime; y: number }[] = [];
      for (let i = splits.length - 1; i >= 0; i--) {
        const [split, txDateTime] = splits[i];
        if (txDateTime.toMillis() <= 24 * 3600 * 1000) {
          // Ignore opening balance
          continue;
        }
        const dt = DateTime.local(
          txDateTime.year,
          txDateTime.month,
          txDateTime.day
        );

        const value =
          signForAccount(accountIsDebit(split.accountId), options) *
          unscaleValue(split.valueScaled);

        if (
          date &&
          dt.year === date.year &&
          dt.month === date.month &&
          dt.day === date.day
        ) {
          data[data.length - 1].y += value;
        } else {
          date = DateTime.local(dt.year, dt.month, dt.day);
          data.push({
            x: date,
            y: (data.length === 0 ? 0 : data[data.length - 1].y) + value
          });
        }
      }

      return {
        series: data,
        accountId:
          options.accounts === "separate" ? splits[0][0].accountId : undefined
      };
    });
  }
);
