import React, { PureComponent } from "react";
import cx from "classnames";
import { Transactions } from "./transactions";
import { ConnectedTransactionCreator } from "components/common/transaction_creator";
import { AppState } from "data/store";
import { addTransactions, mutate } from "data/accounts/actions";
import { connect } from "react-redux";
import pageCss from "components/styles/page.module.css";
import buttonCss from "components/styles/button.module.css";
import css from "./main.module.css";
import { TransactionsEditor } from "./transactions_editor";
import { Transaction, Split } from "model/bookkeeping";
import {
  accountsMapSelector,
  splitsByTransactionSelector,
  accountIsDebitFuncSelector,
} from "data/accounts/selectors";
import {
  filtersSelector,
  searchTermSelector,
  filteredTransactions,
} from "data/transactions/selectors";
import { Filter } from "model/filtering";
import {
  setFilter,
  removeFilter,
  setSearchTerm,
} from "data/transactions/actions";
import { TransactionMutations } from "data/accounts/types";
import { UUID } from "lib/core/uuid";

enum SidebarMode {
  ADD_TRANSACTION = "ADD_TRANSACTION",
  EDIT_TRANSACTION = "EDIT_TRANSACTION",
}

type State = {
  sidebarMode?: SidebarMode;
  selectedIds: { [id: string]: boolean };
};

export class TransactionsMain extends PureComponent<
  {
    transactions: Transaction[];
    splitsByTransaction: { [id: string]: Split[] };
    accountsMap: ReturnType<typeof accountsMapSelector>;
    accountIsDebitFunc(accountId: UUID): boolean;

    filters: Filter[];

    mutate(mutations: TransactionMutations): Promise<void>;
    addTransactions(
      transactions: Transaction[],
      splits: Split[]
    ): Promise<void>;
    setFilter: typeof setFilter;
    removeFilter: typeof removeFilter;
    setSearchTerm: typeof setSearchTerm;
    searchTerm: string;
  },
  State
> {
  state: State = {
    selectedIds: {},
  };

  closeSidebar = () => this.setState({ sidebarMode: undefined });

  renderSidebar() {
    if (!this.state.sidebarMode) {
      return null;
    }
    const { splitsByTransaction } = this.props;
    switch (this.state.sidebarMode) {
      case SidebarMode.ADD_TRANSACTION:
        return (
          <div
            className={cx(
              pageCss.block,
              css.mainRight,
              css.addTransactionWrapper
            )}
          >
            <h1>Add a Transaction</h1>
            <ConnectedTransactionCreator
              onSave={async (transaction, splits) => {
                await this.props.addTransactions([transaction], splits);
                this.closeSidebar();
              }}
            />
            <button
              onClick={this.closeSidebar}
              className={cx(css.addTransactionClose)}
            >
              Close
            </button>
          </div>
        );
      case SidebarMode.EDIT_TRANSACTION:
        const selectedTransactions = this.selectedTransactions();
        return (
          <div
            className={cx(
              pageCss.block,
              css.mainRight,
              css.addTransactionWrapper
            )}
          >
            <h1>
              Edit {selectedTransactions.length} Transaction
              {selectedTransactions.length > 1 ? "s" : ""}
            </h1>
            <TransactionsEditor
              transactions={selectedTransactions}
              splits={selectedTransactions.flatMap(
                (tx) => splitsByTransaction[tx.id] || []
              )}
              onSave={async (mutations) => {
                await this.props.mutate(mutations);
                this.closeSidebar();
              }}
              accountIsDebit={this.props.accountIsDebitFunc}
            />

            <button
              onClick={this.closeSidebar}
              className={cx(css.addTransactionClose)}
            >
              Close
            </button>
          </div>
        );
    }
    return null;
  }

  setSelectedIds = (selectedIds: { [id: string]: boolean }) => {
    this.setState({
      selectedIds,
    });
  };

  private selectedTransactions() {
    return this.props.transactions.filter(
      (tx) => this.state.selectedIds[tx.id]
    );
  }

  render() {
    return (
      <div className={pageCss.pageWrapper}>
        <div className={pageCss.pageTitleWrapper}>
          <h1 className={pageCss.pageTitle}>Transactions</h1>
        </div>
        <div className={css.main}>
          <div className={css.mainLeft}>
            <div className={cx(pageCss.block, css.actionsBlock)}>
              <button
                onClick={() =>
                  this.setState({ sidebarMode: SidebarMode.ADD_TRANSACTION })
                }
                className={cx(buttonCss.button, buttonCss.buttonBlue)}
              >
                Add Transaction
              </button>

              <button
                onClick={() =>
                  this.setState({ sidebarMode: SidebarMode.EDIT_TRANSACTION })
                }
                className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
              >
                Edit Transaction
              </button>

              <button
                onClick={() =>
                  window.confirm(
                    `Are you sure to delete ${
                      this.selectedTransactions().length
                    } transactions?`
                  ) &&
                  this.props.mutate({
                    newSplits: [],
                    newTransactions: [],
                    dirtySplits: {},
                    dirtyTransactions: {},
                    deletedTransactionIds: this.selectedTransactions().map(
                      (t) => t.id
                    ),
                  })
                }
                className={cx(buttonCss.button, buttonCss.buttonPurpleOutline)}
              >
                Delete Transaction
              </button>
            </div>
            <div className={cx(pageCss.block, css.transactionsBlock)}>
              <Transactions
                transactions={this.props.transactions}
                accountsMap={this.props.accountsMap}
                splitsByTransaction={this.props.splitsByTransaction}
                setSelectedIds={this.setSelectedIds}
                selectedIds={this.state.selectedIds}
                filters={this.props.filters}
                setFilter={this.props.setFilter}
                removeFilter={this.props.removeFilter}
                searchTerm={this.props.searchTerm}
                setSearchTerm={this.props.setSearchTerm}
              />
            </div>
          </div>
          {this.renderSidebar()}
        </div>
      </div>
    );
  }
}

export const ConnectedTransactionsMain = connect(
  (state: AppState) => ({
    transactions: filteredTransactions(state),
    accountsMap: accountsMapSelector(state),
    splitsByTransaction: splitsByTransactionSelector(state),
    filters: filtersSelector(state),
    searchTerm: searchTermSelector(state),
    accountIsDebitFunc: accountIsDebitFuncSelector(state),
  }),
  {
    mutate,
    addTransactions,
    setFilter,
    removeFilter,
    setSearchTerm,
  }
)(TransactionsMain);
