import React, { ReactElement } from "react";
import useResizeObserver from "use-resize-observer/polyfilled";

export const FlexibleContainer = React.memo<{
  height: number;
  children: (width: number, height: number) => ReactElement;
}>((props) => {
  const { height, children } = props;
  const { ref, width = 0 } = useResizeObserver();
  return (
    <div ref={ref} style={{ width: "100%", position: "relative", height }}>
      {width > 0 && (
        <div style={{ width, height, position: "absolute" }}>
          {children(width, height)}
        </div>
      )}
    </div>
  );
});

FlexibleContainer.displayName = "FlexibleContainer";
