import { ImportedEntry, Transaction, Split } from "model/bookkeeping";
import { UUID, newUuid } from "lib/core/uuid";
import { scaleValue, negate, unscaleValue } from "model/scaled_value";
import { evaluate } from "lib/math/expression";

export interface TextMatch {
  text: string;
  regex: boolean;
  caseSensitive: boolean;
}

interface ImporterAutomationCriteria {
  externalAccounts?: UUID[] | "any";
  memo?: TextMatch;
}

interface ImporterAutomationActions {
  markAsDuplicate?: boolean;
  setMemo?: string;
  setTransferAccount?: UUID;
  createMultiLeg?: {
    accountId: UUID;
    amountExpression?: string;
  }[];
  importImmediately?: boolean;
}

export interface ImporterAutomationRule {
  criteria: ImporterAutomationCriteria;
  actions: ImporterAutomationActions;
}

function textMatch(needle: TextMatch, haystack: string) {
  if (needle.regex) {
    return !!new RegExp(needle.text, needle.caseSensitive ? "" : "ui").exec(
      haystack
    );
  }
  if (needle.caseSensitive) {
    return haystack.includes(needle.text);
  } else {
    return haystack
      .toLocaleLowerCase()
      .includes(needle.text.toLocaleLowerCase());
  }
}

export function matchCriteria(
  entry: ImportedEntry,
  criteria: ImporterAutomationCriteria
): boolean {
  if (criteria.memo && !textMatch(criteria.memo, entry.memo)) {
    return false;
  }
  if (
    criteria.externalAccounts &&
    criteria.externalAccounts !== "any" &&
    !criteria.externalAccounts.includes(entry.externalAccountId)
  ) {
    return false;
  }

  return true;
}

export function transactionFromAction(
  entry: ImportedEntry,
  entryAccountId: UUID,
  actions: ImporterAutomationActions
): { transaction: Transaction; splits: Split[] } | null {
  const transaction: Transaction = {
    id: newUuid(),
    memo: actions.setMemo || entry.memo,
    datetime: entry.datetime,
    tags: {},
  };

  const splits: Split[] = [];
  if (actions.setTransferAccount) {
    splits.push(
      {
        id: newUuid(),
        transactionId: transaction.id,
        accountId: entryAccountId,
        datetime: entry.datetime,
        valueScaled: entry.valueScaled,
        tags: {},
      },
      {
        id: newUuid(),
        transactionId: transaction.id,
        accountId: actions.setTransferAccount,
        datetime: entry.datetime,
        valueScaled: negate(entry.valueScaled),
        tags: {},
      }
    );
    return { transaction, splits };
  }

  if (actions.createMultiLeg) {
    splits.push({
      id: newUuid(),
      transactionId: transaction.id,
      accountId: entryAccountId,
      datetime: entry.datetime,
      valueScaled: entry.valueScaled,
      tags: {},
    });

    const x = unscaleValue(entry.valueScaled);
    let runningSum = x;

    let remainderAccount = null;

    for (const leg of actions.createMultiLeg) {
      if (leg.amountExpression) {
        let val;
        if (leg.amountExpression.includes("x")) {
          val = evaluate(leg.amountExpression, { x });
        } else {
          val = +leg.amountExpression;
        }

        if (!Number.isFinite(val) || Number.isNaN(val)) {
          return null;
        }

        runningSum += val;

        splits.push({
          id: newUuid(),
          transactionId: transaction.id,
          accountId: leg.accountId,
          datetime: entry.datetime,
          valueScaled: scaleValue(val),
          tags: {},
        });
      } else {
        remainderAccount = leg.accountId;
      }
    }

    if (!remainderAccount) {
      return null;
    }

    splits.push({
      id: newUuid(),
      transactionId: transaction.id,
      accountId: remainderAccount,
      datetime: entry.datetime,
      valueScaled: scaleValue(-runningSum),
      tags: {},
    });

    return { transaction, splits };
  }

  return null;
}
