import { BillingPlan } from "static_data/billing";

export const AUTH_NS = "AUTH";

export type BMUser = {
  name: string | null;
  billingPlans: BillingPlan[];
};

export enum AuthStatus {
  UNKNOWN = "UNKNOWN",
  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",
}

export interface AuthState {
  status: AuthStatus;
  user?: BMUser;
  requireLogin: boolean;
  showLogin: boolean;
}
