import cx from "classnames";
import React, { useState } from "react";
import css from "./quick_add.module.css";
import buttonCss from "components/styles/button.module.css";
import { ConnectedTransactionCreator } from "components/common/transaction_creator";
import { addTransactions, addSubaccountHierarchy } from "data/accounts/actions";
import { connect } from "react-redux";
import { Transaction, Split } from "model/bookkeeping";

import { Modal, ModalSize } from "components/common/modal";
import {
  accountsReadySelector,
  accountsMapSelector
} from "data/accounts/selectors";
import { AppState } from "data/store";
import { ConnectedAccountSelector } from "components/common/account";
import { UUID } from "lib/core/uuid";

const CreateTransactionModal: React.FC<{
  addTransactions: (txs: Transaction[], splits: Split[]) => Promise<void>;
  onSave(): void;
}> = React.memo(props => {
  return (
    <div>
      <h1>Add a Transaction</h1>
      <ConnectedTransactionCreator
        onSave={async (transaction, splits) => {
          await props.addTransactions([transaction], splits);
          props.onSave();
        }}
      />
    </div>
  );
});

const ConnectedCreateTransactionModal = connect(() => ({}), {
  addTransactions
})(CreateTransactionModal);

const CreateAccountModal: React.FC<{
  addSubaccountHierarchy(parentName: string, childName: string): void;
  accountsMap: ReturnType<typeof accountsMapSelector>;
  onSave(): void;
}> = React.memo(props => {
  const [parentId, setParentId] = useState<UUID | undefined>(undefined);
  const [name, setName] = useState("");
  const account = parentId ? props.accountsMap.get(parentId) : undefined;
  return (
    <div>
      <h1>Add an account</h1>
      <table>
        <tbody>
          <tr>
            <td>
              <label htmlFor="quickadd-parentaccount">Parent Account</label>
            </td>
            <td>
              <ConnectedAccountSelector
                inputProps={{ id: "quickadd-parentaccount" }}
                id="quickadd-parentaccount"
                value={parentId}
                onChange={setParentId}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label htmlFor="quickadd-name">Name</label>
            </td>
            <td>
              <input
                id="quickadd-name"
                value={name}
                onChange={e => setName(e.currentTarget.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <button
        disabled={!name || !parentId || !account}
        onClick={() => {
          account && props.addSubaccountHierarchy(account.name, name);
          props.onSave();
        }}
      >
        Add
      </button>
    </div>
  );
});

const ConnectedCreateAccountModal = connect(
  (state: AppState) => ({ accountsMap: accountsMapSelector(state) }),
  {
    addSubaccountHierarchy
  }
)(CreateAccountModal);

export const QuickAdd: React.FC<{ isReady: boolean }> = React.memo(props => {
  const [isHidden, setIsHidden] = useState(true);

  const [isAddTxOpen, setIsAddTxOpen] = useState(false);
  const [isAddAccountOpen, setIsAddAccountOpen] = useState(false);

  if (!props.isReady) {
    return null;
  }

  return (
    <div className={css.container}>
      <Modal
        size={ModalSize.SMALL}
        isOpen={isAddTxOpen}
        onRequestClose={() => setIsAddTxOpen(false)}
      >
        <ConnectedCreateTransactionModal onSave={() => setIsAddTxOpen(false)} />
      </Modal>
      <Modal
        size={ModalSize.SMALL}
        isOpen={isAddAccountOpen}
        onRequestClose={() => setIsAddAccountOpen(false)}
      >
        <ConnectedCreateAccountModal
          onSave={() => setIsAddAccountOpen(false)}
        />
      </Modal>
      <div className={cx(css.wrapper, { [css.hidden]: isHidden })}>
        <div className={css.add}>
          <button
            className={cx(buttonCss.styleless, css.addButton)}
            onClick={() => setIsHidden(!isHidden)}
          >
            +
          </button>
        </div>
        <div className={css.main}>
          <ul className={cx(css.menuItems)}>
            <li>
              <button onClick={() => setIsAddTxOpen(true)}>
                Add transaction
              </button>
            </li>
            <li>
              <button onClick={() => setIsAddAccountOpen(true)}>
                Add account
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export const ConnectedQuickAdd = connect((state: AppState) => ({
  isReady: accountsReadySelector(state)
}))(QuickAdd);
