import cx from "classnames";
import React, { PureComponent, ComponentProps } from "react";
import ReactModal from "react-modal";

import css from "./modal.module.css";

export enum ModalSize {
  SMALL = "SMALL",
  LARGE = "LARGE"
}

const SIZE_TO_CLASS = {
  [ModalSize.LARGE]: cx(css.content, css.large),
  [ModalSize.SMALL]: cx(css.content, css.small)
};

export class Modal extends PureComponent<
  ComponentProps<typeof ReactModal> & { size: ModalSize }
> {
  static defaultProps = {
    size: ModalSize.LARGE
  };

  render() {
    return (
      <ReactModal
        overlayClassName={css.overlay}
        className={SIZE_TO_CLASS[this.props.size]}
        {...this.props}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}
