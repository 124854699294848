import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Transaction, Split } from "../../model/bookkeeping";
import { accountsMapSelector } from "../../data/accounts/selectors";
import { formatDebitCredit } from "../../model/scaled_value";
import { DateTime } from "luxon";
import css from "./transactions.module.css";
import buttonCss from "components/styles/button.module.css";
import { dateToNumber } from "lib/datetime/date";
import { Pager } from "components/common/pagination";
import { FilterList } from "components/common/filtering/filter_list";
import { Filter } from "model/filtering";
import { setFilter, setSearchTerm } from "data/transactions/actions";
import { removeFilter } from "data/transactions/actions";

const PER_PAGE = 20;

export const FilterBar = React.memo<{
  searchTerm: string;
  filters: Filter[];
  setFilter: typeof setFilter;
  removeFilter: typeof removeFilter;
  setSearchTerm: typeof setSearchTerm;
}>(props => {
  const { filters, setFilter, removeFilter, setSearchTerm } = props;
  return (
    <div className={css.filtersBar}>
      {filters.length > 0 ? (
        <div>
          <FilterList
            filters={filters}
            setFilter={setFilter}
            removeFilter={removeFilter}
          />
        </div>
      ) : (
        <>
          <div className={css.searchBoxWrapper}>
            <input
              type="search"
              value={props.searchTerm}
              onChange={e => setSearchTerm(e.currentTarget.value)}
              placeholder="Search for transactions"
            />
          </div>
          <button
            className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
            onClick={() => setFilter(0, {})}
          >
            Advanced Search
          </button>
        </>
      )}
    </div>
  );
});

export const Transactions: React.FC<{
  transactions: Transaction[];
  splitsByTransaction: { [id: string]: Split[] };
  accountsMap: ReturnType<typeof accountsMapSelector>;
  selectedIds: { [id: string]: boolean };
  setSelectedIds(ids: { [id: string]: boolean }): void;

  searchTerm: string;
  filters: Filter[];
  setFilter: typeof setFilter;
  removeFilter: typeof removeFilter;
  setSearchTerm: typeof setSearchTerm;
}> = props => {
  const {
    transactions,
    splitsByTransaction,
    accountsMap,
    selectedIds,
    setSelectedIds,
    filters,
    setFilter,
    removeFilter,
    searchTerm,
    setSearchTerm
  } = props;

  const [curPage, setCurPage] = useState(1);

  const maxPage = Math.ceil(transactions.length / PER_PAGE);
  const showingTransactions = transactions.slice(
    (curPage - 1) * PER_PAGE,
    curPage * PER_PAGE
  );

  useEffect(() => {
    const listener = () => {
      const id = window.location.hash.substring(1);
      if (id && transactions.length > 0) {
        const idx = transactions.findIndex(v => v.id === id);

        if (idx >= 0) {
          setCurPage(Math.floor(idx / PER_PAGE) + 1);
          window.setTimeout(() => {
            const el = document.getElementById(id);
            if (el) {
              el.scrollIntoView({ block: "center" });

              el.classList.add(css.transactionFlash);
            }
          });
        }
      }
    };

    window.addEventListener("hashchange", listener);

    return () => {
      window.removeEventListener("hashchange", listener);
    };
  });

  return (
    <div>
      <FilterBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        filters={filters}
        setFilter={setFilter}
        removeFilter={removeFilter}
      />
      {transactions.length === 0 && (
        <div className={css.empty}>No transactions found</div>
      )}
      {showingTransactions.map((tx, i) => {
        let date = undefined;
        if (
          i === 0 ||
          dateToNumber(tx.datetime.datetime) !==
            dateToNumber(transactions[i - 1].datetime.datetime)
        ) {
          date = tx.datetime.datetime.toLocaleString(DateTime.DATE_MED);
        }

        return (
          <div id={tx.id} key={tx.id} className={css.transactionLine}>
            <div className={date ? css.dateBox : css.emptyDateBox}>{date}</div>
            <div className={css.transactionContent}>
              <div className={css.checkboxBox}>
                <input
                  type="checkbox"
                  checked={!!selectedIds[tx.id]}
                  onChange={e =>
                    setSelectedIds({
                      ...selectedIds,
                      [tx.id]: e.currentTarget.checked
                    })
                  }
                />
              </div>
              <div className={css.splitsBox}>
                <p>{tx.memo}</p>
                <table>
                  <tbody>
                    {(splitsByTransaction[tx.id] || []).map(split => {
                      const [dr, cr] = formatDebitCredit(split.valueScaled);
                      return (
                        <tr key={split.id}>
                          <td>
                            {
                              (accountsMap.get(split.accountId) || { name: "" })
                                .name
                            }
                          </td>
                          <td className={css.amountCell}>{dr}</td>
                          <td className={css.amountCell}>{cr}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      })}

      {maxPage > 0 && (
        <Pager page={curPage} maxPage={maxPage} onChange={setCurPage} />
      )}
    </div>
  );
};
