export enum EntryCategory {
  ADJUSTMENT = 0,
  ADVERTISING = 1,
  AIRFARE = 2,
  ALLOWANCE = 3,
  AMUSEMENT_PARK = 4,
  ATM_FEE = 5,
  ATM_WITHDRAWAL = 6,
  AUTO = 7,
  AUTO_INSURANCE = 8,
  AUTO_MAINTENANCE = 9,
  AUTO_PAYMENT = 10,
  BABY_SUPPLIES = 11,
  BABYSITTER = 12,
  BANK_ACCOUNT_FEES = 13,
  BAR = 14,
  BENEFIT_INCOME = 15,
  BONUS_INCOME = 16,
  BOOKS = 17,
  CHARITY = 18,
  CHECK = 19,
  CHILD_ACTIVITIES = 20,
  CINEMA = 21,
  CLOTHING = 22,
  COFFEE_TEA_SHOP = 23,
  CREDIT_CARD_FEES = 24,
  CULTURAL_ACTIVITIES = 25,
  DAYCARE = 26,
  DENTIST = 27,
  DIVIDEND_INCOME = 28,
  DOCTOR = 29,
  EDUCATION_BOOKS_SUPPLIES = 30,
  ELECTRONICS = 31,
  EYECARE = 32,
  FAST_FOOD = 33,
  FEDERAL_TAX = 34,
  FINANCIAL_ADVISOR = 35,
  FOOD = 36,
  FOOD_DELIVERY = 37,
  FURNITURE = 38,
  GARDEN = 39,
  GAS = 40,
  GIFT = 41,
  GROCERY = 42,
  GYM = 43,
  HEALTH_INSURANCE = 44,
  HOBBY = 45,
  HOME_IMPROVEMENT = 46,
  HOME_SERVICES = 47,
  HOMEOWNER_INSURANCE = 48,
  HOTEL = 49,
  HOUSEHOLD_ITEMS = 50,
  INTEREST = 51,
  INTEREST_INCOME = 52,
  INTERNET = 53,
  LANDLINE = 54,
  LATE_FEE = 55,
  LAUNDRY = 56,
  LEGAL = 57,
  LIFE_INSURANCE = 58,
  LOCAL_TAX = 59,
  MAGAZINE = 60,
  MASSAGE = 61,
  MOBILE_PHONE = 62,
  MUSIC = 63,
  NEWSPAPER = 64,
  OFFICE_SUPPLIES = 65,
  ONLINE_SERVICES = 66,
  PARKING = 67,
  PET_FOOD = 68,
  PET_GROOMING = 69,
  PHARMACY = 70,
  PROFESSIONAL_DEVELOPMENT = 71,
  PROPERTY_TAX = 72,
  PUBLIC_TRANSIT = 73,
  REGULAR_PAY = 74,
  RENT = 75,
  RENTAL_CAR = 76,
  RENTERS_INSURANCE = 77,
  RESTAURANT = 78,
  SALES_TAX = 79,
  SALON = 80,
  SHIPPING = 81,
  SHOPPING = 82,
  SPORTS = 83,
  STATE_TAX = 84,
  STOCK_GRANT = 85,
  STREAMING_SUBSCRIPTION = 86,
  STUDENT_LOAN = 87,
  TAXI = 88,
  TELEVISION = 89,
  TOYS = 90,
  TRADE_COMMISSION = 91,
  TRANSFER = 92,
  TRAVEL = 93,
  TUITION = 94,
  UTILITIES_ELECTRICITY = 95,
  UTILITIES_GAS = 96,
  UTILITIES_WATER = 97,
  VACATION = 98,
  VETERINARY = 99,
  PROFESSIONAL_SERVICE = 100,
  INSURANCE = 101,
  GOVERNMENT = 102,
  COMMUNITY = 103,
  ENTERTAINMENT = 104,
  PERSONAL_CARE = 105,
  ROAD_TOLL = 106,
  TAX = 107
}
