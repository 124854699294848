import React, { createRef } from "react";
import cx from "classnames";
import pageCss from "components/styles/page.module.css";
import buttonCss from "components/styles/button.module.css";
import css from "./main.module.css";
import { getPersistence } from "persistence/factory";
import { saveAs } from "file-saver";
import { DateTime } from "luxon";
import { readFile } from "utils/browser_file";

async function exportData() {
  const json = JSON.stringify(await getPersistence().backup());
  saveAs(
    new Blob([json], { type: "application/json" }),
    `Ducount-${DateTime.local().toISODate()}.json`
  );
}

export const SettingsMain: React.FC<{}> = () => {
  const restoreInputRef = createRef<HTMLInputElement>();

  const restoreData = async () => {
    const el = restoreInputRef.current;
    if (el && el.files && el.files.length > 0) {
      const json = JSON.parse(await readFile(el.files[0]));
      await getPersistence().restore(json);
      window.location.href = "/";
    }
  };
  return (
    <div className={pageCss.pageWrapper}>
      <div className={pageCss.pageTitleWrapper}>
        <h1 className={pageCss.pageTitle}>Settings</h1>
      </div>
      <div className={cx(pageCss.block, css.settingsBlock)}>
        <h2>Export</h2>
        <div>
          You can export all your transaction data, for backup, or to import to
          another software.
        </div>
        <button
          onClick={exportData}
          className={cx(buttonCss.button, buttonCss.buttonBlue)}
        >
          Export your data
        </button>
      </div>
      <div className={cx(pageCss.block, css.settingsBlock)}>
        <h2>Restore</h2>
        <div>
          <p>You can restore backup previously exported.</p>
          <p>
            <em>WARNING:</em> all your existing data will be deleted! It is
            strongly advised that you back up your data first.
          </p>
        </div>
        <input type="file" ref={restoreInputRef} />
        <p>
          <button
            onClick={restoreData}
            className={cx(buttonCss.button, buttonCss.buttonBlue)}
          >
            Restore Data
          </button>
        </p>
      </div>
    </div>
  );
};
