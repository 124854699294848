import React, { useEffect, useState } from "react";
import { getPersistence } from "persistence/factory";
import { connect } from "react-redux";
import { loadData } from "data/importer/actions";
import css from "./change_listener.module.css";

const ChangeNotifier: React.FC<{ loadData(): Promise<void> }> = props => {
  const [dbChanged, setDbChanged] = useState(false);
  useEffect(() => {
    const listener = () => setDbChanged(true);

    getPersistence().addOnRemoteChangeListener(listener);

    return () => {
      getPersistence().removeOnRemoteChangeListener(listener);
    };
  });

  const onClick = async () => {
    await props.loadData();
    setDbChanged(false);
  };

  if (dbChanged) {
    return (
      <div className={css.topbar}>
        <button onClick={onClick}>
          Data has been modified. Click to refresh.
        </button>
      </div>
    );
  }
  return null;
};

export const ConnectedChangeNotifier = connect(() => ({}), {
  loadData: loadData
})(ChangeNotifier);
