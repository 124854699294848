export class Service {
  readonly server: string;
  constructor(server: string) {
    this.server = server;
  }
}

// eslint-disable-next-line
export class Route<I, O> {
  readonly endpoint: string;
  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }
}

export type RouteResponseType<T> = T extends Route<any, infer U> ? U : never;

export type Handler<I, O, ContextT> = {
  readonly route: Route<I, O>;
  readonly handler: (input: I, context: ContextT) => Promise<O>;
};

type ClientMiddleware = () => { headers?: { [h: string]: string } };

export class ServiceClient {
  private service: Service;
  private middlewares: ClientMiddleware[] = [];
  constructor(service: Service) {
    this.service = service;
  }

  addMiddleware(mw: ClientMiddleware) {
    this.middlewares.push(mw);
  }

  async request<I, O>(route: Route<I, O>, input: I): Promise<O> {
    const headers = {
      "Content-Type": "application/json"
    };

    for (const mw of this.middlewares) {
      const result = mw();
      if (result.headers) {
        Object.assign(headers, result.headers);
      }
    }

    const resp = await fetch(this.service.server + route.endpoint, {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify(input)
    });
    return await resp.json();
  }
}
