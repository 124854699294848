import React, { PureComponent } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { ExternalAccount, Account, Split } from "../../model/bookkeeping";
import {
  setOtherAccount,
  markEntriesImported,
  setEntrySelectedStatus,
  autoCategorize,
  addBalancingTransaction,
  setShowImportedEntries,
  clearTransaction,
} from "../../data/importer/actions";
import { Dispatch, AppState } from "../../data/store";
import {
  importerNsSelector,
  candidateTransactionsSelector,
  entryDataByIdSelector,
  displayingExternalAccountDataListSelector,
} from "../../data/importer/selectors";
import {
  ExternalAccountData,
  entryIsSaved,
  entryIsCandidate,
  Entry,
  CandidateTransaction,
} from "../../data/importer/types";
import { sum, ScaledValue, format } from "../../model/scaled_value";
import { UUID } from "../../lib/core/uuid";
import {
  accountByExternalIdSelector,
  balanceSelector,
  accountByImportTypeSelector,
} from "../../data/accounts/selectors";
import { EXTERNAL_ACCOUNT_IDS } from "../../data/accounts/tags";
import {
  setImportToAccount,
  addTransactions,
} from "../../data/accounts/actions";
import map from "lodash/map";
import forEach from "lodash/forEach";
import { AccountDisplay, ConnectedAccountSelector } from "../common/account";
import { ProbabilityIndicator } from "./widgets/probability_indicator";
import {} from "data/importer/actions";
import { ReactComponent as CloseIcon } from "../../assets/font-awesome-solid/times-circle.svg";

import css from "./importer.module.css";
import commonCss from "./common.module.css";
import pageCss from "components/styles/page.module.css";
import buttonCss from "components/styles/button.module.css";
import { ImportToAccountOptions } from "./widgets/import_to_account";
import { ExternalAccountProvider } from "lib/ingestion/types";
import { IS_DUPLICATE } from "data/importer/tags";
import { Checkbox } from "components/common/checkbox";
import { ConnectedSettings, ConnectedHiddenAccountToggle } from "./settings";

interface ImporterDerivedProps {
  externalAccountData: ExternalAccountData[];
  candidateTransactionById: ReturnType<typeof candidateTransactionsSelector>;
  showImportedEntries: boolean;
}

interface DispatchProps {
  dispatch: Dispatch;
}

function formatExternalAccountProvider(
  provider: ExternalAccountProvider
): string {
  switch (provider) {
    case ExternalAccountProvider.CSV:
      return "CSV";
    case ExternalAccountProvider.OFX:
      return "OFX";
    case ExternalAccountProvider.PLAID:
      return "LINKED";
  }
}

type EntryLineProps = {
  account: Account | undefined;
  entryData: Entry;
  isSelected: boolean;
  candidateTransaction?: CandidateTransaction;
  onSetOtherAccount(entry: Entry, accountId: UUID): (accountId: string) => void;
  setEntrySelectedStatus(id: string, status: boolean): void;
  onClearTransaction(e: Entry): void;
};

const EntryLine = React.memo<EntryLineProps>((props) => {
  const {
    account,
    entryData,
    isSelected,
    candidateTransaction,
    onSetOtherAccount,
    onClearTransaction,
  } = props;

  let accountSelector;
  let isSaved = false;
  const entry = entryData.entry;

  if (account) {
    if (entryIsSaved(entryData)) {
      accountSelector = null;
      isSaved = true;
    } else {
      const accountFilter = (a: Account) => !a.tags[EXTERNAL_ACCOUNT_IDS];
      if (candidateTransaction) {
        if (candidateTransaction.splits.length > 2) {
          accountSelector = <div>Multileg</div>;
        } else {
          let id = undefined;
          for (const split of candidateTransaction.splits) {
            if (split.accountId !== account.id) {
              id = split.accountId;
              break;
            }
          }
          accountSelector = (
            <ConnectedAccountSelector
              value={id}
              onChange={onSetOtherAccount(entryData, account.id)}
              accountsFilter={accountFilter}
              hidePlaceholders={true}
            />
          );
        }
      } else {
        accountSelector = (
          <ConnectedAccountSelector
            onChange={onSetOtherAccount(entryData, account.id)}
            accountsFilter={accountFilter}
            hidePlaceholders={true}
          />
        );
      }
    }
  } else {
    accountSelector = null;
  }

  return (
    <tr
      key={entry.id}
      style={{
        textDecoration: isSaved ? "line-through" : "none",
      }}
    >
      <td className={css.checkboxCell}>
        {entryIsSaved(entryData) ? (
          <input key="disabled" type="checkbox" disabled />
        ) : account ? (
          <Checkbox
            type="checkbox"
            checked={isSelected}
            onChange={(e) =>
              props.setEntrySelectedStatus(entry.id, e.currentTarget.checked)
            }
          />
        ) : null}
      </td>
      <td>{entry.memo}</td>
      <td className={css.dateCell}>{entry.datetime.datetime.toFormat("D")}</td>
      <td className={css.otherAccountSelectorCell}>
        <div className={css.otherAccountSelectorInner}>
          {accountSelector}
          {entryData.autoCategorizeP && (
            <ProbabilityIndicator p={entryData.autoCategorizeP} />
          )}
          {candidateTransaction && (
            <button
              className={css.clearTransactionButton}
              onClick={() => onClearTransaction(entryData)}
            >
              <CloseIcon width={16} height={16} />
            </button>
          )}
        </div>
      </td>
      <td className={css.amountCell}>{format(entry.valueScaled)}</td>
    </tr>
  );
});

EntryLine.displayName = "EntryLine";

interface ExternalAccountImporterDerivedProps {
  externalAccountData: ExternalAccountData;

  accountByExternalId: ReturnType<typeof accountByExternalIdSelector>;
  candidateTransactionById: ReturnType<typeof candidateTransactionsSelector>;
  balanceById: ReturnType<typeof balanceSelector>;
  accountByImportType: ReturnType<typeof accountByImportTypeSelector>;
  selectedEntries: { [id: string]: boolean };
  entryDataById: ReturnType<typeof entryDataByIdSelector>;
  showImportedEntries: boolean;
}

class ExternalAccountImporter extends PureComponent<
  ExternalAccountImporterDerivedProps & DispatchProps
> {
  onSetOtherAccount = (entry: Entry, thisAccountId: UUID) => (
    accountId: UUID
  ) => {
    this.props.dispatch(setOtherAccount(entry, thisAccountId, accountId));
  };

  setEntrySelectedStatus = (id: UUID, status: boolean) => {
    this.props.dispatch(setEntrySelectedStatus(id, status));
  };

  onClearTransaction = (e: Entry) => this.props.dispatch(clearTransaction(e));

  renderOpeningBalanceCreator(
    account: Account | undefined,
    unsavedSum: ScaledValue,
    balance: ScaledValue | undefined,
    extAccount: ExternalAccount,
    balancingTransaction?: UUID
  ) {
    if (balance === undefined) {
      return null;
    }
    if (balancingTransaction) {
      return <div>Balancing Transaction Created</div>;
    }
    if (
      account &&
      balance !== this.props.balanceById(account.id) + unsavedSum
    ) {
      return (
        <div className={css.mismatchBalance}>
          <h4 className={css.mismatchBalanceTitle}>
            Account Balance Does Not Match
          </h4>
          <div>Imported data indicate balance: {format(balance)}</div>
          <div>
            <p>
              After importing all entries above, account{" "}
              <AccountDisplay accountName={account.name} /> has balance:{" "}
              {format(
                (this.props.balanceById(account.id) + unsavedSum) as ScaledValue
              )}
            </p>
          </div>
          <div>
            <h4 className={css.mismatchBalanceTitle}>Resolution</h4>
            <ul>
              <li>
                If this is the first time you import this account, you need an
                opening balance entry to sum up all entries not imported. After
                importing all entries you need, click{" "}
                <button
                  className={buttonCss.link}
                  onClick={() =>
                    this.props.dispatch(
                      addBalancingTransaction(
                        extAccount,
                        account,
                        (balance -
                          unsavedSum -
                          this.props.balanceById(account.id)) as ScaledValue
                      )
                    )
                  }
                >
                  here
                </button>{" "}
                to create balancing entry.
              </li>
              <li>
                If you entered transactions manually into{" "}
                <AccountDisplay accountName={account.name} />, you can reconcile
                imported entries with your own entry in the Reconcile tab above.
              </li>
              <li>
                Some entries above may be duplicate. Select the duplicate
                entries, and mark them as duplicate.
              </li>
              <li>
                There may be entries missing. Check if there is any time gap,
                and import those as necessary.
              </li>
              <li>
                When importing data, please make sure to do so in chronological
                order (earlier entries first), so the balance is up to date. To
                fix, import the latest entries again.
              </li>
              <li>
                If the balance indicated by imported data ({format(balance)}) is
                wrong (due to uncleared transactions, closed account, or
                partially frozen balance), please ignore this warning.
              </li>
            </ul>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      account: extAccount,
      entryIds,
      balancingTransaction,
    } = this.props.externalAccountData;

    const entryDataList = entryIds
      .map((id) => this.props.entryDataById[id])
      .filter((entry) => !+entry.entry.tags[IS_DUPLICATE]);
    const accnt = this.props.accountByExternalId(extAccount.externalId);
    const unsavedSum = sum(
      ...entryDataList
        .filter((entry) => !entryIsSaved(entry))
        .map((entryData) => entryData.entry.valueScaled)
    );
    const balance = extAccount.balanceScaled;

    let newAccountName = undefined;
    if (extAccount.type) {
      const accountCategory = this.props.accountByImportType(extAccount.type);
      if (accountCategory) {
        newAccountName = accountCategory.name + ":" + extAccount.name;
      }
    }

    const displayingEntryDataList = entryDataList.filter(
      (e) => !e.entry.splitId || this.props.showImportedEntries
    );

    if (
      displayingEntryDataList.length > 0 ||
      balancingTransaction ||
      (accnt && balance !== this.props.balanceById(accnt.id) + unsavedSum)
    ) {
      return (
        <div
          key={extAccount.id}
          className={cx(commonCss.account, pageCss.block)}
        >
          <div
            className={cx({
              [commonCss.accountUnselected]: !accnt,
            })}
          >
            <h3 className={css.accountHeader}>
              <span className={css.accountName}>{extAccount.name}</span>
              <span className={css.accountProvider}>
                {formatExternalAccountProvider(extAccount.provider)}
              </span>
            </h3>
            {accnt && <div>Import to: {accnt.name}</div>}
            {displayingEntryDataList.length > 0 && (
              <table className={css.entryTable}>
                <tbody>
                  {displayingEntryDataList.map((e) => (
                    <EntryLine
                      key={e.entry.id}
                      account={accnt}
                      entryData={e}
                      isSelected={!!this.props.selectedEntries[e.entry.id]}
                      candidateTransaction={
                        entryIsCandidate(e)
                          ? this.props.candidateTransactionById[
                              e.candidateTransactionId
                            ]
                          : undefined
                      }
                      onSetOtherAccount={this.onSetOtherAccount}
                      setEntrySelectedStatus={this.setEntrySelectedStatus}
                      onClearTransaction={this.onClearTransaction}
                    />
                  ))}
                </tbody>
                {balance !== undefined && (
                  <tfoot>
                    <tr>
                      <td className={css.checkboxCell} />
                      <td>Balance</td>
                      <td className={css.dateCell} />
                      <td className={css.otherAccountSelectorCell} />
                      <td className={css.amountCell}>{format(balance)}</td>
                    </tr>
                  </tfoot>
                )}
              </table>
            )}
            {this.renderOpeningBalanceCreator(
              accnt,
              unsavedSum,
              balance,
              extAccount,
              balancingTransaction
            )}
            <ConnectedSettings extAccount={extAccount} />
          </div>
          {!accnt && (
            <ImportToAccountOptions
              externalAccount={extAccount}
              onSetAccount={(accountId) =>
                this.props.dispatch(setImportToAccount(extAccount, accountId))
              }
              newAccountTags={{
                [EXTERNAL_ACCOUNT_IDS]: extAccount.externalId,
              }}
              defaultValue={newAccountName}
            />
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export const ConnectedExternalAccountImporter = connect(
  (state: AppState, ownProps: {}) => ({
    accountByExternalId: accountByExternalIdSelector(state),
    candidateTransactionById: candidateTransactionsSelector(state),
    balanceById: balanceSelector(state),
    accountByImportType: accountByImportTypeSelector(state),
    selectedEntries: importerNsSelector(state).selectedEntries,
    entryDataById: entryDataByIdSelector(state),
    showImportedEntries: !!importerNsSelector(state).showImportedEntries,
  })
)(ExternalAccountImporter);

class Importer extends PureComponent<ImporterDerivedProps & DispatchProps, {}> {
  onSaveTransactions = async () => {
    const transactions = map(
      this.props.candidateTransactionById,
      (v) => v.transaction
    );
    const splits: Split[] = [];
    forEach(this.props.candidateTransactionById, (v) => {
      splits.push(...v.splits);
    });

    await this.props.dispatch(addTransactions(transactions, splits));
    this.props.dispatch(
      markEntriesImported(
        transactions.map((t) => t.id),
        splits.map((t) => t.id)
      )
    );
  };

  autoCategorize = () => {
    this.props.dispatch(
      autoCategorize(
        this.props.externalAccountData.map(({ account }) => account)
      )
    );
  };

  render() {
    return (
      <div>
        <div className={cx(pageCss.block, commonCss.actions)}>
          <ul className={commonCss.actionList}>
            <li>
              <button
                onClick={this.onSaveTransactions}
                className={cx(buttonCss.button, buttonCss.buttonBlue)}
              >
                Save
              </button>
            </li>
            <li>
              <button
                onClick={this.autoCategorize}
                className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
              >
                Auto Categorize
              </button>
            </li>
            <li>
              <label>
                <input
                  type="checkbox"
                  checked={this.props.showImportedEntries}
                  onChange={(e) =>
                    this.props.dispatch(
                      setShowImportedEntries(e.currentTarget.checked)
                    )
                  }
                />
                Show Imported Entries
              </label>
            </li>
          </ul>
        </div>
        {this.props.externalAccountData.map((externalAccountData) => (
          <ConnectedExternalAccountImporter
            key={externalAccountData.account.id}
            externalAccountData={externalAccountData}
          />
        ))}
        <ConnectedHiddenAccountToggle />
      </div>
    );
  }
}

export const ConnectedImporter = connect((state: AppState, ownProps: {}) => ({
  externalAccountData: displayingExternalAccountDataListSelector(state),
  candidateTransactionById: candidateTransactionsSelector(state),
  showImportedEntries: !!importerNsSelector(state).showImportedEntries,
}))(Importer);
