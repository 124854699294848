import { ThunkDispatch } from "redux-thunk";
import * as path from "path";

import memoize from "lodash/memoize";
import { UUID, newUuid } from "../../lib/core/uuid";
import { getPersistence } from "../../persistence/factory";
import * as Actions from "./action_creators";
import { AppState } from "../store";
import {
  importerNsSelector,
  externalAccountDataSelector,
  externalIdSetInAccountSelector,
  entryDataByIdSelector,
  transactionByEntryIdSelector,
  externalAccountByIdSelector,
} from "./selectors";
import forEach from "lodash/forEach";
import sortBy from "lodash/sortBy";
import { entryIsCandidate, entryIsSaved } from "./types";
import { ExternalAccount, ImportedEntry, Split } from "../../model/bookkeeping";
import {
  accountByEntryCategorySelector,
  accountByExternalIdSelector,
} from "../accounts/selectors";
import { EntryCategory } from "../../static_data/categories";
import {
  matchTransferEntries,
  matchEntryAndSplit,
} from "../../lib/importing/matching";
import { IngestionFormats, formatFromExtension } from "./constants";
import { batch } from "react-redux";
import { client } from "api/cloud_client";
import { autoCategorizeRoute } from "api/cloud";
import { readFile } from "utils/browser_file";
import { Action } from "redux";
import { plaidLinkItemRoute } from "api/plaid";
import { linkingListRoute } from "api/linking";
import { IS_HIDDEN, IS_DUPLICATE } from "./tags";
import {
  automationEntriesSelector,
  automationRuleSelector,
} from "data/importer/selectors";
import { transactionFromAction } from "lib/importing/automation";

type Dispatch = ThunkDispatch<AppState, {}, Action>;

const getIngestionFunc = memoize(async (format: IngestionFormats) => {
  switch (format) {
    case IngestionFormats.OFX:
      return (await import("../../lib/ingestion/ofx")).importEntries;
  }
});

export function parseExternalFiles(files: File[]) {
  return async (dispatch: Dispatch) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const ext = formatFromExtension(path.extname(file.name));

      if (ext) {
        let ingestionFunc = await getIngestionFunc(ext);

        const data = await readFile(file);
        const entires = await ingestionFunc(data);
        for (const [extAccount, ents] of entires) {
          await dispatch(addExternalAccountAndEntries(extAccount, ents));
        }
      }
    }
  };
}

export function addExternalAccountAndEntries(
  extAccount: ExternalAccount,
  ents: ImportedEntry[]
) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const accountsData: [ExternalAccount, ImportedEntry[]][] = [];
    const updatedBalances = [];

    const extAccountData = externalAccountDataSelector(getState())(
      extAccount.externalId
    );
    if (extAccountData) {
      const existingIds = externalIdSetInAccountSelector(getState())(
        extAccount.externalId
      );
      const updatedEnts = ents
        .filter((ent) => !existingIds.has(ent.externalId))
        .map((entry) => ({
          ...entry,
          externalAccountId: extAccountData.account.id,
        }));
      accountsData.push([extAccountData.account, updatedEnts]);
      updatedBalances.push({
        externalId: extAccount.externalId,
        balanceScaled: extAccount.balanceScaled,
      });

      await getPersistence().addExternalAccountsAndEntries(
        undefined,
        updatedEnts
      );

      await getPersistence().updateExternalAccount({
        id: extAccountData.account.id,
        balanceScaled: extAccount.balanceScaled,
      });
    } else {
      accountsData.push([extAccount, ents]);

      await getPersistence().addExternalAccountsAndEntries(extAccount, ents);
    }

    dispatch(Actions.importerFilesParsed(accountsData));
    dispatch(Actions.updateExternalAccounts(updatedBalances));
  };
}

export function importCSV(file: File) {
  return async (dispatch: Dispatch) => {
    const data = await readFile(file);
    const parser = (await import("lib/ingestion/csv")).parseCsv;
    const csv = await parser(data);
    await dispatch(Actions.importCSV(csv));
  };
}

export function markEntriesImported(transactionIds: UUID[], splitIds: UUID[]) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const mapping = new Map<UUID, UUID>();
    forEach(importerNsSelector(getState()).entries, (entry) => {
      if (
        entryIsCandidate(entry) &&
        splitIds.includes(entry.candidateSplitId)
      ) {
        mapping.set(entry.entry.id, entry.candidateSplitId);
      }
    });
    await getPersistence().updateImportedEntrySplitIds(mapping);
    batch(() => {
      mapping.forEach((_, id) =>
        dispatch(Actions.setEntrySelectedStatus(id, false))
      );
      dispatch(Actions.markEntriesImported(transactionIds, splitIds));
    });
  };
}

export function markEntriesReconciled(mapping: Map<UUID, UUID>) {
  return async (dispatch: Dispatch) => {
    getPersistence().updateImportedEntrySplitIds(mapping);
    dispatch(Actions.markEntriesReconciled(Array.from(mapping.keys())));
  };
}

export function loadData() {
  return async (dispatch: Dispatch) => {
    const {
      importedEntries,
      externalAccounts,
    } = await getPersistence().getImporterData();

    const sortedImportedEntries = sortBy(importedEntries, (e) =>
      e.datetime.datetime.toMillis()
    );

    dispatch(
      Actions.setAccountsAndEntries(externalAccounts, sortedImportedEntries)
    );

    dispatch(Actions.importerReady());
  };
}

export function loadLinkedAccount() {
  return async (dispatch: Dispatch) => {
    if (process.env.REACT_APP_PERSIST === "cloud") {
      const response = await client.request(linkingListRoute, {});

      dispatch(Actions.setLinkedInstitutions(response));
    }
  };
}

export function autoCategorize(externalAccounts: ExternalAccount[]) {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const thisAccountFunc = accountByExternalIdSelector(getState());
    const accountExternalIds = new Set(
      externalAccounts.map((acc) => acc.externalId)
    );
    const entriesToSend = Object.values(
      entryDataByIdSelector(getState())
    ).filter(
      (entryData) =>
        accountExternalIds.has(entryData.accountExternalId) &&
        !entryIsSaved(entryData) &&
        !entryIsCandidate(entryData) &&
        !+entryData.entry.tags[IS_DUPLICATE] &&
        thisAccountFunc(entryData.accountExternalId)
    );

    const allData = entriesToSend.map((entry) => ({
      memo: entry.entry.memo,
      raw: entry.entry.raw,
    }));

    const respJson = await client.request(autoCategorizeRoute, allData);

    const accountByEntryCategory = accountByEntryCategorySelector(getState());

    const transferEntries: ImportedEntry[] = [];
    const accountIdMap = new Map<UUID, UUID>();

    batch(() => {
      for (let i = 0; i < respJson.length; i++) {
        const result = respJson[i];
        const entryData = entriesToSend[i];
        const thisAccount = thisAccountFunc(entryData.accountExternalId)!;
        if (result.p === undefined || result.p > 0.4) {
          // Handle special category
          if (result.category === EntryCategory.TRANSFER) {
            transferEntries.push(entryData.entry);
            accountIdMap.set(entryData.entry.id, thisAccount.id);
          } else {
            const otherAccount = accountByEntryCategory(result.category);

            if (otherAccount) {
              dispatch(
                Actions.setOtherAccount(
                  entryData,
                  thisAccount.id,
                  otherAccount.id
                )
              );
              dispatch(Actions.setAutoCategorizePValue(entryData, result.p));
            }
          }
        }
      }

      for (const entryPair of matchTransferEntries(transferEntries)) {
        const transactionId = newUuid();
        dispatch(
          Actions.createTransaction(
            entryPair,
            entryPair.map((entry) => ({
              id: newUuid(),
              transactionId,
              accountId: accountIdMap.get(entry.id)!,
              valueScaled: entry.valueScaled,
              memo: entry.memo,
              datetime: entry.datetime,
              tags: {},
            })),
            // TODO select datetime and memo more cleverly
            {
              id: transactionId,
              memo: entryPair[0].memo,
              tags: {},
              datetime: entryPair[0].datetime,
            }
          )
        );
      }
    });
  };
}

export function linkPlaidItem(publicToken: string) {
  return async (dispatch: Dispatch) => {
    await client.request(plaidLinkItemRoute, { publicToken });
  };
}

export function autoReconcile(entries: ImportedEntry[], splits: Split[]) {
  return async (dispatch: Dispatch) => {
    const matches = matchEntryAndSplit(entries, splits);
    dispatch(
      Actions.autoReconcile(matches.map(([ent, split]) => [ent.id, split.id]))
    );
  };
}

export function setAccountHidden(externalAccountId: UUID, hidden: boolean) {
  return async (dispatch: Dispatch) => {
    await getPersistence().updateExternalAccountTags(externalAccountId, {
      [IS_HIDDEN]: hidden ? "1" : "0",
    });
    dispatch(Actions.setHiddenAccount(externalAccountId, hidden));
  };
}

export function markEntriesDuplicate(ids: UUID[], isDuplicate: boolean) {
  return async (dispatch: Dispatch) => {
    await Promise.all(
      ids.map((id) =>
        getPersistence().updateImportedEntryTags(id, {
          [IS_DUPLICATE]: isDuplicate ? "1" : "0",
        })
      )
    );
    batch(() => {
      dispatch(Actions.markEntriesDuplicate(ids, isDuplicate));
      for (const id of ids) {
        dispatch(Actions.setEntrySelectedStatus(id, false));
      }
    });
  };
}

export function executeAutomation() {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const automationRule = automationRuleSelector(getState());
    const automationEntries = automationEntriesSelector(getState());

    const candidateTransactionById = transactionByEntryIdSelector(getState());

    const accountByExternalId = accountByExternalIdSelector(getState());
    const externalAccountById = externalAccountByIdSelector(getState());

    if (automationRule.actions.markAsDuplicate) {
      await dispatch(
        markEntriesDuplicate(
          automationEntries.map((entry) => entry.id),
          true
        )
      );
    } else {
      const transactionIds = new Set<UUID>();
      const splitIds = new Set<UUID>();

      for (const entry of automationEntries) {
        const account = accountByExternalId(
          externalAccountById[entry.externalAccountId]?.externalId
        );
        if (account) {
          let candidate = transactionFromAction(
            entry,
            account.id,
            automationRule.actions
          );

          if (candidate) {
            if (candidate.splits[0].accountId !== account.id) {
              throw new Error(
                "The first split must correspond to the imported entry"
              );
            }
            dispatch(
              Actions.createTransaction(
                [entry],
                candidate.splits,
                candidate.transaction
              )
            );
          } else {
            candidate = candidateTransactionById(entry.id);
          }

          if (candidate) {
            transactionIds.add(candidate.transaction.id);
            for (const split of candidate.splits) {
              splitIds.add(split.id);
            }
          }
        }
      }

      if (automationRule.actions.importImmediately) {
        await dispatch(
          markEntriesImported(Array.from(transactionIds), Array.from(splitIds))
        );
      }
    }
  };
}

export {
  setOtherAccount,
  setEntrySelectedStatus,
  createTransaction,
  unselectAll,
  clearTransaction,
  clearCSV,
  reconcile,
  addBalancingTransaction,
  setShowImportedEntries,
  updateAutomationRule,
} from "./action_creators";
