import { ImporterState } from "./types";

export const IMPORTER_DEFAULT_STATE: ImporterState = {
  externalAccountsData: {},
  candidateTransactions: {},
  entries: {},
  selectedEntries: {},
  cursorIndex: 0,
  automationRule: { actions: {}, criteria: {} },
};
