import React, { useEffect, useState } from "react";
import { Treemap } from "react-vis";
import { Account } from "model/bookkeeping";

import {
  treeify,
  breakAccountName,
  joinAccountName
} from "lib/accounts/hierarchy";
import { DataSet } from "./types";
import { UUID } from "lib/core/uuid";
import { FlexibleContainer } from "./flexible_container";
import css from "./treemap.module.css";
import { formatUnscaled } from "model/scaled_value";

interface TreemapNode {
  name: string;
  fullPath: string;
  value: number;
  children: TreemapNode[];
}

function pushDown(node: TreemapNode) {
  if (node.children.length > 0 && Math.abs(node.value) >= 0.001) {
    node.children.push({
      name: "(Self)",
      fullPath: node.fullPath,
      value: node.value,
      children: []
    });
  }
  node.children.forEach(v => pushDown(v));
  if (node.children.length > 0) {
    node.value = node.children.reduce((acc, cur) => acc + cur.value, 0);
  }
}

export function treemapData(dataSet: DataSet, accountsMap: Map<UUID, Account>) {
  const values: { name: string; value: number }[] = [];
  for (const series of dataSet) {
    if (series.series.length > 0 && series.accountId) {
      values.push({
        name: accountsMap.get(series.accountId)?.name || "Unknown",
        value: series.series[series.series.length - 1].y
      });
    }
  }
  let forest = treeify<TreemapNode, { name: string; value: number }>(
    values,
    account => breakAccountName(account.name),
    t => t.name,
    t => t.children,
    (acc, soFar) =>
      ({
        name: soFar[soFar.length - 1],
        fullPath: joinAccountName(soFar),
        value: 0,
        children: []
      } as TreemapNode),
    (account, tree) => (tree.value = account.value)
  );
  forest.forEach(node => pushDown(node));
  let parentAccount = "(Root)";
  while (forest.length === 1 && forest[0].children.length !== 0) {
    parentAccount = forest[0].fullPath;
    forest = forest[0].children;
  }
  return { forest, parentAccount };
}

export const TreemapGraph = React.memo<{
  forest: TreemapNode[];
  parentAccount: string;
}>(props => {
  const { forest: propForest, parentAccount } = props;
  const [currentAccount, setCurrentAccount] = useState(props.parentAccount);
  const [forest, setCurrentForest] = useState(props.forest);

  useEffect(() => {
    setCurrentForest(propForest);
  }, [propForest]);

  useEffect(() => {
    setCurrentAccount(parentAccount);
  }, [parentAccount]);

  const [logScale, setLogScale] = useState(false);

  const data = {
    title: currentAccount,
    children: forest.map(node => ({
      title: node.name,
      amount: formatUnscaled(node.value),
      size: logScale
        ? Math.log10(Math.abs(node.value) + 1) * 1000
        : Math.abs(node.value),
      style: node.value < 0 ? { opacity: 0.8 } : {},
      node
    }))
  };

  const [hoverAccount, setHoverAccount] = useState("");
  const [hoverAmount, setHoverAmount] = useState("");

  return (
    <div className={css.wrapper}>
      <h4 className={css.title}>{currentAccount}</h4>
      <FlexibleContainer height={300}>
        {(width, height) => (
          <Treemap
            animation={{ damping: 9, stiffness: 300 }}
            width={width}
            height={height}
            padding={2}
            data={data}
            mode="squarify"
            hideRootNode={true}
            sortFunction={(a: any, b: any) => b.value - a.value}
            onLeafClick={(data: any) => {
              if (data?.data?.node?.children?.length > 0) {
                setCurrentAccount(data.data.node.fullPath);
                setCurrentForest(data.data.node.children);
              }
            }}
            onLeafMouseOver={(
              data: any,
              event: React.MouseEvent<HTMLDivElement>
            ) => {
              setHoverAccount(data?.data?.node?.fullPath || "");
              setHoverAmount(data?.data?.amount || "");
              event.currentTarget.classList.add(css.leafHover);
            }}
            onLeafMouseOut={(
              data: unknown,
              event: React.MouseEvent<HTMLDivElement>
            ) => {
              setHoverAccount("");
              setHoverAmount("");
              event.currentTarget.classList.remove(css.leafHover);
            }}
          />
        )}
      </FlexibleContainer>
      <div>
        <div>
          {hoverAccount} {hoverAmount}
        </div>
        <label>
          <input
            type="checkbox"
            checked={logScale}
            onChange={e => setLogScale(e.currentTarget.checked)}
          />
          Log scale
        </label>
      </div>
    </div>
  );
});
