import { Parser } from "expr-eval";

Object.getPrototypeOf(Parser.parse("0")).toJSFunction = () => {
  throw new Error("Function disabled for safety");
};

export function evaluate(
  expression: string,
  bindings: { [v: string]: number }
) {
  return Parser.evaluate(expression, bindings);
}
