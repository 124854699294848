import cx from "classnames";
import React, { Suspense, useState } from "react";

import { visit } from "components/core/routing";
import { ReactComponent as TransactionsIcon } from "assets/icons/transactions.svg";
import { ReactComponent as AccountsIcon } from "assets/icons/accounts.svg";
import { ReactComponent as ImportIcon } from "assets/icons/import.svg";
import { ReactComponent as OverviewIcon } from "assets/icons/overview.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as MenuIcon } from "assets/font-awesome-solid/bars.svg";
import { ReactComponent as UserIcon } from "assets/font-awesome-solid/user.svg";
import css from "./nav.module.css";
import buttonCss from "components/styles/button.module.css";
import { connect } from "react-redux";
import { BMUser } from "data/auth/types";
import { userSelector } from "data/auth/selectors";
import { AppState } from "data/store";
import { setShowLogin } from "data/auth/action_creators";

const LazyUserManagement = React.lazy(() =>
  import("components/auth/user_management").then((m) => ({
    default: m.ConnectedUserManagement,
  }))
);

const UserButton = React.memo<{
  user?: BMUser;
  setShowLogin(showLogin: boolean): void;
}>((props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      {isOpen && props.user && (
        <Suspense fallback={null}>
          <LazyUserManagement onRequestClose={() => setIsOpen(false)} />
        </Suspense>
      )}
      <button
        className={cx(buttonCss.styleless, css.userButton)}
        onClick={() => {
          if (props.user) {
            setIsOpen(true);
          } else {
            props.setShowLogin(true);
          }
        }}
      >
        <span className={css.navIcon}>
          <span className={css.userIconWrapper}>
            <UserIcon width={14} height={14} />
          </span>
        </span>
        <span className={css.navText}>
          {props.user ? "My Account" : "Log In"}
        </span>
      </button>
    </>
  );
});

const ConnectedUserButton = connect(
  (state: AppState) => ({
    user: userSelector(state),
  }),
  { setShowLogin }
)(UserButton);

export const Nav: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={cx(css.leftNavWrapper, { [css.expanded]: isExpanded })}>
      <div className={css.leftNav}>
        <div className={css.logoWrapper}>
          <div className={css.logo} />
          <h1 className={css.productName}>Ducount</h1>
        </div>
        <ul role="navigation" className={css.navMenu}>
          <li>
            <button onClick={() => visit("/")}>
              <span className={css.navIcon}>
                <OverviewIcon width={18} height={18} />
              </span>

              <span className={css.navText}>Overview</span>
            </button>
          </li>
          <li>
            <button onClick={() => visit("/import")}>
              <span className={css.navIcon}>
                <ImportIcon width={18} height={18} />
              </span>
              <span className={css.navText}>Import</span>
            </button>
          </li>
          <li>
            <button onClick={() => visit("/accounts")}>
              <span className={css.navIcon}>
                <AccountsIcon width={18} height={18} />
              </span>

              <span className={css.navText}>Accounts</span>
            </button>
          </li>
          <li>
            <button onClick={() => visit("/transactions")}>
              <span className={css.navIcon}>
                <TransactionsIcon width={18} height={18} />
              </span>
              <span className={css.navText}>Transactions</span>
            </button>
          </li>
          <li>
            <button onClick={() => visit("/settings")}>
              <span className={css.navIcon}>
                <SettingsIcon width={18} height={18} />
              </span>
              <span className={css.navText}>Settings</span>
            </button>
          </li>
          <li role="presentation" className={css.spacerEntry}></li>
          <li>
            <ConnectedUserButton />
          </li>
        </ul>
        <button
          className={css.expandMenuButton}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <MenuIcon width={18} height={18} />
        </button>
      </div>
    </div>
  );
};
