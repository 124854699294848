import { BMUser } from "./types";

export function setUser(user: BMUser | null) {
  return {
    type: "AUTH/SET_USER" as const,
    payload: { user },
  };
}
export function setShowLogin(showLogin: boolean) {
  return {
    type: "AUTH/SET_SHOW_LOGIN" as const,
    payload: { showLogin },
  };
}

export type AuthAction =
  | ReturnType<typeof setUser>
  | ReturnType<typeof setShowLogin>;
