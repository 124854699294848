import React, { PureComponent } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { ImportedEntry, ExternalAccount } from "../../model/bookkeeping";
import {
  selectedEntryDataSelector,
  externalAccountByIdSelector,
  candidateTransactionsSelector,
} from "../../data/importer/selectors";
import { AppState, Dispatch } from "../../data/store";
import { Entry, entryIsCandidate } from "../../data/importer/types";
import { newUuid, UUID } from "../../lib/core/uuid";
import { accountByExternalIdSelector } from "../../data/accounts/selectors";
import {
  createTransaction,
  setOtherAccount,
  unselectAll,
  markEntriesDuplicate,
} from "../../data/importer/actions";
import { ConnectedTransactionCreator } from "components/common/transaction_creator";
import { ConnectedAccountSelector } from "../common/account";
import pageCss from "components/styles/page.module.css";
import css from "./sidebar.module.css";

interface BatchEditTransferAccountProps {
  entries: Entry[];
  accountByExternalId: ReturnType<typeof accountByExternalIdSelector>;
  externalAccountById: ReturnType<typeof externalAccountByIdSelector>;
  candidateTransactionById: ReturnType<typeof candidateTransactionsSelector>;
  dispatch: Dispatch;
}

class BatchEditTransferAccount extends PureComponent<
  BatchEditTransferAccountProps,
  { accountId?: UUID }
> {
  constructor(props: BatchEditTransferAccountProps) {
    super(props);
    this.state = {};
  }

  private onAccountChange = (accountId: UUID) => {
    this.setState({ accountId });
  };

  componentDidUpdate(prevProps: BatchEditTransferAccountProps) {
    if (prevProps.entries !== this.props.entries) {
      this.setState({ accountId: undefined });
    }
  }

  private onSave = () => {
    for (const entry of this.props.entries) {
      this.props.dispatch(
        setOtherAccount(
          entry,
          this.props.accountByExternalId(entry.accountExternalId)!.id,
          this.state.accountId!
        )
      );
      this.props.dispatch(unselectAll());
    }
  };

  render() {
    const { entries, candidateTransactionById } = this.props;

    const accounts = new Set<UUID | undefined>();

    for (const e of entries) {
      if (entryIsCandidate(e)) {
        const candidateTransaction =
          candidateTransactionById[e.candidateTransactionId];

        if (candidateTransaction) {
          for (const split of candidateTransaction.splits) {
            if (split.id !== e.candidateSplitId) {
              accounts.add(split.accountId);
            }
          }
        }
      } else {
        accounts.add(undefined);
      }
    }

    return (
      <section>
        <h3>
          {entries.length > 1
            ? "Batch Edit Transfer Account"
            : "Edit Transfer Account"}
        </h3>
        <div>
          <ConnectedAccountSelector
            value={
              this.state.accountId ||
              (accounts.size === 1 ? accounts.values().next().value : undefined)
            }
            onChange={this.onAccountChange}
            hidePlaceholders={true}
          />
          <button disabled={!this.state.accountId} onClick={this.onSave}>
            OK
          </button>
        </div>
      </section>
    );
  }
}

interface Props {
  entries: Entry[];
  accountByExternalId: ReturnType<typeof accountByExternalIdSelector>;
  externalAccountById: ReturnType<typeof externalAccountByIdSelector>;
  dispatch: Dispatch;
}

const ConnectedBatchEditTransferAccount = connect((state: AppState) => ({
  entries: selectedEntryDataSelector(state),
  accountByExternalId: accountByExternalIdSelector(state),
  externalAccountById: externalAccountByIdSelector(state),
  candidateTransactionById: candidateTransactionsSelector(state),
}))(BatchEditTransferAccount);

class TransactionCreatorSection extends PureComponent<Props, {}> {
  render() {
    const { entries } = this.props;

    const entryAndAccounts = entries
      .filter((e) => !entryIsCandidate(e))
      .map(
        (e) =>
          [
            e.entry,
            this.props.externalAccountById[e.entry.externalAccountId],
          ] as [ImportedEntry, ExternalAccount]
      );
    return (
      <section>
        <h3>Create a Transaction</h3>
        <ConnectedTransactionCreator
          memo={entries.length === 1 ? entries[0].entry.memo : undefined}
          splits={entryAndAccounts.map(([entry, extAccount]) => ({
            id: newUuid(),
            accountId: this.props.accountByExternalId(extAccount.externalId)!
              .id,
            valueScaled: entry.valueScaled,
            memo: entry.memo,
            disabled: true,
            datetime: entry.datetime,
          }))}
          onSave={(transaction, splits) => {
            this.props.dispatch(
              createTransaction(
                entryAndAccounts.map(([entry, account]) => entry),
                splits,
                transaction
              )
            );
            this.props.dispatch(unselectAll());
          }}
        />
      </section>
    );
  }
}

class MarkAsDuplicateSection extends PureComponent<{
  entries: Entry[];
  dispatch: Dispatch;
}> {
  private onSave = () => {
    this.props.dispatch(
      markEntriesDuplicate(
        this.props.entries.map((e) => e.entry.id),
        true
      )
    );
    this.props.dispatch(unselectAll());
  };

  render() {
    const { entries } = this.props;

    return (
      <section>
        <h3>Mark as Duplicate</h3>
        <div>
          <button onClick={this.onSave}>
            Mark {entries.length} entries as duplicate
          </button>
        </div>
      </section>
    );
  }
}

const ConnectedTransactionCreatorSection = connect((state: AppState) => ({
  entries: selectedEntryDataSelector(state),
  accountByExternalId: accountByExternalIdSelector(state),
  externalAccountById: externalAccountByIdSelector(state),
}))(TransactionCreatorSection);

export class ImporterSidebar extends PureComponent<Props, {}> {
  render() {
    const { entries } = this.props;
    if (entries.length === 0) {
      return <aside />;
    }
    let title;
    if (entries.length === 1) {
      title = <h2 key="title">{entries[0].entry.memo}</h2>;
    } else {
      title = <h2 key="title">{`${entries.length} Entries Selected`}</h2>;
    }

    return (
      <aside className={cx(pageCss.block, css.sidebar)}>
        {title}
        <ConnectedBatchEditTransferAccount />
        <ConnectedTransactionCreatorSection />
        <MarkAsDuplicateSection
          dispatch={this.props.dispatch}
          entries={entries}
        />
      </aside>
    );
  }
}

export const ConnectedImporterSidebar = connect((state: AppState) => ({
  entries: selectedEntryDataSelector(state),
  accountByExternalId: accountByExternalIdSelector(state),
  externalAccountById: externalAccountByIdSelector(state),
}))(ImporterSidebar);
