import cx from "classnames";
import React, { useEffect, useState } from "react";
import css from "./simple_dropdown_menu.module.css";

export const SimpleDropdown: React.FC<{
  contents(ref: React.Ref<any>, onClick: () => void): React.ReactNode;
  buttonClass?: string;
  wrapperClass?: string;
}> = React.memo(props => {
  const menuRef = React.createRef<HTMLElement>();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    function onClickHandler(e: Event) {
      if (
        isOpen &&
        menuRef.current &&
        !menuRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", onClickHandler);

    return () => {
      document.removeEventListener("click", onClickHandler);
    };
  });
  return (
    <div className={cx(css.wrapper, props.wrapperClass)}>
      <button className={props.buttonClass} onClick={() => setIsOpen(!isOpen)}>
        {props.children}
      </button>
      {isOpen && props.contents(menuRef, () => setIsOpen(false))}
    </div>
  );
});

SimpleDropdown.displayName = "SimpleDropdown";

export const SimpleDropdownMenu: React.FC<{
  items: { title: string; action: () => void }[];
  buttonClass?: string;
}> = React.memo(props => {
  return (
    <SimpleDropdown
      contents={(ref, onClick) => (
        <ul className={css.menu} ref={ref} role="menu">
          {props.items.map((item, key) => (
            <li
              className={css.menuitem}
              role="menuitem"
              key={key}
              onClick={() => {
                item.action();
                onClick();
              }}
            >
              {item.title}
            </li>
          ))}
        </ul>
      )}
      buttonClass={props.buttonClass}
    >
      {props.children}
    </SimpleDropdown>
  );
});

SimpleDropdownMenu.displayName = "SimpleDropdownMenu";
