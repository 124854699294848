import React, { PureComponent } from "react";
import { ExternalAccount } from "../../../model/bookkeeping";
import { UUID } from "../../../lib/core/uuid";
import { ConnectedAccountCreator } from "../../ledgers/account_creator";
import { ConnectedAccountSelector } from "../../common/account";
import css from "./import_to_account.module.css";

interface ImportToAccountOptionsProps {
  externalAccount: ExternalAccount;
  disableCreation?: boolean;
  onSetAccount?(accountId: UUID): void;
  newAccountTags?: {
    [s: string]: string;
  };
  defaultValue?: string;
}

interface ImportToAccountOptionsState {
  accountId?: UUID;
}

export class ImportToAccountOptions extends PureComponent<
  ImportToAccountOptionsProps,
  ImportToAccountOptionsState
> {
  state: ImportToAccountOptionsState = {};
  private onChange = (accountId: UUID) => {
    this.setState({ accountId });
  };
  private onSetAccount = () => {
    if (this.props.onSetAccount && this.state.accountId) {
      this.props.onSetAccount(this.state.accountId);
    }
  };
  render() {
    return (
      <div className={css.accountSelectorOverlay}>
        <div className={css.accountSelector}>
          <div>
            External account {this.props.externalAccount.name} does not have a
            corresponding account
          </div>
          <div>
            <p>Select an account to import into</p>
            <ConnectedAccountSelector
              id={this.props.externalAccount.id}
              value={this.state.accountId}
              onChange={this.onChange}
              theme={{
                container: css.accountSelectorInput,
              }}
              hidePlaceholders={true}
            />
            <button
              onClick={this.onSetAccount}
              disabled={!this.state.accountId}
            >
              OK
            </button>
          </div>
          {this.props.disableCreation ? null : (
            <>
              <hr />
              <div>
                <p>Or, create a new account</p>
                <ConnectedAccountCreator
                  defaultValue={this.props.defaultValue}
                  onCreated={this.props.onSetAccount}
                  tags={this.props.newAccountTags}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
