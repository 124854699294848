import { UUID } from "lib/core/uuid";
import { Filter } from "model/filtering";
import { DurationUnit } from "luxon";
import { BASIC_ACCOUNT_VALUES } from "data/accounts/tags";

export const LEDGERS_NS = "LEDGERS";

export interface ChartingOptions {
  type: "line" | "bar" | "pie" | "treemap";
  data: "balance" | "delta" | "cumulative";
  accounts: "sum" | "separate";
  grouping: DurationUnit;
  origin: "zero" | "auto";
  signConventionDebit: "positive" | "negative";
  signConventionCredit: "positive" | "negative";
  regression?: "linear" | "power" | "exponential" | "logarithmic";
  extrapolateDays: number;
}

export type AccountDescriptor = RegularAccount | AccountType;

type RegularAccount = {
  _tag: "regular";
  accountId: UUID;
  includeSubAccounts: boolean;
};

type AccountType = {
  _tag: "type";
  type: BASIC_ACCOUNT_VALUES;
};

export type SavedAccounts = {
  accountDescriptor: AccountDescriptor;
  policy: "include" | "exclude";
}[];

export interface SavedChart {
  id: UUID;
  name: string;
  filters?: Filter[];
  options: ChartingOptions;
  accounts: SavedAccounts;
}

export interface LedgersState {
  selectedAccountIds: UUID[];
  selectedAccountBackStack: UUID[][];
  selectedAccountForwardStack: UUID[][];
  // disjunction
  filters?: Filter[];

  selectedSplitIds: { [k: string]: boolean };

  pagingOffset: number;
  pagingSize: number;

  charts: {
    default: SavedChart[];
    user: SavedChart[];
  };
}
