import cx from "classnames";
import React from "react";
import { ExternalAccount } from "model/bookkeeping";
import { UUID } from "lib/core/uuid";
import { IS_HIDDEN } from "data/importer/tags";
import { SimpleDropdownMenu } from "components/common/simple_dropdown_menu";
import { ReactComponent as DotIcon } from "assets/font-awesome-solid/ellipsis-h.svg";
import buttonCss from "components/styles/button.module.css";
import { connect } from "react-redux";
import { setAccountHidden } from "data/importer/actions";
import { setShowHiddenAccountOnly } from "data/importer/action_creators";
import { AppState } from "data/store";
import { showHiddenAccountOnlySelector } from "data/importer/selectors";

export const Settings = React.memo<{
  extAccount: ExternalAccount;
  setAccountHidden(id: UUID, isHidden: boolean): Promise<void>;
}>((props) => {
  const { extAccount, setAccountHidden } = props;
  const isHidden = !!+extAccount.tags[IS_HIDDEN];

  return (
    <div>
      <SimpleDropdownMenu
        buttonClass={buttonCss.styleless}
        items={[
          {
            title: isHidden ? "Show this account" : "Hide this account",
            action: () => setAccountHidden(extAccount.id, !isHidden),
          },
        ]}
      >
        <DotIcon width={16} height={16} />
      </SimpleDropdownMenu>
    </div>
  );
});

export const ConnectedSettings = connect(() => ({}), {
  setAccountHidden,
})(Settings);

const HiddenAccountToggle = React.memo<{
  setShowHiddenAccountOnly(h: boolean): void;
  showHiddenOnly: boolean;
}>((props) => {
  return (
    <div>
      {props.showHiddenOnly ? (
        <button
          className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
          onClick={() => props.setShowHiddenAccountOnly(false)}
        >
          Show active account
        </button>
      ) : (
        <button
          className={cx(buttonCss.button, buttonCss.buttonBlueOutline)}
          onClick={() => props.setShowHiddenAccountOnly(true)}
        >
          Show hidden account
        </button>
      )}
    </div>
  );
});

export const ConnectedHiddenAccountToggle = connect(
  (state: AppState) => ({
    showHiddenOnly: showHiddenAccountOnlySelector(state),
  }),
  { setShowHiddenAccountOnly: setShowHiddenAccountOnly }
)(HiddenAccountToggle);
