import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { store } from "./data/store";
import { Router, Route, FallbackRoute } from "./components/core/routing";
import { ConnectedImporterMain } from "./components/importer/main";
import { ConnectedTransactionsMain } from "./components/transactions/main";
import { LedgersMain } from "./components/ledgers/main";
import { ConnectedOobe } from "./components/oobe/oobe";
import { OverviewMain } from "components/overview/main";
import { SettingsMain } from "components/settings/main";
import { ConnectedQuickAdd } from "components/global/quick_add";
import { CheckboxContainer } from "components/common/checkbox";
import { ConnectedChangeNotifier } from "components/common/change_listener";
import { Nav } from "components/global/nav/nav";

import css from "./App.module.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const LazySignInScreen = React.lazy(() =>
  import("components/auth/auth").then((m) => ({
    default: m.ConnectedSignInScreen,
  }))
);

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <CheckboxContainer selectionClassName={css.checkboxSelection}>
          <ConnectedOobe />
          <Suspense fallback={null}>
            <LazySignInScreen />
          </Suspense>
          <Router>
            <Nav />
            <div className={css.mainContent}>
              <ConnectedChangeNotifier />
              <Route match="/import">
                <ConnectedImporterMain />
              </Route>
              <Route match="/transactions">
                <ConnectedTransactionsMain />
              </Route>
              <Route match="/accounts">
                <LedgersMain />
              </Route>
              <Route match="/settings">
                <SettingsMain />
              </Route>
              <Route match="/">
                <OverviewMain />
              </Route>
              <FallbackRoute>404 Not Found</FallbackRoute>
              <ConnectedQuickAdd />
              <div className={css.footer}>
                Copyright {new Date().getFullYear()} Ducount. Build{" "}
                {process.env.REACT_APP_BUILD_VERSION || "unknown"}
              </div>
            </div>
          </Router>
        </CheckboxContainer>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
