import { ImportedEntry, ExternalAccount } from "../../model/bookkeeping";

export type ExternalAccountData = [ExternalAccount, ImportedEntry[]];

export type IngestionFunction = (s: string) => Promise<ExternalAccountData[]>;

export enum ExternalAccountProvider {
  OFX = "OFX",
  CSV = "CSV",
  PLAID = "PLAID"
}
