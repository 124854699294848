import cx from "classnames";
import React, { PureComponent } from "react";
import "react-vis/dist/style.css";
import { Split, Transaction } from "../../model/bookkeeping";
import { connect } from "react-redux";
import {
  transactionByIdSelector,
  accountsMapSelector,
  splitsByTransactionSelector,
  accountIsDebitFuncSelector,
  balanceListFuncSelector,
  subaccountsMapSelector,
  accountsByBasicTypeMapSelector,
} from "../../data/accounts/selectors";
import { AppState } from "../../data/store";
import {
  filteredSplitsSelector,
  selectedAccountIdSelector,
  filtersSelector,
  chartsSelector,
} from "../../data/ledgers/selectors";
import pageCss from "components/styles/page.module.css";
import {
  setFilterDateRange,
  setFilter,
  clearFilter,
  setSelectedAccount,
} from "data/ledgers/actions";
import { XYGraph } from "./charts/xy_graph";
import { DEFAULT_OPTIONS, ChartingSettings } from "./charts/options";
import { DataSet, SplitAndTxDate } from "./charts/types";
import {
  balanceDataSet,
  cumulativeDataSet,
  deltaDataSet,
} from "./charts/dataset";
import { TreemapGraph, treemapData } from "./charts/treemap";
import { RadialGraph } from "./charts/radial";
import css from "./chart.module.css";
import { ChartingOptions } from "data/ledgers/types";
import { expandSavedAccounts } from "data/ledgers/utils";
import buttonCss from "components/styles/button.module.css";

type Props = {
  splits: Split[];
  transactions: {
    [id: string]: Transaction;
  };
  accountsMap: ReturnType<typeof accountsMapSelector>;
  subaccountsMap: ReturnType<typeof subaccountsMapSelector>;
  accountsByBasicType: ReturnType<typeof accountsByBasicTypeMapSelector>;

  splitsByTransaction: ReturnType<typeof splitsByTransactionSelector>;
  balanceListFunc: ReturnType<typeof balanceListFuncSelector>;
  accountIsDebitFunc: ReturnType<typeof accountIsDebitFuncSelector>;
  selectedAccountId: ReturnType<typeof selectedAccountIdSelector>;
  filters: ReturnType<typeof filtersSelector>;
  charts: ReturnType<typeof chartsSelector>;

  setFilterDateRange: typeof setFilterDateRange;
  setFilter: typeof setFilter;
  clearFilter: typeof clearFilter;
  setSelectedAccount: typeof setSelectedAccount;
};

export class Chart extends PureComponent<Props, { options: ChartingOptions }> {
  state = { options: DEFAULT_OPTIONS };

  onOptionChange = (options: ChartingOptions) => {
    this.setState({ options });
  };

  splitsAndTxDate(): SplitAndTxDate[] {
    return this.props.splits.map((s) => [
      s,
      this.props.transactions[s.transactionId].datetime.datetime,
    ]);
  }

  render() {
    const { options } = this.state;
    const {
      splits,
      balanceListFunc,
      selectedAccountId,
      filters,
      accountIsDebitFunc,
      setFilterDateRange,
      accountsMap,
    } = this.props;

    let dataSet: DataSet = [];
    switch (options.data) {
      case "balance":
        dataSet = balanceDataSet(
          balanceListFunc,
          selectedAccountId,
          filters,
          accountIsDebitFunc,
          options
        );
        break;
      case "cumulative":
        dataSet = cumulativeDataSet(
          this.splitsAndTxDate(),
          accountIsDebitFunc,
          options
        );
        break;
      case "delta":
        dataSet = deltaDataSet(
          this.splitsAndTxDate(),
          accountIsDebitFunc,
          options
        );
        break;
    }

    return (
      <div className={pageCss.block}>
        <div className={css.charts}>
          <span className={css.chartsLegend}>Common Charts:</span>
          <ul className={css.defaultCharts}>
            {this.props.charts.default.map((savedChart) => (
              <li key={savedChart.id}>
                <button
                  className={cx(buttonCss.styleless, css.chartButton)}
                  onClick={() => {
                    this.props.clearFilter();
                    if (savedChart.filters) {
                      savedChart.filters.forEach((filter, index) =>
                        this.props.setFilter(index, filter)
                      );
                    }
                    this.props.setSelectedAccount(
                      Array.from(
                        expandSavedAccounts(
                          savedChart.accounts,
                          this.props.accountsMap,
                          this.props.accountsByBasicType,
                          this.props.subaccountsMap
                        )
                      )
                    );
                    this.setState({ options: savedChart.options });
                  }}
                >
                  {savedChart.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {splits.length > 0 ? (
          options.type === "line" || options.type === "bar" ? (
            <XYGraph
              type={options.type}
              dataSet={dataSet}
              origin={options.origin}
              setFilterDateRange={setFilterDateRange}
              regressionOptions={options}
            />
          ) : options.type === "pie" ? (
            <RadialGraph accountsMap={accountsMap} dataSet={dataSet} />
          ) : (
            <TreemapGraph {...treemapData(dataSet, accountsMap)} />
          )
        ) : (
          <div className={css.noData} style={{ height: 300 }}>
            <p>No data to display</p>
          </div>
        )}

        <ChartingSettings
          options={this.state.options}
          onChange={this.onOptionChange}
        />
      </div>
    );
  }
}

export const ConnectedChart = connect(
  (state: AppState) => ({
    splits: filteredSplitsSelector(state),
    transactions: transactionByIdSelector(state),
    accountsMap: accountsMapSelector(state),
    subaccountsMap: subaccountsMapSelector(state),
    accountsByBasicType: accountsByBasicTypeMapSelector(state),
    splitsByTransaction: splitsByTransactionSelector(state),
    balanceListFunc: balanceListFuncSelector(state),
    accountIsDebitFunc: accountIsDebitFuncSelector(state),
    selectedAccountId: selectedAccountIdSelector(state),
    filters: filtersSelector(state),
    charts: chartsSelector(state),
  }),
  {
    setFilterDateRange,
    setFilter,
    clearFilter,
    setSelectedAccount,
  }
)(Chart);
