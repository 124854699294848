import { Route } from "./lib";

export const plaidGetLinkTokenRoute = new Route<void, { link_token: string }>(
  "/linking/plaid/get_link_token"
);

export const plaidLinkItemRoute = new Route<
  {
    publicToken: string;
  },
  void
>("/linking/plaid/link_item");

export const plaidUpdateItemRoute = new Route<
  { id: string; days?: number },
  { success: boolean }
>("/linking/plaid/update");
