import React, { PureComponent } from "react";

import { ConnectedImporter } from "./importer";
import { Dispatch, AppState } from "../../data/store";
import { loadData } from "../../data/importer/actions";
import { connect } from "react-redux";
import {
  importerNsSelector,
  externalAccountDataListSelector,
} from "../../data/importer/selectors";
import { accountsNsSelector } from "../../data/accounts/selectors";
import { ConnectedImporterSidebar } from "./sidebar";
import pageCss from "components/styles/page.module.css";

import css from "./main.module.css";
import { ConnectedUpload } from "./upload";
import { SegmentedButton } from "components/common/segmented_button";
import { userSelector } from "data/auth/selectors";
import { ConnectedReconciler } from "./reconcile";
import { BMUser } from "data/auth/types";
import { Feature } from "static_data/billing";
import { hasFeatureEnabled } from "lib/billing/billing";
import { ConnectedAutomation } from "./automation";

enum Tabs {
  UPLOAD = "UPLOAD",
  MANAGE = "MANAGE",
  LINK = "LINK",
  RECONCILE = "RECONCILE",
  AUTOMATION = "AUTOMATION",
}

type Props = {
  dispatch: Dispatch;
  isReady: boolean;
  isImporterReady: boolean;
  hasExternalAccountData: boolean;
  user?: BMUser;
};

type State = {
  tab?: Tabs;
};

const LazyAccountLinker = React.lazy(() =>
  import("./linking").then(({ ConnectedAccountLinker }) => ({
    default: ConnectedAccountLinker,
  }))
);

export class ImporterMain extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.stateFromProps(props);
  }

  componentDidMount() {
    this.fetchDataIfNecessary();
  }

  fetchDataIfNecessary(prevProps?: Props) {
    if (prevProps && prevProps.isReady && !prevProps.isImporterReady) {
      return;
    }
    if (this.props.isReady && !this.props.isImporterReady) {
      this.props.dispatch(loadData());
    }
  }

  supportLinking() {
    return (
      process.env.REACT_APP_PERSIST === "cloud" &&
      this.props.user &&
      hasFeatureEnabled(this.props.user, Feature.LINK_ACCOUNT)
    );
  }

  stateFromProps(props: Props): Partial<State> {
    if (this.state && this.state.tab) {
      return {};
    }
    if (props.isReady) {
      if (props.hasExternalAccountData) {
        return {
          tab: Tabs.MANAGE,
        };
      } else {
        return { tab: this.supportLinking() ? Tabs.LINK : Tabs.UPLOAD };
      }
    }
    return {};
  }

  componentDidUpdate(prevProps: Props) {
    this.fetchDataIfNecessary(prevProps);
    this.setState(this.stateFromProps(this.props));
  }

  onUploaded = () => this.setState({ tab: Tabs.MANAGE });

  onTabChange = (tab: Tabs) => {
    this.setState({ tab });
  };

  buttons() {
    const buttons = [];

    if (this.supportLinking()) {
      buttons.push({ label: "Link", value: Tabs.LINK });
    }

    buttons.push(
      { label: "Upload", value: Tabs.UPLOAD },
      { label: "Import", value: Tabs.MANAGE },
      { label: "Reconcile", value: Tabs.RECONCILE },
      { label: "Automation", value: Tabs.AUTOMATION }
    );

    return buttons;
  }

  render() {
    if (!this.props.isReady) {
      return null;
    }
    return (
      <div className={pageCss.pageWrapper}>
        <div className={pageCss.pageTitleWrapper}>
          <h1 className={pageCss.pageTitle}>Import Transactions</h1>
          <div className={pageCss.pageTitleNav}>
            <SegmentedButton
              buttons={this.buttons()}
              value={this.state.tab}
              onChange={this.onTabChange}
            />
          </div>
        </div>
        <div className={css.mainContent}>
          {this.state.tab === Tabs.AUTOMATION && <ConnectedAutomation />}
          {this.state.tab === Tabs.UPLOAD && (
            <ConnectedUpload onUploaded={this.onUploaded} />
          )}
          {this.state.tab === Tabs.LINK && (
            <React.Suspense fallback={null}>
              <LazyAccountLinker onLinked={this.onUploaded} />
            </React.Suspense>
          )}
          {this.state.tab === Tabs.RECONCILE && (
            <div className={css.entries}>
              <ConnectedReconciler />
            </div>
          )}
          {this.state.tab === Tabs.MANAGE && (
            <div className={css.entries}>
              <ConnectedImporter />
            </div>
          )}
          {this.state.tab === Tabs.MANAGE && (
            <>
              <div className={css.spacer} />
              <div className={css.sidebar}>
                <ConnectedImporterSidebar />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export const ConnectedImporterMain = connect((state: AppState) => ({
  isReady: !!accountsNsSelector(state).ready,
  isImporterReady: !!importerNsSelector(state).ready,
  hasExternalAccountData: externalAccountDataListSelector(state).length > 0,
  user: userSelector(state),
}))(ImporterMain);
