import React, { useState } from "react";
import { DataSet } from "./types";
import { FlexibleContainer } from "./flexible_container";
import { RadialChart, Hint } from "react-vis";
import { formatUnscaled } from "model/scaled_value";
import { UUID } from "lib/core/uuid";
import { Account } from "model/bookkeeping";

export const RadialGraph = React.memo<{
  dataSet: DataSet;
  accountsMap: Map<UUID, Account>;
}>(props => {
  const data = props.dataSet
    .map(series => {
      const value = series.series[series.series.length - 1]?.y;
      return {
        angle: Math.abs(value),
        label: series.accountId
          ? props.accountsMap.get(series.accountId)?.name
          : "Unknown",
        formattedValue: value !== undefined ? formatUnscaled(value) : "",
        style:
          value < 0
            ? {
                fill: "none",
                strokeWidth: 3,
                strokeDasharray: 5
              }
            : {}
      };
    })
    .filter(data => !isNaN(data.angle));

  type DataPoint = { label: string; formattedValue: string } | undefined;
  const [curValue, setCurValue] = useState<DataPoint>(undefined);

  return (
    <FlexibleContainer height={300}>
      {(width, height) => (
        <RadialChart
          width={width}
          height={height}
          data={data}
          onValueMouseOver={(v: DataPoint) => setCurValue(v)}
          onSeriesMouseOut={(v: DataPoint) => setCurValue(undefined)}
        >
          {curValue !== undefined ? (
            <Hint value={curValue}>
              <div style={{ backgroundColor: "black" }}>
                <p>{curValue.label}</p>
                <p>{curValue.formattedValue}</p>
              </div>
            </Hint>
          ) : null}
        </RadialChart>
      )}
    </FlexibleContainer>
  );
});
