import { BMDateTime } from "./types";
import { DateTime } from "luxon";

export const beginningOfTime = (): BMDateTime => ({
  datetime: DateTime.fromMillis(0),
  hasTime: false
});

export function today(): BMDateTime {
  return {
    datetime: DateTime.local(),
    hasTime: false
  };
}

export function dateToNumber(date: DateTime): number {
  return date.year * 10000 + date.month * 100 + date.day;
}

export function numberToDate(num: number): DateTime {
  const day = num % 100;
  num = Math.floor(num / 100);
  const month = num % 100;
  num = Math.floor(num / 100);
  return DateTime.local(num, month, day);
}

export function heuristicStringToDate(s: string): BMDateTime | undefined {
  const tokens = s.split(/[^0-9]+/).map(s => parseInt(s, 10));
  switch (tokens.length) {
    case 2:
      // MM/DD
      return {
        hasTime: false,
        datetime: DateTime.local().set({
          month: tokens[0],
          day: tokens[1]
        })
      };

    case 3:
      if (tokens[0] > 99) {
        // YYYY/MM/DD
        return {
          hasTime: false,
          datetime: DateTime.local(tokens[0], tokens[1], tokens[2])
        };
      } else if (tokens[2] > 99) {
        // MM/DD/YYYY
        return {
          hasTime: false,
          datetime: DateTime.local(tokens[2], tokens[0], tokens[1])
        };
      } else {
        // MM/DD/YY
        return {
          hasTime: false,
          datetime: DateTime.local(
            tokens[2] < 50 ? 2000 + tokens[2] : 19 + tokens[2],
            tokens[0],
            tokens[1]
          )
        };
      }

    default:
      return undefined;
  }
}
